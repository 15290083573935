import * as React from "react";

import { cn } from "shared/utils/cn";

import { Text } from "./text";
import { Title } from "./title";

const CardHeader = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("flex flex-col space-y-1.5 p-6", className)}
    {...props}
  />
));
CardHeader.displayName = "CardHeader";

const CardContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn("p-6 pt-0", className)} {...props} />
));
CardContent.displayName = "CardContent";

const CardFooter = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("flex items-center p-6 pt-0", className)}
    {...props}
  />
));
CardFooter.displayName = "CardFooter";

interface CardProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, "title" | "children"> {
  children: React.ReactNode;
  title?: string;
  header?: React.ReactNode;
  description?: string;
  footer?: React.ReactNode;
  contentClassName?: string;
  icon?: React.ReactNode;
}

const Card = React.forwardRef<HTMLDivElement, CardProps>(
  (
    {
      className,
      contentClassName,
      children,
      title,
      header,
      description,
      footer,
      icon,
      ...rest
    },
    ref,
  ) => {
    const hasHeader = Boolean(header || title || description || icon);

    return (
      <div
        ref={ref}
        className={cn(
          "rounded-lg border border-neutral-200 bg-white dark:border-neutral-800 dark:bg-neutral-950",
          className,
        )}
        {...rest}
      >
        {hasHeader && (
          <CardHeader className="flex items-center">
            {header}
            <div className="flex flex-row gap-2">
              {icon && (
                <aside className="w-8 h-8 text-brand-orange [&>*]:w-full [&>*]:h-full">
                  {icon}
                </aside>
              )}
              {title && <Title order={1}>{title}</Title>}
            </div>
            {description && <Text size="sm">{description}</Text>}
          </CardHeader>
        )}

        {children && (
          <CardContent className={contentClassName}>{children}</CardContent>
        )}
        {footer && <CardFooter>{footer}</CardFooter>}
      </div>
    );
  },
);
Card.displayName = "Card";

export { Card, CardContent, CardFooter, CardHeader };
