import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";
import type { ReactNode } from "react";

const cardStyles = cva([], {
  variants: {
    size: {
      default: [
        "md:h-[190px] md:w-[360px]  md:max-h-[190px] md:max-w-[360px] md:min-h-[190px] md:min-w-[360px] h-[180px] w-[80vw] max-w-[360px] min-h-[180px] min-w-[240px] max-h-[180px] text-left",
      ],
      thumbnail: [
        "h-[160px]  w-[160px] max-h-[160px] max-w-[160px]  min-h-[160px] min-w-[160px] items-center text-center",
      ],
      skinny: [
        "md:h-[165px] md:w-[360px]  md:max-h-[165px] md:max-w-[360px] md:min-h-[165px] md:min-w-[360px] h-[165px] w-[80vw] max-w-[360px] min-w-[240px] min-h-[165px] max-h-[165px] text-left",
      ],
    },
    color: {
      default: ["bg-[#2497AA]"],
      darkBlue: ["bg-[#176590]"],
      aqua: ["bg-[#75B0B4]"],
      lightBlue: ["bg-[#3496CE]"],
      plum: ["bg-[#6D175A]"],
      white: ["bg-white"],
    },
    border: {
      default: ["border border-neutral-200 shadow-sm rounded-md"],
      none: ["border-none"],
    },
    padding: {
      default: ["p-2"],
      none: ["p-0"],
    },
  },
  compoundVariants: [],
  defaultVariants: {
    size: "default",
    color: "default",
    border: "default",
    padding: "default",
  },
});

interface CardProps extends VariantProps<typeof cardStyles> {
  children: ReactNode;
}

export function Card({ children, ...variantProps }: CardProps) {
  const cardStyle = cardStyles({ ...variantProps });
  if (!children) return null;
  return (
    <div
      className={`relative text-white ${cardStyle} flex flex-col justify-center`}
    >
      {children}
    </div>
  );
}
