import type { PatientWithOrg } from "../../backend/resources/patient/patient";

import { flexRender } from "@tanstack/react-table";
import type { Cell } from "@tanstack/react-table";
import type { TableDataRowType } from "components/Tables/InfiniteScrollingTable";
import { InfiniteScrollingTable } from "components/Tables/InfiniteScrollingTable";
import { Route } from "features/routing/constants";
import { useAppNavigate } from "lib/routing";
import { useEffect, useState } from "react";
import { useCarespaceStore } from "state/carespace/carespace";
import { useOrganizationStore } from "state/organization/organization";

enum PatientFields {
  Name = "Name",
  DOB = "DOB",
  Organization = "Organization",
  OrganizationId = "OrganizationId",
  CarespaceId = "CarespaceId",
}

type BasePatientType = {
  [PatientFields.Name]: string;
  [PatientFields.DOB]: string;
  [PatientFields.Organization]: string;
  [PatientFields.OrganizationId]: string;
  [PatientFields.CarespaceId]: string;
};
type PatientType = BasePatientType & TableDataRowType<BasePatientType>;

export default function PatientSearchResultsTable({
  data: rawData,
}: {
  data: PatientWithOrg[] | null | undefined;
}) {
  const navigate = useAppNavigate();
  const [data, setData] = useState<PatientType[]>(() => []);

  const setActiveOrganizationId = useOrganizationStore(
    (state) => state.setActiveOrganizationId,
  );
  const setActiveCarespaceId = useCarespaceStore(
    (state) => state.setActiveCarespaceId,
  );

  useEffect(() => {
    if (rawData) {
      const data = rawData.map((item) => ({
        [PatientFields.Name]: `${item.first_name} ${item.last_name}`,
        [PatientFields.DOB]: item.birthday
          ? new Date(item.birthday).toLocaleDateString("en-US", {
              timeZone: "UTC",
            })
          : "",
        [PatientFields.Organization]: item.organization.name,
        [PatientFields.OrganizationId]: item.organization.id,
        [PatientFields.CarespaceId]: item.carespace_id,
      }));
      setData(data);
    }
  }, [rawData]);

  return (
    <InfiniteScrollingTable
      data={data}
      hiddenColumns={[PatientFields.OrganizationId, PatientFields.CarespaceId]}
      columnFields={Object.values(PatientFields)}
      handleClick={(cell) => {
        setActiveOrganizationId(
          cell.row.original[PatientFields.OrganizationId],
        );
        setActiveCarespaceId(cell.row.original[PatientFields.CarespaceId]);
        navigate({
          path: Route.CARESPACE,
          params: {
            carespaceId: cell.row.original[PatientFields.CarespaceId],
          },
        });
      }}
      CellContent={({ cell }) => <CellContent cell={cell} />}
      emptyText="No patients found"
    />
  );
}

function CellContent({
  cell,
}: {
  cell: Cell<PatientType, unknown>;
}) {
  return (
    <p className="truncate text-left max-w-[200px] w-[200px]">
      {flexRender(cell.column.columnDef.cell, cell.getContext())}
    </p>
  );
}
