import * as Sentry from "@sentry/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import queryClient from "shared/query-client";
import { queryKeys, remove, select, update } from ".";
import type { OrganizationInvitationUpdate, TableName } from "../types";

import {
  type Filter,
  type QueryOptions,
  buildFilters,
} from "features/query-utils";

export function useFetchOne(
  filter: Filter<TableName>,
  options: QueryOptions = {},
) {
  const { enabled } = options;

  return useQuery({
    queryKey: queryKeys.detail(filter),
    queryFn: async () => {
      const query = buildFilters(select(), filter);

      const { data, error } = await query.limit(1).single();

      if (error) {
        Sentry.captureException(error);
        throw error;
      }

      return data;
    },
    enabled,
  });
}

export function useFetchMany(
  filter: Filter<TableName>,
  options: QueryOptions = {},
) {
  const { enabled } = options;

  return useQuery({
    queryKey: queryKeys.detail(filter),
    queryFn: async () => {
      const query = buildFilters(select(), filter);

      const { data, error } = await query;

      if (error) {
        Sentry.captureException(error);
        throw error;
      }

      return data;
    },
    enabled,
  });
}

export function useUpdate(filter: Filter<TableName>) {
  return useMutation({
    mutationFn: async (
      organizationInvitationUpdate: OrganizationInvitationUpdate,
    ) => {
      const mutation = buildFilters(
        update(organizationInvitationUpdate),
        filter,
      );

      const { data, error } = await mutation.select().maybeSingle();

      if (!data) {
        const finalError =
          error ||
          new Error(`No data found for organization invitation after update:
          filter: ${JSON.stringify(filter, null, 2)}

          organizationInvitationUpdate: ${JSON.stringify(organizationInvitationUpdate, null, 2)}
        `);

        Sentry.captureException(finalError);
        throw finalError;
      }

      return data;
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: queryKeys.detail(filter),
      }),
  });
}

export function useDelete() {
  return useMutation({
    mutationFn: async (id: string) => {
      const { data, error } = await remove(id);

      if (error) {
        Sentry.captureException(error);
        throw error;
      }

      return data;
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: queryKeys.all,
      }),
  });
}
