import * as Sentry from "@sentry/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { supabase } from "clients/supabaseClient";
import { ALL_ORGANIZATIONS } from "features/routing/layouts/components/organization-switcher";
import { useActiveOrganizationId } from "state/organization/organization";
import type { Database } from "../../../../../types/supabase";
import queryClient from "../../../shared/query-client";
import { QUERY_KEYS } from "../../query-keys";
import type { Carespace } from "../carespace/carespace";
import { useCarespacesInOrganization } from "../carespace/carespace";
import {
  ConversationType,
  useMutateChatGptConversations,
} from "../chatGptConversation/chatGptConversation";
import type { ServiceResource } from "./serviceResource";

const TABLE = "service_engagement";
export type ServiceEngagement =
  Database["public"]["Tables"]["service_engagement"]["Row"];
type ServiceEngagementUpdate =
  Database["public"]["Tables"]["service_engagement"]["Update"];

type ServiceEngagementWithServiceResource = ServiceEngagement & {
  service_resource: ServiceResource | null;
};
export type ServiceEngagementWithServiceResourceAndCarespace =
  ServiceEngagementWithServiceResource & {
    carespace: Carespace | null;
  };

export function useServiceEngagementById(
  serviceEngagementId: string | undefined | null,
) {
  return useQuery({
    queryKey: [QUERY_KEYS.serviceEngagements, { serviceEngagementId }],
    queryFn: async () => {
      if (!serviceEngagementId) return null;
      const { data, error } = await supabase
        .from(TABLE)
        .select("*, service_resource(*)")
        .eq("id", serviceEngagementId)
        .limit(1)
        .maybeSingle();
      if (error) {
        Sentry.captureException(error);
        throw new Error(error.message);
      }
      return data as ServiceEngagementWithServiceResource;
    },
  });
}

export function useServiceEngagements() {
  const activeOrgId = useActiveOrganizationId();
  const { carespaces } = useCarespacesInOrganization();
  const carespaceIds = carespaces?.map((carespace) => carespace.id) ?? [];
  return useQuery({
    queryKey: [QUERY_KEYS.serviceEngagements, { activeOrgId, carespaceIds }],
    queryFn: async () => {
      const { data, error } = await supabase
        .from(TABLE)
        .select("*, service_resource(*),  carespace(*)")
        .in("carespace_id", carespaceIds);

      if (error) {
        Sentry.captureException(error);
        throw new Error(error.message);
      }
      const filteredData =
        activeOrgId === ALL_ORGANIZATIONS
          ? data
          : data.filter(
              (entry) => entry.carespace?.organization_id === activeOrgId,
            );
      return filteredData as ServiceEngagementWithServiceResourceAndCarespace[];
    },
  });
}

export function useUpdateServiceEngagement() {
  return useMutation({
    mutationFn: async (serviceEngagement: ServiceEngagementUpdate) => {
      if (serviceEngagement.id) {
        const { data, error } = await supabase
          .from(TABLE)
          .update(serviceEngagement)
          .eq("id", serviceEngagement.id);
        if (error) {
          Sentry.captureException(error);
          throw new Error(error.message);
        }
        return data;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.chatGptConversation],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.serviceEngagements],
      });
    },
  });
}

export function useAddConversationToServiceEngagement() {
  const updateServiceEngagement = useUpdateServiceEngagement().mutateAsync;
  const chatGptConversationMutation = useMutateChatGptConversations();
  return useMutation({
    mutationFn: async ({
      serviceEngagementId,
      carespaceId,
    }: {
      serviceEngagementId: string | undefined;
      carespaceId: string | undefined;
    }) => {
      if (!serviceEngagementId) return;
      const newConversation = await chatGptConversationMutation.mutateAsync({
        type: ConversationType.Service,
        carespaceId,
      });
      updateServiceEngagement({
        id: serviceEngagementId,
        conversation_id: newConversation?.id,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.serviceEngagements],
      });
    },
  });
}
