import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import FooterButtons from "components/FooterButtons/FooterButtons";
import { Popup } from "components/Popup";
import { BottomSheet } from "components/Sheet";
import { useWindowSize } from "hooks/useWindowSize";
import OneSignal from "react-onesignal";

export default function PushSettingPopup({ onClose }: { onClose: () => void }) {
  const { isMobile } = useWindowSize();
  if (isMobile) {
    return (
      <BottomSheet isOpen={true} onClose={onClose}>
        <PushSettingPopupComponent onClose={onClose} />
      </BottomSheet>
    );
  }
  return (
    <Popup close={onClose}>
      <PushSettingPopupComponent onClose={onClose} />
    </Popup>
  );
}
function PushSettingPopupComponent({ onClose }: { onClose: () => void }) {
  return (
    <div className="pb-20 px-6 md:px-0 md:pb-0">
      <h1 className="text-lg">Turn on Push Notifications</h1>
      <br />
      <p>Please turn on push-notifications on your browser</p>
      <br />
      <FooterButtons>
        <ButtonWithIcon
          className=" text-xs md:text-base"
          onClick={async () => {
            OneSignal.User.PushSubscription.optIn();
            await OneSignal.Slidedown.promptPush();
            onClose();
          }}
          text="OK"
          icon={IconOption.CHECKMARK}
        />
      </FooterButtons>
    </div>
  );
}
