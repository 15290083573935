import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import Logo from "components/Logo/logo";
import type { CraniometrixProduct } from "hooks/product/product";
import { createPortal } from "react-dom";
import { ScrollArea } from "shared/ui/scroll-area";

interface ModalProps {
  isOpen: boolean;
  onClose?: () => void;
  closeText?: string;
  title?: string | React.ReactNode;
  showLogo?: CraniometrixProduct | "base";
  isLogoCentered?: boolean;
  logoSize?: "small" | "large";
  footerButtons?: React.ReactNode;
  children: React.ReactNode;
}

export default function ModalView({
  isOpen,
  onClose,
  closeText,
  title,
  showLogo,
  logoSize,
  isLogoCentered,
  footerButtons,
  children,
}: ModalProps) {
  if (!isOpen) return null;

  return createPortal(
    <div className="flex flex-col fixed inset-0 top-0 left-0 right-0 bottom-0 items-center justify-center w-full h-full bg-white z-20">
      <div className="flex flex-col relative w-full h-full p-6 gap-4 shadow-lg">
        {closeText && onClose ? (
          <div>
            <ButtonWithIcon
              onClick={onClose}
              text={closeText}
              icon={IconOption.BACK_ARROW}
            />
          </div>
        ) : null}
        {showLogo ? (
          <Logo
            isDisabled
            product={showLogo}
            isCentered={isLogoCentered}
            size={logoSize}
          />
        ) : null}

        {title ? (
          <div className="flex w-full justify-center">
            <h2 className="w-full text-2xl text-center md:text-start">
              {title}
            </h2>
          </div>
        ) : null}

        {/* Children Content */}
        <ScrollArea className="flex-grow">{children}</ScrollArea>

        {/* Footer buttons */}
        {footerButtons ? (
          <div className="flex w-full items-center justify-around">
            {footerButtons}
          </div>
        ) : null}
      </div>
    </div>,
    document.body,
  );
}
