import { create } from "zustand";

type DiscussionFilterState = {
  carespace: string | undefined;
  type: string | undefined;
  setCarespace: (carespace: string | undefined) => void;
  setType: (type: string | undefined) => void;
};

export const useDiscussionFilterStore = create<DiscussionFilterState>(
  (set) => ({
    carespace: undefined,
    type: "All",
    setCarespace: (carespace: string | undefined) => set(() => ({ carespace })),
    setType: (type: string | undefined) => set(() => ({ type })),
  }),
);
