import { supabase } from "clients/supabaseClient";

import type { Filter } from "features/query-utils";

import { TABLE_NAME } from "../constants";
import type { TableName } from "../types";

const all = ["service-resources"];
const lists = [...all, "list"];

export const queryKeys = {
  all,
  lists,
  list: (filter: Filter<TableName>) => [...lists, filter],
};

export const select = () =>
  supabase.from(TABLE_NAME).select(
    `
    id,
    created_at,
    name,
    categories,
    service_engagement(
      id,
      created_at,
      name,
      is_active,
      became_inactive_at,
      carespace(
        name
      )
    )
  `,
  );
