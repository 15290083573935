/**
 * @fileoverview Sets up the Zustand product store.
 * This store holds information about the product state.
 */
import { useOrgs } from "../../backend/resources/orgRole";
import { useActiveUserRole } from "../../backend/resources/userRole";

export enum CraniometrixProduct {
  CARE_PILOT = "care pilot",
  CARE_CENTRAL = "care central",
}

/**
 * Product access and URL state
 */

export function useProductAccess() {
  const { userRole, isUserRoleLoading } = useActiveUserRole();

  const { isLoading: isHasCareCentralAccessLoading, hasCareCentralAccess } =
    useOrgs();

  return {
    data: getCurrentProduct(hasCareCentralAccess, !!userRole),
    isLoading: isHasCareCentralAccessLoading || isUserRoleLoading,
  };
}

function getCurrentProduct(
  hasCareCentralAccess: boolean,
  hasUserRole: boolean,
) {
  if (hasCareCentralAccess) return CraniometrixProduct.CARE_CENTRAL;
  if (hasUserRole) return CraniometrixProduct.CARE_PILOT;
  return undefined;
}
