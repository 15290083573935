import type { BankAccountType, CarePlanDestination } from "./types";

export const TABLE_NAME = "organization";
export const ORGANIZATION_ROLE_TABLE_NAME = "organization_role";

export const CRANIOMETRIX_TIN = "990947976";
export const CRANIOMETRIX_GUIDE_ID = "GUIDE-1043";

export const BankAccountTypeMap: Record<BankAccountType, string> = {
  checking: "Checking",
  savings: "Savings",
};

export const CarePlanDestinationMap: Record<CarePlanDestination, string> = {
  fax: "Fax",
  api_endpoint: "API Endpoint",
  redox: "Redox",
};

export const US_STATES = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DC",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
] as const;
