import "components/HomePage/CaregiverHome/CaregiverHome.css";
import { PageContainer } from "components/PageContainer";
import { PageMainHeader } from "components/PageMainHeader";

export function CaregiverHome() {
  return (
    <PageContainer>
      <PageMainHeader text="Home" />
    </PageContainer>
  );
}
