import * as Sentry from "@sentry/react";
import { supabase } from "clients/supabaseClient";
import { Route } from "features/routing/constants";
import { ALL_ORGANIZATIONS } from "features/routing/layouts/components/organization-switcher";
import { useAppNavigate } from "lib/routing";
import { useToast } from "shared/hooks/use-toast";
import { useActiveOrganizationId } from "state/organization/organization";
import CarespacesForm from "../components/carespace-form";
import type { FormSchema } from "../schema";
import { createCaregiverUserIfNeeded } from "../utils";

export default function CarespacesNew() {
  const activeOrganizationId = useActiveOrganizationId();
  const { toast } = useToast();
  const navigate = useAppNavigate();

  async function onSubmit(data: FormSchema) {
    try {
      if (activeOrganizationId === ALL_ORGANIZATIONS || !activeOrganizationId) {
        throw new Error("No active organization selected");
      }

      const { data: carespaceId } = await supabase.rpc("create_carespace", {
        ...data,
        organization_id: activeOrganizationId,
        patient_birthday: data.patient_birthday.toISOString(),
        patient_mbi: data.patient_mbi,
        patient_address: data.patient_address,
        patient_city: data.patient_city,
        patient_state: data.patient_state,
        patient_zipcode: data.patient_zipcode,
        patient_phone_number: data.patient_phone_number,
        patient_medical_record_number: data.patient_medical_record_number,
        patient_dementia_staging_tool: data.patient_dementia_staging_tool,
        patient_dementia_staging_score: data.patient_dementia_staging_score,
        patient_icd10_code: data.patient_icd10_code,
        patient_has_caregiver: data.patient_has_caregiver,
        patient_is_new_patient: data.patient_is_new_patient,
        patient_referral_source: data.patient_referral_source,
        patient_preferred_name: data.patient_preferred_name,
        patient_preferred_language: data.patient_preferred_language,
        patient_medicaid_id: data.patient_medicaid_id,
        patient_email: data.patient_email,
        patient_race: data.patient_race,
        patient_ethnicity: data.patient_ethnicity,
        pcp_first_name: data.pcp_first_name,
        pcp_last_name: data.pcp_last_name,
        pcp_phone_number: data.pcp_phone_number,
        caregiver_first_name: data.caregiver_first_name,
        caregiver_last_name: data.caregiver_last_name,
        caregiver_email: data.caregiver_email,
        caregiver_phone_number: data.caregiver_phone_number,
      });

      if (!carespaceId) {
        throw new Error("Failed to create carespace");
      }

      // Create a caregiver user if needed
      await createCaregiverUserIfNeeded({
        carespaceId,
        caregiverId: null,
        first_name: data.caregiver_first_name,
        last_name: data.caregiver_last_name,
        email: data.caregiver_email,
        patient_has_caregiver: data.patient_has_caregiver,
      });

      toast({
        variant: "success",
        title: "Success",
        description: "Carespace created successfully",
      });

      navigate({
        path: Route.CARESPACE,
        params: { carespaceId },
      });
    } catch (error) {
      Sentry.captureException(error);
      toast({
        variant: "destructive",
        title: "Error",
        description:
          error instanceof Error ? error.message : "Please try again",
      });
    }
  }

  return (
    <CarespacesForm
      variant="default"
      title="New Carespace"
      submitLabel="Save"
      onSubmit={onSubmit}
      defaultValues={{
        patient_first_name: "",
        patient_last_name: "",
        patient_is_new_patient: undefined,
        patient_referral_source: undefined,
        patient_birthday: undefined,
        patient_legal_sex: undefined,
        patient_race: undefined,
        patient_ethnicity: undefined,
        patient_preferred_name: "",
        patient_preferred_language: "",
        patient_mbi: "",
        patient_medicaid_id: "",
        patient_medical_record_number: "",
        patient_dementia_staging_tool: undefined,
        patient_dementia_staging_score: undefined,
        patient_icd10_code: undefined,
        patient_has_caregiver: undefined,
        patient_address: "",
        patient_city: "",
        patient_state: undefined,
        patient_zipcode: "",
        patient_email: "",
        patient_phone_number: "",
        pcp_last_name: "",
        pcp_first_name: "",
        pcp_phone_number: "",
        provider_first_name: "",
        provider_last_name: "",
        provider_npi: "",
        clinician_first_name: "",
        clinician_last_name: "",
        clinician_npi: "",
        caregiver_first_name: "",
        caregiver_last_name: "",
        caregiver_email: "",
        caregiver_phone_number: "",
      }}
    />
  );
}
