import * as React from "react";
import type { DateRange } from "react-day-picker";

import useControlledProps, {
  type ControlledProps,
} from "shared/hooks/use-controlled-props";
import { Input } from "./input";

interface TwoInputDateRangePickerProps extends ControlledProps<DateRange> {
  className?: string;
  id?: string;
  disabled?: boolean;
}

const TwoInputDateRangePicker = React.forwardRef<
  HTMLInputElement,
  TwoInputDateRangePickerProps
>(({ className, defaultValue, id, disabled, ...props }, ref) => {
  const { onChange } = useControlledProps(props);
  const [fromValue, setFromValue] = React.useState<Date | undefined>(
    defaultValue?.from,
  );
  const [toValue, setToValue] = React.useState<Date | undefined>(
    defaultValue?.to,
  );

  React.useEffect(() => {
    if (!fromValue && !toValue) {
      onChange(undefined);
      return;
    }
    onChange({ from: fromValue, to: toValue });
  }, [fromValue, toValue]);

  return (
    <div id={id} className={`grid grid-cols-2 gap-2 ${className}`}>
      <Input
        style={{ display: "inline" }}
        type="date"
        ref={ref}
        disabled={disabled}
        onChange={(v) => setFromValue(parseDateInput(v.target.value))}
      />

      <Input
        style={{ display: "inline" }}
        type="date"
        disabled={disabled}
        onChange={(v) => setToValue(parseDateInput(v.target.value))}
      />
    </div>
  );
});
TwoInputDateRangePicker.displayName = "TwoInputDateRangePicker";

const parseDateInput = (value: string) => {
  try {
    const date = new Date(value);
    return Number.isNaN(date.getTime()) ? undefined : date;
  } catch {
    return undefined;
  }
};

export { TwoInputDateRangePicker };
