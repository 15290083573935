import type { Cell, Row } from "@tanstack/react-table";
import { flexRender } from "@tanstack/react-table";
import type { ServiceFilterConfig } from "components/Services Hub/components/ServiceFilters";
import { ServiceFilters } from "components/Services Hub/components/ServiceFilters";
import { useServicesFilteredData } from "components/Services Hub/servicesUtil";
import type { TableDataRowType } from "components/Tables/InfiniteScrollingTable";
import { InfiniteScrollingTable } from "components/Tables/InfiniteScrollingTable";
import { Route } from "features/routing/constants";
import { useAppNavigate } from "lib/routing";
import { useEffect, useState } from "react";
import type {
  ServiceEngagement,
  ServiceEngagementWithServiceResourceAndCarespace,
} from "../../backend/resources/services/serviceEngagement";

// Define your service fields and types
export enum ServiceFields {
  RowData = "rowData",
  Carespace = "Carespace",
  Service = "Service",
  ServiceType = "Service Type",
  Provider = "Provider",
  ActiveOrInactiveDate = "Active/ Inactive Date",
}
type BaseServiceType = {
  [ServiceFields.RowData]: ServiceEngagement;
  [ServiceFields.Carespace]: string | undefined;
  [ServiceFields.Service]: string | undefined;
  [ServiceFields.Provider]: string | undefined;
  [ServiceFields.ActiveOrInactiveDate]: string | undefined;
  [ServiceFields.ServiceType]: string;
};
type ServiceType = BaseServiceType & TableDataRowType<BaseServiceType>;

interface ServicesTableProps {
  data: ServiceEngagementWithServiceResourceAndCarespace[] | undefined;
  filterConfig: ServiceFilterConfig<ServiceEngagementWithServiceResourceAndCarespace>;
  filtersToShow?: ServiceFilterConfig<ServiceEngagementWithServiceResourceAndCarespace>;
  hiddenColumns?: ServiceFields[];
}

export default function ServicesTable({
  data: rawData,
  filterConfig,
  filtersToShow,
  hiddenColumns,
}: ServicesTableProps) {
  const navigate = useAppNavigate();
  const [data, setData] = useState<ServiceType[]>(() => []);
  const filteredData = useServicesFilteredData({
    data: rawData,
    config: filterConfig,
  });

  useEffect(() => {
    // Updates data
    const updatedData = filteredData?.map((item) => ({
      [ServiceFields.RowData]: item,
      [ServiceFields.Carespace]: item.carespace?.name ?? undefined,
      [ServiceFields.Service]: item?.name,
      [ServiceFields.ServiceType]:
        item.service_resource?.categories &&
        item.service_resource.categories !== ""
          ? item.service_resource.categories
          : "N/A",
      [ServiceFields.Provider]: item.service_resource?.name,
      [ServiceFields.ActiveOrInactiveDate]: new Date(
        item.became_inactive_at ?? item.created_at,
      ).toLocaleDateString("en-US"),
    }));
    const sortedData = updatedData?.sort(
      (a, b) =>
        new Date(b[ServiceFields.RowData].created_at).getTime() -
        new Date(a[ServiceFields.RowData].created_at).getTime(),
    );

    setData(sortedData ?? []);
  }, [filteredData]);

  return (
    <div className="flex flex-col gap-5 ">
      {filtersToShow && <ServiceFilters filterConfig={filtersToShow} />}
      <InfiniteScrollingTable
        data={data}
        columnFields={Object.values(ServiceFields)}
        handleClick={(
          cell: Cell<ServiceType, unknown>,
          row: Row<ServiceType>,
        ): void => {
          if (cell.column.id === ServiceFields.Provider) {
            navigate({
              path: Route.SERVICE_PROVIDER_PAGE,
              params: {
                id: row.original[ServiceFields.RowData].service_resource_id,
              },
            });
          } else {
            navigate({
              path: Route.SERVICE_PAGE,
              params: {
                id: row.original[ServiceFields.RowData].id,
              },
            });
          }
        }}
        hiddenColumns={[...(hiddenColumns ?? []), ServiceFields.RowData]}
        CellContent={({
          cell,
        }: {
          cell: Cell<ServiceType, unknown>;
        }): JSX.Element => {
          switch (cell.column.id) {
            case ServiceFields.ActiveOrInactiveDate:
              return (
                <p className="rounded-full truncate text-center w-[120px] max-w-[120px]">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </p>
              );
            default:
              return (
                <p className="rounded-full truncate text-left w-[200px] max-w-[200px]">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </p>
              );
          }
        }}
        emptyText="There are no services associated with the filters you selected"
      />
    </div>
  );
}
