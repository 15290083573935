import { ChevronDown } from "lucide-react";
import React from "react";
import { cn } from "../utils/cn";
import type { ButtonProps } from "./button";
import { Button } from "./button";
import {
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuRoot,
  DropdownMenuTrigger,
} from "./dropdown-menu";

interface SplitButtonProps extends ButtonProps {
  title: string;
  icon?: React.ReactNode;
  /**
   * The options for the dropdown menu
   */
  options: {
    label: string;
    onClick: () => void;
    disabled?: boolean;
    icon?: React.ReactNode;
  }[];
  /**
   * Callback for when the main button is clicked
   */
  onClick?: () => void;
  /**
   * Custom class for the main button
   */
  mainButtonClassName?: string;
  /**
   * Custom class for the dropdown toggle button
   */
  dropdownButtonClassName?: string;
  /**
   * Whether the button is in a loading state
   */
  isLoading?: boolean;
}

export const SplitButton = React.forwardRef<
  HTMLButtonElement,
  SplitButtonProps
>(
  (
    {
      title,
      icon,
      className,
      options,
      onClick,
      mainButtonClassName,
      dropdownButtonClassName,
      ...props
    },
    ref,
  ) => {
    return (
      <div className={cn("flex", className)}>
        <Button
          className={cn("rounded-r-none border-r", mainButtonClassName)}
          onClick={onClick}
          ref={ref}
          {...props}
        >
          {icon}
          {title}
        </Button>
        <DropdownMenuRoot>
          <DropdownMenuTrigger asChild>
            <Button
              variant={props.variant}
              size={props.size}
              className={cn("rounded-l-none px-2", dropdownButtonClassName)}
              disabled={options.every((option) => option.disabled)}
              aria-label={`${title} - More actions`}
            >
              <ChevronDown className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            {options.map((option) => (
              <DropdownMenuItem
                key={option.label}
                onClick={option.onClick}
                disabled={option.disabled}
              >
                {option.icon && (
                  <span className="mr-2 h-4 w-4 inline-flex items-center">
                    {option.icon}
                  </span>
                )}
                {option.label}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenuRoot>
      </div>
    );
  },
);

SplitButton.displayName = "SplitButton";
