import { CaregiverHome } from "components/HomePage/CaregiverHome";
import { LoadingSpinner } from "components/LoadingSpinner";
import { Route } from "features/routing/constants";
import { ALL_ORGANIZATIONS } from "features/routing/layouts/components/organization-switcher";
import { useAppNavigate } from "lib/routing";
import { useEffect } from "react";
import { useActiveOrganizationId } from "state/organization/organization";
import { useAlfredPageSideBar } from "../../backend/resources/chatGptConversation";
import { useOrgs } from "../../backend/resources/orgRole";

export function HomePage() {
  useAlfredPageSideBar("homePage");
  const { isLoading: isUserAccessLoading, hasCareCentralAccess } = useOrgs();
  const activeOrganizationId = useActiveOrganizationId();
  const navigate = useAppNavigate();

  useEffect(() => {
    if (!activeOrganizationId) {
      return;
    }

    if (activeOrganizationId === ALL_ORGANIZATIONS) {
      navigate({
        path: Route.ORGANIZATIONS,
      });
    } else if (hasCareCentralAccess) {
      navigate({
        path: Route.CARESPACES,
      });
    }
  }, [hasCareCentralAccess, activeOrganizationId, navigate]);

  if (isUserAccessLoading) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <LoadingSpinner className="w-20 h-20" />
      </div>
    );
  }

  if (!activeOrganizationId) {
    return null;
  }

  if (activeOrganizationId === ALL_ORGANIZATIONS) {
    return null;
  }

  if (hasCareCentralAccess) {
    return null;
  }

  return <CaregiverHome />;
}
