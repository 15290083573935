import { US_STATES } from "features/organizations/constants";
import { enumFromKeys } from "features/query-utils";
import {
  PhoneNumberSchema,
  ZipCodeSchema,
  optionalString,
} from "shared/forms/types";
import { z } from "zod";
import {
  ClinicianAttestationMap,
  DementiaStagingToolMap,
  EthnicityMap,
  HasCaregiverMap,
  MedicareBeneficiaryTypeMap,
  PhoneTypeMap,
  RaceMap,
  ReferralSourceMap,
  RelationshipTypeMap,
  ResidenceTypeMap,
  Sex,
  icd10Codes,
} from "./constants";

const requiredBaseFieldsForCaregiver = [
  "caregiver_first_name",
  "caregiver_last_name",
  "caregiver_email",
  "caregiver_phone_number",
] as const;

const requiredFieldsForCaregiver = [
  ...requiredBaseFieldsForCaregiver,
  "caregiver_address",
  "caregiver_city",
  "caregiver_state",
  "caregiver_zipcode",
  "caregiver_phone_type",
  "caregiver_birthday",
  "caregiver_legal_sex",
  "caregiver_race",
  "caregiver_ethnicity",
  "caregiver_relationship_type",
  "caregiver_lives_with_patient",
  "caregiver_is_medicare_beneficiary",
  "caregiver_mbi",
  "caregiver_since_years",
  "caregiver_since_months",
] as const;

function validateCaregiverFields<
  T extends typeof baseForm | typeof extendedForm,
>(fields: ReadonlyArray<keyof z.infer<T>>) {
  return (data: z.infer<T>, ctx: z.RefinementCtx) => {
    const hasCaregiver =
      data.patient_has_caregiver === "yes_one" ||
      data.patient_has_caregiver === "yes_multiple";

    if (!hasCaregiver) return;

    for (const fieldName of fields) {
      if (
        typeof fieldName === "string" &&
        typeof data[fieldName] === "undefined"
      ) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: `${fieldName} is required`,
          path: [fieldName],
        });
      }
    }
  };
}

const baseForm = z.object({
  patient_first_name: z
    .string()
    .min(1, { message: "Patient first name is required." }),
  patient_last_name: z
    .string()
    .min(1, { message: "Patient last name is required." }),
  patient_is_new_patient: z.boolean(),
  patient_referral_source: enumFromKeys(ReferralSourceMap),
  patient_birthday: z.preprocess(
    (value) => (typeof value === "string" ? new Date(value) : value),
    z
      .date({ invalid_type_error: "Please enter a valid date" })
      .refine((date) => date < new Date(), {
        message: "Birthday must be a date in the past",
      }),
  ),
  patient_legal_sex: z.nativeEnum(Sex),
  patient_race: enumFromKeys(RaceMap),
  patient_ethnicity: enumFromKeys(EthnicityMap),
  patient_preferred_name: optionalString(),
  patient_preferred_language: optionalString(),
  patient_mbi: z
    .string()
    .min(1, { message: "Medicare Beneficiary Identifier is required." })
    .length(11, {
      message: "Medicare Beneficiary Identifier must be 11 characters.",
    }),
  patient_medicaid_id: optionalString(),
  patient_medical_record_number: z
    .string()
    .min(1, { message: "Medical Record Number is required." }),
  patient_dementia_staging_tool: enumFromKeys(DementiaStagingToolMap),
  patient_dementia_staging_score: z
    .number()
    .or(
      z
        .string()
        .regex(/^\d+(\.\d{1,2})?$/)
        .transform(Number),
    )
    .refine((value) => value >= 0, {
      message: "Dementia Staging Tool Score must be a positive number.",
    }),
  patient_icd10_code: z.enum(icd10Codes),
  patient_has_caregiver: enumFromKeys(HasCaregiverMap),
  patient_address: z
    .string()
    .min(1, { message: "Patient address is required." }),
  patient_city: z.string().min(1, { message: "Patient city is required." }),
  patient_state: z.enum(US_STATES),
  patient_zipcode: ZipCodeSchema,
  patient_email: optionalString(
    z.string().email({ message: "Invalid email" }).trim().toLowerCase(),
  ),
  patient_phone_number: PhoneNumberSchema,
  pcp_first_name: optionalString(),
  pcp_last_name: optionalString(),
  pcp_phone_number: optionalString(),
  provider_first_name: z
    .string()
    .min(1, { message: "Provider first name is required." }),
  provider_last_name: z
    .string()
    .min(1, { message: "Provider last name is required." }),
  provider_npi: z
    .string()
    .min(1, { message: "Provider National Provider Identifier is required." })
    .regex(/^\d{10}$/, {
      message:
        "Provider National Provider Identifier must be a 10-digit number.",
    }),
  clinician_first_name: z
    .string()
    .min(1, { message: "Clinician first name is required." }),
  clinician_last_name: z
    .string()
    .min(1, { message: "Clinician last name is required." }),
  clinician_npi: z
    .string()
    .min(1, {
      message: "Clinician National Provider Identifier is required.",
    })
    .regex(/^\d{10}$/, {
      message:
        "Clinician National Provider Identifier must be a 10-digit number.",
    }),
  clinician_attestation: enumFromKeys(ClinicianAttestationMap),
  caregiver_first_name: optionalString(),
  caregiver_last_name: optionalString(),
  caregiver_email: optionalString(z.string().trim().toLowerCase()),
  caregiver_phone_number: optionalString(),
});

const extendedForm = baseForm.extend({
  patient_timezone: z
    .string()
    .min(1, { message: "Patient timezone is required." }),
  patient_phone_type: enumFromKeys(PhoneTypeMap, {
    required_error: "Phone type is required",
  }),
  assessment_date: z.preprocess(
    (value) => (typeof value === "string" ? new Date(value) : value),
    z.date({ invalid_type_error: "Please enter a valid date" }),
  ),
  patient_residence_type: enumFromKeys(ResidenceTypeMap, {
    required_error: "Residence type is required",
  }),
  patient_is_nursing_home_resident: z.boolean({
    required_error: "Nursing home status is required",
  }),
  caregiver_address: optionalString(
    z.string().min(1, { message: "Caregiver address is required." }),
  ),
  caregiver_city: optionalString(
    z.string().min(1, { message: "Caregiver city is required." }),
  ),
  caregiver_state: z.enum(US_STATES).optional(),
  caregiver_zipcode: optionalString(ZipCodeSchema),
  caregiver_phone_type: enumFromKeys(PhoneTypeMap, {
    required_error: "Phone type is required",
  }).optional(),
  caregiver_birthday: z.preprocess(
    (value) => (typeof value === "string" ? new Date(value) : value),
    z
      .date({ invalid_type_error: "Please enter a valid date" })
      .refine((date) => date < new Date(), {
        message: "Birthday must be a date in the past",
      })
      .optional(),
  ),
  caregiver_legal_sex: z.nativeEnum(Sex).optional(),
  caregiver_race: enumFromKeys(RaceMap).optional(),
  caregiver_ethnicity: enumFromKeys(EthnicityMap).optional(),
  caregiver_relationship_type: enumFromKeys(RelationshipTypeMap).optional(),
  caregiver_lives_with_patient: z.boolean().optional(),
  caregiver_is_medicare_beneficiary: enumFromKeys(
    MedicareBeneficiaryTypeMap,
  ).optional(),
  caregiver_mbi: optionalString(
    z
      .string()
      .min(1, { message: "Medicare Beneficiary Identifier is required." })
      .length(11, {
        message: "Medicare Beneficiary Identifier must be 11 characters.",
      }),
  ),
  caregiver_since_years: z.preprocess(
    (value) => (typeof value === "string" ? Number.parseInt(value, 10) : value),
    z.number().min(0, { message: "Years must be non-negative." }).optional(),
  ),
  caregiver_since_months: z.preprocess(
    (value) => (typeof value === "string" ? Number.parseInt(value, 10) : value),
    z.number().min(0, { message: "Months must be non-negative." }).optional(),
  ),
});

export const formSchema = baseForm.superRefine(
  validateCaregiverFields<typeof baseForm>(requiredBaseFieldsForCaregiver),
);
export const alignmentFormSchema = extendedForm.superRefine(
  validateCaregiverFields<typeof extendedForm>(requiredFieldsForCaregiver),
);

export type FormSchema = z.infer<typeof formSchema>;
export type AlignmentFormSchema = z.infer<typeof alignmentFormSchema>;
