import * as Sentry from "@sentry/react";
import type { Session } from "@supabase/supabase-js";
import { useMutation, useQuery } from "@tanstack/react-query";
import { supabase } from "clients/supabaseClient";
import type { BillingPageData } from "components/BillingPage/types";
import { ALL_ORGANIZATIONS } from "features/routing/layouts/components/organization-switcher";
import { useAuthUser } from "features/users/auth";
import qs from "qs";
import type { YellowPagesListingSchema } from "shared/forms/types";
import { useActiveOrganizationId } from "state/organization/organization";
import type { z } from "zod";
import { QUERY_KEYS } from "../query-keys";
import type { DiscussionMessage } from "../resources/chatGptMessage/chatGptMessage";

const BACKEND_URL = import.meta.env.VITE_BACKEND_URL;

async function getSupabaseAccessToken(sessionParam?: Session | null) {
  let currentSession = sessionParam;
  if (!currentSession) {
    currentSession = (await supabase.auth.getSession()).data.session;
  }
  const accessToken = currentSession?.access_token;
  return accessToken;
}

/**
 * Onboarding
 */

export enum NotificationType {
  UPDATE_POSTED_IN_HUB = "New Hub Update",
  MESSAGE_POSTED_IN_HUB = "New Comment in The Hub",
  MESSAGE_POSTED_IN_CARESPACE = "New Comment in The Discussion",
  CARESPACE_DEACTIVATION = "Carespace Deactivation",
  EXTERNAL_SERVICE = "External Service",
  EXTERNAL_SERVICE_REQUEST = "External Service Request",
  EXTERNAL_DIRECT_MESSAGE = "External Direct Message",
}

export async function sendNotification(
  related_object_id: string,
  notif_type: NotificationType,
) {
  try {
    const response = await fetch(`${BACKEND_URL}/send_notification`, {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${await getSupabaseAccessToken()}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "authorization, x-client-info, apikey, content-type",
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({
        related_object_id,
        notif_type,
      }),
    });
    const responseBody = await response.json();
    return responseBody;
  } catch (error: unknown) {
    Sentry.captureException(error);
    return { error: error instanceof Error ? error.message : String(error) };
  }
}

async function submitAssessment(user_assessment_id: string) {
  try {
    const response = await fetch(`${BACKEND_URL}/submit_assessment`, {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${await getSupabaseAccessToken()}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "authorization, x-client-info, apikey, content-type",
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({
        user_assessment_id,
      }),
    });
    const responseBody = await response.json();
    if (response.status !== 200) {
      throw new Error(responseBody.detail);
    }
    return responseBody;
  } catch (error: unknown) {
    return { error: error instanceof Error ? error.message : String(error) };
  }
}

export async function oneSignal(
  user_id: string,
  is_starting_assessment?: boolean,
  is_onboarding?: boolean,
) {
  try {
    const response = await fetch(`${BACKEND_URL}/one_signal`, {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${await getSupabaseAccessToken()}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "authorization, x-client-info, apikey, content-type",
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({
        user_id,
        is_starting_assessment,
        is_onboarding,
      }),
    });
    const responseBody = await response.json();
    return responseBody;
  } catch (error: unknown) {
    Sentry.captureException(error);
    return { error: error instanceof Error ? error.message : String(error) };
  }
}

async function createPrivateConversation({
  user_ids,
  carespace_id,
  service_provider_id,
}: {
  user_ids: string[];
  carespace_id: string;
  service_provider_id?: string | undefined;
}) {
  const response = await fetch(`${BACKEND_URL}/create_private_conversation`, {
    method: "POST",
    mode: "cors",
    headers: {
      Authorization: `Bearer ${await getSupabaseAccessToken()}`,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
        "authorization, x-client-info, apikey, content-type",
      "Content-Type": "application/json; charset=utf-8",
    },
    body: JSON.stringify({ user_ids, carespace_id, service_provider_id }),
  });
  const responseBody = await response.json();
  return responseBody;
}

export const useCreatePrivateConversationMutation = () => {
  const { authUser } = useAuthUser();
  return useMutation({
    mutationFn: async ({
      user_id,
      carespace_id,
      service_provider_id,
    }: {
      user_id?: string | undefined;
      carespace_id: string;
      service_provider_id?: string | undefined;
    }) => {
      if (!authUser || (!user_id && !service_provider_id)) return null;
      const userIds = user_id ? [authUser.id, user_id] : [authUser.id];
      return createPrivateConversation({
        user_ids: userIds,
        carespace_id,
        service_provider_id,
      });
    },
  });
};

async function createCarespace({
  name,
  orgId,
  carespace_creator_id,
}: {
  name: string;
  orgId: string;
  carespace_creator_id: string;
}) {
  try {
    const response = await fetch(`${BACKEND_URL}/create_carespace`, {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: `Bearer ${await getSupabaseAccessToken()}`,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "authorization, x-client-info, apikey, content-type",
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify({
        name,
        org_id: orgId,
        carespace_creator_id,
      }),
    });
    const responseBody = await response.json();
    if (response.status !== 200) {
      throw new Error(responseBody.detail);
    }
    return responseBody;
  } catch (error: unknown) {
    Sentry.captureException(error);
    return { error: error instanceof Error ? error.message : String(error) };
  }
}

/**
 * search_local_resources
 */

export type YellowPagesListing = z.infer<typeof YellowPagesListingSchema>;

const useSearchLocalResources = (yplocation?: string, ypkeyword?: string) =>
  useQuery({
    queryKey: [QUERY_KEYS.localResources, yplocation, ypkeyword],
    queryFn: async () => {
      const { data } = await searchLocalResources({
        yplocation: yplocation || "",
        ypkeyword: ypkeyword || "",
      });
      return data;
    },
  });

export function useBillingPageData(
  hideIneligible: boolean,
  selectedMonth: number | undefined,
  selectedYear: number | undefined,
) {
  const organizationId = useActiveOrganizationId();
  return useQuery({
    queryKey: [
      QUERY_KEYS.billingPageData,
      {
        organizationId,
        hideIneligible,
        month: selectedMonth,
        year: selectedYear,
      },
    ],
    queryFn: async () => {
      try {
        if (!organizationId) {
          throw new Error("Please select an organization");
        }

        if (organizationId === ALL_ORGANIZATIONS) {
          throw new Error(
            "The billing data is not available for multiple organizations",
          );
        }

        const response = await fetch(
          `${BACKEND_URL}/get_billing_page_info?organization_id=${organizationId}&filter_out_ineligible=${hideIneligible}&month=${selectedMonth}&year=${selectedYear}`,
          {
            method: "GET",
            mode: "cors",
            headers: {
              Authorization: `Bearer ${await getSupabaseAccessToken()}`,
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Headers":
                "authorization, x-client-info, apikey, content-type",
              "Content-Type": "application/json; charset=utf-8",
            },
          },
        );
        if (response.status !== 200) {
          throw new Error(await response.text());
        }
        const responseBody = await response.json();
        return responseBody as BillingPageData;
      } catch (error: unknown) {
        Sentry.captureException(error);
        return null;
      }
    },
  });
}

async function searchLocalResources({
  yplocation,
  ypkeyword,
}: {
  yplocation: string;
  ypkeyword: string;
}) {
  try {
    const query = qs.stringify({ yplocation, ypkeyword });
    const response = await fetch(
      `${BACKEND_URL}/find_local_resources?${query}`,
      {
        mode: "cors",
        headers: {
          Authorization: `Bearer ${await getSupabaseAccessToken()}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "authorization, x-client-info, apikey, content-type",
          "Content-Type": "application/json; charset=utf-8",
        },
      },
    );
    if (response.status !== 200) {
      throw new Error(await response.text());
    }
    const responseBody = (await response.json()) as YellowPagesListing[];
    return { data: responseBody, error: null };
  } catch (error: unknown) {
    Sentry.captureException(error);
    return { error: error instanceof Error ? error.message : String(error) };
  }
}

async function updateCarespaceCareNavigator({
  carespace_ids,
  care_navigator_id,
}: {
  carespace_ids: string[];
  care_navigator_id: string;
}) {
  try {
    const response = await fetch(
      `${BACKEND_URL}/update-network-care-navigator`,
      {
        method: "POST",
        mode: "cors",
        headers: {
          Authorization: `Bearer ${await getSupabaseAccessToken()}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "authorization, x-client-info, apikey, content-type",
          "Content-Type": "application/json; charset=utf-8",
        },
        body: JSON.stringify({ network_ids: carespace_ids, care_navigator_id }),
      },
    );

    const responseBody = await response.json();
    if (response.status !== 200) {
      throw new Error(responseBody.detail);
    }

    return responseBody;
  } catch (error: unknown) {
    Sentry.captureException(error);
    return { error: error instanceof Error ? error.message : String(error) };
  }
}

export async function fetchConversations(
  userId: string | undefined,
  carespaceIds: string[],
) {
  if (!userId || !carespaceIds || carespaceIds.length === 0) {
    return [];
  }

  const queryString = carespaceIds
    .map((id) => `network_ids=${encodeURIComponent(id)}`)
    .join("&");

  try {
    const response = await fetch(
      `${BACKEND_URL}/fetch_conversations?user_id=${userId}&${queryString}`,
      {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization: `Bearer ${await getSupabaseAccessToken()}`,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Headers":
            "authorization, x-client-info, apikey, content-type",
          "Content-Type": "application/json; charset=utf-8",
        },
      },
    );
    const responseBody = await response.json();
    return responseBody as DiscussionMessage[];
  } catch (e) {
    Sentry.captureException(e);
    return [];
  }
}
