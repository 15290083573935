import AlfredIcon from "components/Alfred/Alfred";
import { Message } from "components/ChatGptSideBar/Message";
import { renderContentWithBreaks } from "components/ChatGptSideBar/Message/utils";
import { TypingIndicator } from "components/TypingIndicator";
import { useEffect, useRef } from "react";
import Skeleton from "react-loading-skeleton";
import type { SideBarPageType } from "state/gpt";
import { useGptStore } from "state/gpt";
import { useChatGptMessages } from "../../../backend/resources/chatGptMessage";

type Props = {
  threadId?: string | undefined;
  pageType: SideBarPageType;
  children?: React.ReactNode;
};

const PAGE_TYPES_WITHOUT_ALFRED = [
  "userUpdate",
  "carespace",
  "service",
  "serviceRequest",
];

export function MessagesThread({ threadId, pageType, children }: Props) {
  /**
   * Stores
   */

  const streamingMessage = useGptStore((state) => state.streamingMessage);
  const pendingMessage = useGptStore((state) => state.pendingMessage);

  /**
   * Refs
   */

  const messagesContainerRef = useRef<HTMLDivElement>(null);

  /**
   * Queries
   */

  const { isLoadingMessages, messages } = useChatGptMessages(
    threadId,
    pageType,
  );

  /**
   * Handlers
   */

  function scrollToBottomOfMessages() {
    if (messagesContainerRef?.current) {
      const { scrollHeight } = messagesContainerRef.current;
      messagesContainerRef.current.scrollTop = scrollHeight;
    }
  }

  function isUserScrolledToBottom(element: HTMLElement): boolean {
    const { scrollTop, clientHeight, scrollHeight } = element;
    // Using a tolerance to account for fractional values
    const tolerance = 80;
    return scrollHeight - scrollTop <= clientHeight + tolerance;
  }

  /**
   * Effects
   */

  useEffect(() => {
    scrollToBottomOfMessages();
  }, [messages]);

  useEffect(() => {
    if (
      messagesContainerRef?.current &&
      isUserScrolledToBottom(messagesContainerRef.current)
    ) {
      scrollToBottomOfMessages();
    }
  }, [streamingMessage]);

  return (
    <div
      ref={messagesContainerRef}
      className="flex flex-col min-w-0 px-4 py-2 gap-6 relative overflow-y-scroll flex-grow max-w-full w-full overflow-x-hidden"
    >
      {!isLoadingMessages && children}
      {isLoadingMessages ? (
        <div className="flex flex-col gap-4">
          <div className="flex items-center gap-3">
            <Skeleton className="w-[200px] h-3" count={2} />
            <Skeleton circle className="w-10 h-10" />
          </div>
          <div className="flex items-center gap-3">
            <Skeleton circle className="w-10 h-10" />
            <Skeleton className="w-[200px] h-3" count={2} />
          </div>
          <div className="flex items-center gap-3">
            <Skeleton className="w-[200px] h-3" count={2} />
            <Skeleton circle className="w-10 h-10" />
          </div>
        </div>
      ) : (
        messages?.map((message) => (
          <Message key={message.id} message={message} />
        ))
      )}
      {streamingMessage && !PAGE_TYPES_WITHOUT_ALFRED.includes(pageType) ? (
        <div className="flex min-w-0 gap-3">
          <AlfredIcon className="flex-shrink-0 w-8 h-8 mt-1" />
          <p className="bg-gray-50 pt-4 text-sm px-4 py-2 rounded-md">
            {renderContentWithBreaks(streamingMessage)}
          </p>
        </div>
      ) : null}
      {pendingMessage && !PAGE_TYPES_WITHOUT_ALFRED.includes(pageType) && (
        <div className="flex flex-col flex-grow justify-end">
          <TypingIndicator />
        </div>
      )}
    </div>
  );
}
