import EmptyProfileIcon from "assets/default-user.svg?react";
import { useSignedUrl } from "hooks/useSignedUrl";
import React from "react";
import Skeleton from "react-loading-skeleton";

import { useFetchOne } from "features/users/queries/hooks";

interface Props {
  userId?: string;
  className?: string;
  size?: number;
  isLoading?: boolean;
  tempProfileImageFilename?: string | undefined | null;
  imageUrl?: string | undefined | null;
}

const ProfileImageComponent = ({
  userId,
  className,
  size = 100,
  isLoading,
  tempProfileImageFilename,
  imageUrl,
}: Props) => {
  const { data: user, isLoading: isUserLoading } = useFetchOne(
    {
      equals: { id: userId },
    },
    {
      enabled: !!userId,
    },
  );

  const { signedUrl, isLoading: isLoadingProfileImage } = useSignedUrl({
    path: tempProfileImageFilename || user?.profile_image || undefined,
    bucket: "profile-images",
  });

  if (isLoadingProfileImage || isLoading || isUserLoading) {
    return <Skeleton className="mt-2" height={size} width={size} circle />;
  }
  if (signedUrl) {
    return (
      <img
        alt="profile"
        src={signedUrl}
        style={{
          height: size,
          width: size,
          minWidth: size,
        }}
        className={`rounded-full object-cover ${className}`}
      />
    );
  }
  if (imageUrl) {
    return (
      <img
        alt="profile"
        src={imageUrl}
        style={{
          height: size,
          width: size,
          minWidth: size,
        }}
        className={`rounded-full object-cover ${className}`}
      />
    );
  }
  return (
    <div
      className={`flex justify-center items-center ${className} flex-shrink-0`}
      style={{ height: size, width: size }}
    >
      <EmptyProfileIcon
        className={`rounded-full object-cover ${className}`}
        style={{ height: size, width: size }}
      />
    </div>
  );
};
export const ProfileImage = React.memo(ProfileImageComponent);
