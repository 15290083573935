import type { UserAssessmentAnswerWithQuestion } from "shared/types/user-assessment-answer";
/**
 * @fileoverview Sets up the Zustand assessment store.
 * This store holds information about the active assessment.
 */
import { create } from "zustand";

type State = {
  activeUserAssessmentId?: string | undefined;
  assessmentAnswers: {
    [assessment_question_id: string]: UserAssessmentAnswerWithQuestion;
  };
};

type Actions = {
  setActiveUserAssessmentId: (id: string | undefined) => void;
  setAssessmentAnswers: (answers: {
    [assessment_question_id: string]: UserAssessmentAnswerWithQuestion;
  }) => void;
  reset: () => void;
};

const initialState: State = {
  activeUserAssessmentId: undefined,
  assessmentAnswers: {},
};

// Create the store
export const useAssessmentStore = create<State & Actions>((set) => ({
  ...initialState,
  setActiveUserAssessmentId: (id) =>
    set(() => ({ activeUserAssessmentId: id })),
  setAssessmentAnswers: (answers) =>
    set(() => ({ assessmentAnswers: answers })),
  reset: () => set(() => initialState),
}));

export function resetAssessmentStore() {
  const reset = useAssessmentStore.getState().reset;
  reset();
}
