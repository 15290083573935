import BottomActionBar from "components/BottomActionBar/BottomActionBar";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { BottomSheet } from "components/Sheet";
import { useWindowSize } from "hooks/useWindowSize";
import { type ReactNode, useState } from "react";

export default function ActionButtons({
  children,
  title,
}: {
  children: ReactNode;
  title?: string;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const { isMobile } = useWindowSize();
  if (!isMobile) {
    return <>{children}</>;
  }
  if (!isOpen) {
    return (
      <BottomActionBar
        centerSlot={
          <ButtonWithIcon
            onClick={() => {
              setIsOpen(true);
            }}
            text=""
            icon={IconOption.MORE}
          />
        }
      />
    );
  }
  return (
    <BottomSheet isOpen={true} onClose={() => setIsOpen(false)}>
      <div
        className="flex flex-col gap-4 items-start pl-10 pb-10"
        onClick={() => setIsOpen(false)}
      >
        <h1 className="text-lg">{title}</h1>
        {children}
      </div>
    </BottomSheet>
  );
}
