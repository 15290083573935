import { useMutation, useQuery } from "@tanstack/react-query";
import { supabase } from "clients/supabaseClient";
import { ALL_ORGANIZATIONS } from "features/routing/layouts/components/organization-switcher";
import type { CarespaceRole, User } from "features/users/types";
import { useActiveCarespaceId } from "state/carespace/carespace";
import type { OrganizationSelection } from "state/organization/organization";
import { useActiveOrganizationId } from "state/organization/organization";
import type { Database } from "types/supabase";
import queryClient from "../../../shared/query-client";
import { QUERY_KEYS } from "../../query-keys";
import type { OrganizationRole } from "../orgRole/orgRole";
import { useActiveOrgRole, useOrgs } from "../orgRole/orgRole";
import type { Patient } from "../patient/patient";

const TABLE = "carespace";
export type Carespace = Database["public"]["Tables"]["carespace"]["Row"];
type PAFSubmission = Database["public"]["Tables"]["paf_submission"]["Row"];

// helper types
export type CarespacesWithPatientAndCaregivers = Carespace & {
  patient: Patient[];
  paf_submission: PAFSubmission[];
  organization: { name: string; software_only: boolean };
} & { user_role: { user: User; role: CarespaceRole }[] };

export const useActiveCarespaceName = () => {
  const carespaceId = useActiveCarespaceId();

  const { isLoading, error, data } = useQuery({
    queryKey: [QUERY_KEYS.carespaceName, { carespaceId }],
    queryFn: () => fetchCarespaceName(carespaceId),
    refetchOnWindowFocus: false,
  });
  return { carespaceName: data, isLoading, error };
};

export const useAddConversationToActiveCarespace = () => {
  const carespaceId = useActiveCarespaceId();
  return useMutation({
    mutationFn: async (conversationId: string) => {
      if (!carespaceId) return;
      await supabase
        .from("carespace")
        .update({ conversation_id: conversationId })
        .eq("id", carespaceId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.carespace],
      });
    },
  });
};

export const useCarespacesInOrganization = () => {
  const organizationId = useActiveOrganizationId();
  const { isSuperSuperUser } = useOrgs();
  const { data: activeCarespace } = useActiveCarespace();
  const { data: orgRole } = useActiveOrgRole();
  const { isLoading, error, data } = useQuery({
    queryKey: [
      QUERY_KEYS.carespacesInOrganization,
      { organizationId, orgRole, isSuperSuperUser, activeCarespace },
    ],
    queryFn: () =>
      fetchCarespacesByOrganizationIdAndActiveOrgRole(
        organizationId,
        orgRole,
        isSuperSuperUser,
      ),
  });
  const carespaces =
    data?.sort((a, b) => {
      if (a?.name && b?.name) {
        return a.name.localeCompare(b.name);
      }
      return 0;
    }) ?? (activeCarespace ? [activeCarespace] : []);

  const carespacesOptionsWithoutAll =
    carespaces?.map((carespace) => ({
      label: carespace.name || "Default",
      value: carespace.id,
    })) ?? [];
  const carespacesOptions = [
    { value: "All", label: "All" },
    ...carespacesOptionsWithoutAll,
  ];
  return {
    carespaces,
    carespacesOptions,
    carespacesOptionsWithoutAll,
    isLoading,
    error,
  };
};

export function useCarespace(carespaceId?: string) {
  const { isLoading, error, data } = useQuery({
    queryKey: [QUERY_KEYS.carespace, { carespaceId }],
    queryFn: () => fetchCarespaceById(carespaceId),
    refetchOnWindowFocus: false,
  });
  return { data, isLoading, error };
}

export function useActiveCarespace() {
  const activeCarespaceId = useActiveCarespaceId();
  return useCarespace(activeCarespaceId);
}

async function fetchCarespaceById(carespaceId?: string | null) {
  if (!carespaceId) {
    return null;
  }

  const { data, error } = await supabase
    .from(TABLE)
    .select(
      "*, organization(name, software_only), patient(*), user_role(user(*), role), paf_submission(id, status)",
    )
    .eq("id", carespaceId)
    .returns<CarespacesWithPatientAndCaregivers[]>();

  if (error) {
    throw new Error(error.message);
  }

  return data && data.length > 0 ? data[0] : null;
}

async function fetchCarespacesByOrganizationIdAndActiveOrgRole(
  organizationId: OrganizationSelection | undefined,
  orgRole: OrganizationRole | null | undefined,
  isSuperSuperUser: boolean,
) {
  if (!organizationId || (!orgRole && !isSuperSuperUser)) {
    return null;
  }

  let query = supabase
    .from(TABLE)
    .select(
      "*, paf_submission(status), organization(name), patient(*), user_role(user(*), role)",
    )
    .order("name", { ascending: true });

  if (organizationId !== ALL_ORGANIZATIONS) {
    query = query.eq("organization_id", organizationId);
  }

  const { data, error } =
    await query.returns<CarespacesWithPatientAndCaregivers[]>(); // asserts many to many relationships
  if (error) {
    throw new Error(error.message);
  }

  // If orgRole's is_superuser field is false, filter out carespaces
  if (!isSuperSuperUser && !orgRole?.is_superuser) {
    return data?.filter((carespace) =>
      carespace.user_role.some(
        (userRole) => userRole.user.id === orgRole?.user_id,
      ),
    );
  }

  return data;
}

async function fetchCarespaceName(carespaceId?: string | null) {
  if (!carespaceId) {
    return null;
  }

  const { data, error } = await supabase
    .from(TABLE)
    .select("name")
    .eq("id", carespaceId)
    .limit(1)
    .single();

  if (error) {
    throw new Error(error.message);
  }

  return data.name;
}

export const useAllCarespaces = () => {
  const query = useQuery({
    queryKey: [QUERY_KEYS.allCarespaces],
    queryFn: async () => {
      const { data, error } = await supabase
        .from(TABLE)
        .select(
          `
          *,
          paf_submission(status),
          organization(name, software_only),
          patient(*),
          user_role(user(*), role)
        `,
        )
        .order("name", { ascending: true })
        .returns<CarespacesWithPatientAndCaregivers[]>();

      if (error) {
        throw new Error(error.message);
      }

      return data ?? [];
    },
    refetchOnWindowFocus: false,
  });

  return {
    ...query,
    carespaces: query.data ?? [],
    isLoading: query.isLoading,
  };
};
