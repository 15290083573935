import type {
  ClinicianAttestation,
  DementiaStagingTool,
  Ethnicity,
  HasCaregiver,
  MedicareBeneficiaryType,
  PatientTier,
  PhoneType,
  Race,
  ReferralSource,
  RelationshipType,
  ResidenceType,
} from "./types";

export const TABLE_NAME = "carespace";
export const ACTIVITY_TABLE_NAME = "activity";
export const GUIDE_STATUS_TABLE_NAME = "guide_status";
export const ASSESSMENT_TABLE_NAME = "assessment";
export const USER_ASSESSMENT_TABLE_NAME = "user_assessment";
export const USER_ASSESSMENT_ANSWER_TABLE_NAME = "user_assessment_answer";

export enum Sex {
  MALE = "Male",
  FEMALE = "Female",
  UNKNOWN = "Unknown",
}

export const PatientTierMap: Record<PatientTier, string> = {
  low: "Low",
  moderate: "Moderate",
  high: "High",
  na: "N/a",
};

export const RaceMap: Record<Race, string> = {
  american_indian_or_alaska_native: "American Indian or Alaska Native",
  asian: "Asian",
  black_or_african_american: "Black or African American",
  native_hawaiian_or_other_pacific_islander:
    "Native Hawaiian or Other Pacific Islander",
  white: "White",
  other: "Other",
  declined_to_specify: "Declined to specify",
  unknown: "Unknown",
};

export const EthnicityMap: Record<Ethnicity, string> = {
  hispanic_or_latino: "Hispanic or Latino",
  not_hispanic_or_latino: "Not Hispanic or Latino",
  declined_to_specify: "Declined to specify",
  unknown: "Unknown",
};

export const DementiaStagingToolMap: Record<DementiaStagingTool, string> = {
  fast: "FAST",
  cdr: "CDR",
  alternative: "Alternative",
};

export const HasCaregiverMap: Record<HasCaregiver, string> = {
  yes_multiple: "Yes (multiple)",
  yes_one: "Yes (one)",
  no: "No",
  undetermined: "Undetermined",
};

export const PhoneTypeMap: Record<PhoneType, string> = {
  home: "Home (landline)",
  business: "Business",
  mobile: "Mobile",
};

export const ResidenceTypeMap: Record<ResidenceType, string> = {
  private_residence: "Private Residence",
  assisted_living_facility: "Assisted Living Facility",
  memory_care_program: "Memory Care Program",
};

export const RelationshipTypeMap: Record<RelationshipType, string> = {
  spouse: "Spouse",
  domestic_partner: "Domestic Partner",
  daughter: "Daughter",
  son: "Son",
  sibling: "Sibling",
  other_family_member: "Other Family Member",
  friend: "Friend",
  other_non_family_member: "Other Non-Family Member",
};

export const MedicareBeneficiaryTypeMap: Record<
  MedicareBeneficiaryType,
  string
> = {
  yes: "Yes",
  no: "No",
  declined_to_provide: "Declined to provide",
};

export const ClinicianAttestationMap: Record<ClinicianAttestation, string> = {
  meets_guidelines:
    "Yes, the patient meets the National Institute on Aging-Alzheimer’s Association diagnostic guidelines for dementia and/or the DSM-5 diagnostic guidelines for major neurocognitive disorder.",
  written_report:
    "Yes, I received a written report of a documented dementia diagnosis.",
  cannot_attest: "No, I cannot attest to either statement.",
};

export const ReferralSourceMap: Record<ReferralSource, string> = {
  referred_by_healthcare_provider: "Referred by a healthcare provider",
  referred_by_community_organization:
    "Referred by a community-based organization",
  self_referral: "Self-referral",
};

export const icd10Codes = [
  "F01.50 Vascular dementia",
  "F01.511 Vascular dementia, unspecified severity, with agitation",
  "F01.518 Vascular dementia, unspecified severity, with other behavioral disturbance",
  "F01.A0 Vascular dementia, mild, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
  "F01.A11 Vascular dementia, mild, with agitation",
  "F01.A18 Vascular dementia, mild, with other behavioral disturbance",
  "F01.A2 Vascular dementia, mild, with psychotic disturbance",
  "F01.A3 Vascular dementia, mild, with mood disturbance",
  "F01.A4 Vascular dementia, mild, with anxiety",
  "F01.B0 Vascular dementia, moderate, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety F01.B11 Vascular dementia, moderate, with agitation",
  "F01.B18 Vascular dementia, moderate, with other behavioral disturbance",
  "F01.B2 Vascular dementia, moderate, with psychotic disturbance",
  "F01.B3 Vascular dementia, moderate, with mood disturbance",
  "F01.B4 Vascular dementia, moderate, with anxiety",
  "F01.C0 Vascular dementia, severe, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
  "F01.C11 Vascular dementia, severe, with agitation",
  "F01.C18 Vascular dementia, severe, with other behavioral disturbance",
  "F01.C2 Vascular dementia, severe, with psychotic disturbance",
  "F01.C3 Vascular dementia, severe, with mood disturbance",
  "F01.C4 Vascular dementia, severe, with anxiety",
  "F02.80 Dementia in other diseases classified elsewhere",
  "F02.811 Dementia in other diseases classified elsewhere, unspecified severity, with agitation",
  "F02.818 Dementia in other diseases classified elsewhere, unspecified severity, with other behavioral disturbance",
  "F02.A0 Dementia in other diseases classified elsewhere, mild, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
  "F02.A11 Dementia in other diseases classified elsewhere, mild, with agitation",
  "F02.A18 Dementia in other diseases classified elsewhere, mild, with other behavioral disturbance",
  "F02.A2 Dementia in other diseases classified elsewhere, mild, with psychotic disturbance",
  "F02.A3 Dementia in other diseases classified elsewhere, mild, with mood disturbance",
  "F02.A4 Dementia in other diseases classified elsewhere, mild, with anxiety",
  "F02.B0 Dementia in other diseases classified elsewhere, moderate, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
  "F02.B11 Dementia in other diseases classified elsewhere, moderate, with agitation",
  "F02.B18 Dementia in other diseases classified elsewhere, moderate, with other behavioral disturbance",
  "F02.B2 Dementia in other diseases classified elsewhere, moderate, with psychotic disturbance",
  "F02.B3 Dementia in other diseases classified elsewhere, moderate, with mood disturbance",
  "F02.B4 Dementia in other diseases classified elsewhere, moderate, with anxiety",
  "F02.C0 Dementia in other diseases classified elsewhere, severe, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
  "F02.C11 Dementia in other diseases classified elsewhere, severe, with agitation",
  "F02.C18 Dementia in other diseases classified elsewhere, severe, with other behavioral disturbance",
  "F02.C2 Dementia in other diseases classified elsewhere, severe, with psychotic disturbance",
  "F02.C3 Dementia in other diseases classified elsewhere, severe, with mood disturbance",
  "F02.C4 Dementia in other diseases classified elsewhere, severe, with anxiety",
  "F03.90 Dementia (degenerative (primary)) (old age) (persisting)",
  "F03.911 Unspecified dementia, unspecified severity, with agitation",
  "F03.918 Unspecified dementia, unspecified severity, with other behavioral disturbance",
  "F03.A0 Unspecified dementia, mild, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
  "F03.A11 Unspecified dementia, mild, with agitation",
  "F03.A18 Unspecified dementia, mild, with other behavioral disturbance",
  "F03.A2 Unspecified dementia, mild, with psychotic disturbance",
  "F03.A3 Unspecified dementia, mild, with mood disturbance",
  "F03.A4 Unspecified dementia, mild, with anxiety",
  "F03.B0 Unspecified dementia, moderate, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
  "F03.B11 Unspecified dementia, moderate, with agitation",
  "F03.B18 Unspecified dementia, moderate, with other behavioral disturbance",
  "F03.B2 Unspecified dementia, moderate, with psychotic disturbance",
  "F03.B3 Unspecified dementia, moderate, with mood disturbance",
  "F03.B4 Unspecified dementia, moderate, with anxiety",
  "F03.C0 Unspecified dementia, severe, without behavioral disturbance, psychotic disturbance, mood disturbance, and anxiety",
  "F03.C11 Unspecified dementia, severe, with agitation",
  "F03.C18 Unspecified dementia, severe, with other behavioral disturbance",
  "F03.C2 Unspecified dementia, severe, with psychotic disturbance",
  "F03.C3 Unspecified dementia, severe, with mood disturbance",
  "F03.C4 Unspecified dementia, severe, with anxiety",
  "F10.27 Alcohol dependence with alcohol induced persisting dementia",
  "F10.97 Alcohol use, unspecified with alcohol induced persisting dementia",
  "F13.27 Sedative, hypnotic or anxiolytic dependence with sedative, hypnotic or anxiolytic induced persisting dementia",
  "F13.97 Sedative, hypnotic or anxiolytic use, unspecified with sedative, hypnotic or anxiolytic induced persisting dementia",
  "F18.97 Inhalant use, unspecified with inhalant induced persisting dementia",
  "F19.17 Other psychoactive substance abuse with psychoactive substance induced persisting dementia",
  "F19.27 Other psychoactive substance dependence with psychoactive substance induced persisting dementia",
  "F19.97 Other psychoactive substance use, unspecified with psychoactive substance induced persisting dementia",
  "G30.0 Alzheimer's disease",
  "G30.1 Alzheimer's disease with late onset",
  "G30.8 Other Alzheimer's disease",
  "G30.9 Alzheimer's disease, unspecified",
  "G31.01 Pick's disease",
  "G31.09 Other front temporal dementia",
  "G31.1 Senile degeneration of brain, not elsewhere classified",
  "G31.2 Degeneration of nervous system due to alcohol",
  "G31.83 Neurocognitive disorder with Lewy bodies",
] as const;
