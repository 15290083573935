import type { Connection, Edge, NodeTypes } from "@xyflow/react";
import {
  Background,
  BackgroundVariant,
  ConnectionLineType,
  MarkerType,
  MiniMap,
  Panel,
  ReactFlow,
  addEdge,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from "@xyflow/react";
import { useEffect, useRef, useState } from "react";
import { v4 as uuidV4 } from "uuid";
import "@xyflow/react/dist/style.css";
import isEqual from "lodash/isEqual";
import { CloudUpload, Lock, LockOpen, Plus } from "lucide-react";
import { Button } from "shared/ui/button";
import ActivityNodeComponent from "../components/activity-node";
import type { ActivityNode, ActivityNodeProps } from "../types";
import CareFlowNodeEditor from "./careflow-node-editor";

type ActivityEdge = {
  id: string;
  source: string;
  target: string;
};
type CareFlowViewerProps = {
  nodes: ActivityNodeProps[];
  edges: ActivityEdge[];
  onSave: (nodes: ActivityNodeProps[], edges: ActivityEdge[]) => Promise<void>;
  isSaving: boolean;
  isDirty: boolean;
  setIsDirty: (isDirty: boolean) => void;
};

const nodeTypes: NodeTypes = { activity: ActivityNodeComponent };
const NODE_WIDTH = 340;
const NODE_HEIGHT = 102;

function parseNodes(nodes: ActivityNodeProps[]): ActivityNode[] {
  return nodes.map((node) => ({
    id: node.id,
    type: "activity",
    data: node,
    position: node.editor_position,
    width: NODE_WIDTH,
    height: NODE_HEIGHT,
  }));
}

function formatNodes(nodes: ActivityNode[]): ActivityNodeProps[] {
  return nodes.map((node) => ({
    ...node.data,
    editor_position: node.position,
  }));
}

export default function CareFlowViewer(props: CareFlowViewerProps) {
  const {
    nodes: propNodes,
    edges: propEdges,
    isDirty,
    setIsDirty,
    isSaving,
  } = props;
  const reactFlowWrapper = useRef<HTMLDivElement>(null);
  const { screenToFlowPosition } = useReactFlow();
  const [nodes, setNodes, onNodesChange] = useNodesState<ActivityNode>(
    parseNodes(propNodes),
  );
  const [edges, setEdges, onEdgesChange] = useEdgesState<Edge>(propEdges);
  const [editingNode, setEditingNode] = useState<ActivityNodeProps | null>(
    null,
  );
  const [isLocked, setIsLocked] = useState(true);

  // Effect to check for unsaved changes
  useEffect(() => {
    // Convert the current nodes to the data format for comparison
    const currentNodesData = formatNodes(nodes);
    const nodesChanged = !isEqual(currentNodesData, propNodes);
    const edgesChanged = !isEqual(edges, propEdges);

    if (nodesChanged || edgesChanged) {
      setIsDirty(true);
    } else {
      setIsDirty(false);
    }
  }, [nodes, edges, propNodes, propEdges, setIsDirty]);

  function onConnect(connection: Connection) {
    setEdges((eds) =>
      addEdge(
        {
          ...connection,
          id: uuidV4(),
        },
        eds,
      ),
    );
  }

  function onNodeDoubleClick(_event: React.MouseEvent, node: ActivityNode) {
    setEditingNode(node.data);
  }

  function getViewportCenter() {
    if (!reactFlowWrapper.current) return { x: 0, y: 0 };
    const bounds = reactFlowWrapper.current.getBoundingClientRect();
    const centerScreen = {
      x: bounds.x + bounds.width / 2,
      y: bounds.y + bounds.height / 2,
    };
    // Converts screen coordinates to the canvas coordinate system (accounts for zoom and pan)
    const canvasCenter = screenToFlowPosition(centerScreen);

    return {
      x: canvasCenter.x - NODE_WIDTH / 2,
      y: canvasCenter.y - NODE_HEIGHT / 2,
    };
  }

  function addNode() {
    const newNodeId = uuidV4();
    const newNodePosition = getViewportCenter();
    const newNode: ActivityNode = {
      id: newNodeId,
      type: "activity",
      position: newNodePosition,
      width: NODE_WIDTH,
      height: NODE_HEIGHT,
      data: {
        id: newNodeId,
        title: "Untitled Activity",
        description: "",
        owner: "carenavigator",
        type: "onboarding",
        active: true,
        deadline: undefined,
        recurrence: undefined,
        editor_position: newNodePosition,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
      },
    };

    setNodes((nds) => [...nds, newNode]);
    setEditingNode(newNode.data);
  }

  function saveNodeChanges(node: ActivityNodeProps) {
    setNodes((nds) =>
      nds.map((n) => (n.id === node.id ? { ...n, data: node } : n)),
    );
    setEditingNode(null);
  }

  async function handleOnSave() {
    await props.onSave(formatNodes(nodes), edges);
    props.setIsDirty(false);
  }

  return (
    <div ref={reactFlowWrapper} className="h-full w-full">
      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        nodesDraggable={!isLocked}
        nodesConnectable={!isLocked}
        nodesFocusable={!isLocked}
        edgesFocusable={!isLocked}
        elementsSelectable={!isLocked}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onNodeDoubleClick={onNodeDoubleClick}
        connectionLineType={ConnectionLineType.SmoothStep}
        defaultEdgeOptions={{
          type: "smoothstep",
          markerEnd: {
            type: MarkerType.ArrowClosed,
            color: "#000",
            width: 16,
            height: 16,
          },
        }}
      >
        <MiniMap />
        <Background variant={BackgroundVariant.Dots} gap={12} size={1} />
        {editingNode ? (
          <Panel
            position="top-right"
            className="h-full w-1/2 border-l bg-white absolute z-50 right-0 p-4 m-0 overflow-y-auto"
          >
            <CareFlowNodeEditor
              node={editingNode}
              onSave={saveNodeChanges}
              onCancel={() => setEditingNode(null)}
            />
          </Panel>
        ) : (
          <Panel
            position="bottom-center"
            className="bg-white p-2 shadow-md rounded border border-gray-100 flex flex-row gap-2"
          >
            {isLocked ? (
              <Button variant="outline" onClick={() => setIsLocked(false)}>
                <Lock size={16} />
                Unlock
              </Button>
            ) : (
              <Button variant="outline" onClick={() => setIsLocked(true)}>
                <LockOpen size={16} />
                Lock
              </Button>
            )}
            <Button
              variant="outline"
              disabled={isSaving || isLocked}
              onClick={() => addNode()}
            >
              <Plus size={16} /> Add Activity
            </Button>
            <Button
              disabled={!isDirty || isSaving}
              onClick={handleOnSave}
              isLoading={isSaving}
            >
              <CloudUpload size={16} /> Save
            </Button>
          </Panel>
        )}
      </ReactFlow>
    </div>
  );
}
