import BackButton from "components/BackButton/BackButton";
import { DiscussionType, UserDiscussion } from "components/ChatGptSideBar";
import { PageContainer } from "components/PageContainer";
import { PageMainHeaderWithCarespaceName } from "components/PageMainHeader/PageMainHeaderWithCarespace";
import { useActiveCarespace } from "../../backend/resources/carespace/carespace";
import { useAlfredPageSideBar } from "../../backend/resources/chatGptConversation";

export default function GeneralDiscussionPage() {
  // Queries
  useAlfredPageSideBar("discussionsPage");
  const { data: activeCarespace } = useActiveCarespace();

  return (
    <PageContainer>
      <BackButton className="mb-2" />
      <PageMainHeaderWithCarespaceName text="General Discussion" />
      <div className="h-full pb-24 w-full pt-2">
        <UserDiscussion
          discussionType={DiscussionType.Carespace}
          hideBorder
          threadId={activeCarespace?.id}
        />
      </div>
    </PageContainer>
  );
}
