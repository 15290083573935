import { Splide, SplideSlide } from "@splidejs/react-splide";

export default function Carousel({
  items,
  showArrowsInMobile,
}: {
  items: React.ReactNode[];
  showArrowsInMobile?: boolean;
}) {
  return (
    <div className="md:px-10">
      <Splide
        aria-label="news-articles"
        options={{
          trimSpace: true,
          type: "slide",
          padding: { right: "10px", left: "0px" },
          autoWidth: true,
          pagination: false,
        }}
        className={
          showArrowsInMobile ? "my-splide show-arrows-on-mobile" : "my-splide"
        }
      >
        {items?.map((item: React.ReactNode, index: number) => (
          <SplideSlide
            key={`additional-content-${index}`}
            className="mx-1 md:mx-2 relative"
          >
            {item}
          </SplideSlide>
        ))}
      </Splide>
    </div>
  );
}
