import { zodResolver } from "@hookform/resolvers/zod";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { LoadingSpinner } from "components/LoadingSpinner";
import { TextArea } from "components/TextArea";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { Form, FormField } from "shared/ui/form";
import { Input } from "shared/ui/input";
import { ResponsiveModal } from "shared/ui/responsive-modal";
import { Title } from "shared/ui/title";
import { z } from "zod";
import {
  useServiceEngagementById,
  useUpdateServiceEngagement,
} from "../../../backend/resources/services/serviceEngagement";

const editServicePageFormSchema = z.object({
  name: z.string(),
  details: z.string().optional(),
});

const useEditServicePageForm = (
  defaultValues?: z.infer<typeof editServicePageFormSchema>,
) =>
  useForm<z.infer<typeof editServicePageFormSchema>>({
    resolver: zodResolver(editServicePageFormSchema),
    defaultValues: defaultValues ?? {},
    mode: "onSubmit",
  });

const editServicePageFormId = "edit-service-page-form";

export function EditServicePageButton() {
  const [isEditServicePageModalOpen, setIsEditServicePageModalOpen] =
    useState(false);
  const [isSending, setIsSending] = useState(false);
  const { id: serviceId } = useParams<{ id: string }>();

  const { data: serviceEngagementData, isLoading } =
    useServiceEngagementById(serviceId);
  const form = useEditServicePageForm({
    name: serviceEngagementData?.name ?? "",
    details: serviceEngagementData?.details ?? "",
  });
  const updateServiceEngagement = useUpdateServiceEngagement().mutateAsync;

  useEffect(() => {
    form.reset({
      name: serviceEngagementData?.name ?? "",
      details: serviceEngagementData?.details ?? "",
    });
  }, [form, serviceEngagementData]);

  async function handleSubmit(
    validatedForm: z.infer<typeof editServicePageFormSchema>,
  ) {
    setIsSending(true);
    if (serviceId)
      await updateServiceEngagement({
        id: serviceId,
        ...validatedForm,
      });
    setIsSending(false);
  }

  if (isLoading) {
    return <LoadingSpinner className="w-6 h-6" />;
  }

  return (
    <>
      <ButtonWithIcon
        onClick={() => setIsEditServicePageModalOpen(true)}
        size="small"
        text=""
        icon={IconOption.EDIT}
      />

      <ResponsiveModal
        isOpen={isEditServicePageModalOpen}
        closeText="Back"
        title="Edit Service Page"
        isNetworkCallInProgress={isSending}
        onClose={() => {
          setIsEditServicePageModalOpen(false);
          form.reset();
        }}
        footerButtons={
          <>
            <ButtonWithIcon
              text="Cancel"
              icon={IconOption.CANCEL}
              onClick={() => {
                setIsEditServicePageModalOpen(false);
                form.reset();
              }}
            />
            <ButtonWithIcon
              text="Done"
              icon={IconOption.CHECKMARK}
              onClick={async () => {
                await form.handleSubmit((values) => {
                  handleSubmit(values);
                  setIsEditServicePageModalOpen(false);
                  form.reset();
                })();
              }}
            />
          </>
        }
      >
        <Form form={form} id={editServicePageFormId}>
          <Title>Edit Service Page</Title>
          <FormField
            control={form.control}
            name="name"
            label="Name"
            render={({ field }) => <Input {...field} />}
          />
          <FormField
            control={form.control}
            name="details"
            label="Details"
            render={({ field }) => (
              <TextArea className="w-full text-sm" {...field} />
            )}
          />
        </Form>
      </ResponsiveModal>
    </>
  );
}
