import * as Sentry from "@sentry/react";
import { useMutation, useQuery } from "@tanstack/react-query";

import { supabase } from "clients/supabaseClient";
import queryClient from "shared/query-client";

import {
  type Filter,
  type QueryOptions,
  buildFilters,
} from "features/query-utils";

import type { OrganizationRole } from "features/users/constants";
import { queryKeys as userQueryKeys } from "features/users/queries";

import type { OrganizationInvitationInsert } from "../../../backend/resources/organizationInvitations/organizationInvitation";

import type {
  OrganizationInsert,
  OrganizationRoleTableName,
  OrganizationRoleUpdate,
  OrganizationUpdate,
  TableName,
} from "../types";

import {
  fields,
  insert,
  queryKeys,
  select,
  selectUserRoles,
  update,
  updateRole,
} from ".";

export function useFetchOne(
  filter: Filter<TableName>,
  options: QueryOptions = {},
) {
  const { enabled } = options;

  return useQuery({
    queryKey: queryKeys.detail(filter),
    queryFn: async () => {
      const query = buildFilters(select(), filter);

      const { data, error } = await query.limit(1).single();

      if (error) {
        Sentry.captureException(error);
        throw error;
      }

      return data;
    },
    enabled,
  });
}

export function useFetchMany(
  filter?: Filter<TableName>,
  options: QueryOptions = {},
) {
  const { enabled } = options;

  return useQuery({
    queryKey: queryKeys.detail(filter),
    queryFn: async () => {
      const query = buildFilters(select(), filter);

      const { data, error } = await query;

      if (error) {
        Sentry.captureException(error);
        throw error;
      }

      return data;
    },
    enabled,
  });
}

export function useUpdate(filter: Filter<TableName>) {
  return useMutation({
    mutationFn: async (organizationUpdate: OrganizationUpdate) => {
      const mutation = buildFilters(update(organizationUpdate), filter);

      const { data, error } = await mutation.select(fields).maybeSingle();

      if (!data) {
        const finalError =
          error ||
          new Error(`No data found for organization after update:
          filter: ${JSON.stringify(filter, null, 2)}

          organizationUpdate: ${JSON.stringify(organizationUpdate, null, 2)}
        `);

        Sentry.captureException(finalError);
        throw finalError;
      }

      return data;
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: queryKeys.detail(filter),
      }),
  });
}

export function useInsert() {
  return useMutation({
    mutationFn: async (organizationInsert: OrganizationInsert) => {
      const { data, error } = await insert(organizationInsert);

      if (error) {
        Sentry.captureException(error);
        throw error;
      }

      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.lists,
      });
    },
  });
}

export function useGetUserRoles(
  filter: Filter<"organization_role">,
  options: QueryOptions = {},
) {
  const { enabled } = options;

  return useQuery({
    queryKey: queryKeys.userRole(filter),
    queryFn: async () => {
      const query = buildFilters(selectUserRoles(), filter);

      const { data, error } = await query;

      if (error) {
        Sentry.captureException(error);
        throw error;
      }

      return data.map((userRole) => ({
        ...userRole,
        role: userRole.role as OrganizationRole,
        is_super_org: userRole.organization?.is_super_org,
        organization_id: userRole.organization?.id,
      }));
    },
    enabled,
  });
}

export function useInviteUserToOrg() {
  return useMutation({
    mutationFn: async (params: OrganizationInvitationInsert) => {
      const { error } = await supabase.functions.invoke("invite_user", {
        body: params,
      });

      if (error) {
        Sentry.captureException(error);
        throw error;
      }

      return params;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.detail({ equals: { id: data.organization_id } }),
      });

      queryClient.invalidateQueries({
        queryKey: userQueryKeys.detail({ equals: { id: data.id } }),
      });
    },
  });
}

export function useUpdateRole(filter: Filter<OrganizationRoleTableName>) {
  return useMutation({
    mutationFn: async (organizationRoleUpdate: OrganizationRoleUpdate) => {
      const { data, error } = await buildFilters(
        updateRole(organizationRoleUpdate),
        filter,
      );

      if (error) {
        Sentry.captureException(error);
        throw error;
      }

      return data;
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: queryKeys.userRole(filter),
      }),
  });
}
