import PatientList from "components/BillingPage/components/PatientBreakdownSection";
import { RespiteCareSection } from "components/BillingPage/components/RespiteCareSection";
import StandardCareSection from "components/BillingPage/components/StandardCareSection";
import TotalsSection from "components/BillingPage/components/TotalsSection";
import { DefaultCheckbox } from "components/Checkbox";
import { PageContainer } from "components/PageContainer";
import { PageMainHeader } from "components/PageMainHeader";
import { useState } from "react";
import { DatePicker } from "shared/ui/date-picker";
import { useBillingPageData } from "../../backend/functions";

export default function BillingPage() {
  // States
  const [hideIneligible, setHideIneligible] = useState(true);
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  const [selectedMonth, setSelectedMonth] = useState<number>(currentMonth);
  const [selectedYear, setSelectedYear] = useState<number>(currentYear);

  // Query
  const { data } = useBillingPageData(
    hideIneligible,
    selectedMonth,
    selectedYear,
  );

  return (
    <PageContainer>
      <div className="flex flex-col gap-8">
        <PageMainHeader text="Billing" />
        {/* Filters */}
        <div className="flex gap-4">
          <DatePicker
            max={new Date()}
            value={new Date(selectedYear, selectedMonth)}
            onChange={(date) => {
              if (date) {
                setSelectedMonth(date.getMonth());
                setSelectedYear(date.getFullYear());
              }
            }}
            className="h-[30px] w-[260px] text-xs font-normal font-['Poppins']"
            placeholder="Select period"
          />
          <DefaultCheckbox
            label="Hide Ineligible"
            checked={hideIneligible}
            onChange={setHideIneligible}
          />
        </div>
        {/* Totals (TLDR) */}
        <TotalsSection data={data} />
        {/* Billing breakdown: */}
        <StandardCareSection data={data} />
        <RespiteCareSection data={data} />
        <div className="pt-24">
          <PatientList data={data?.patient_list ?? []} />
        </div>
      </div>
    </PageContainer>
  );
}
