import type { ReactNode } from "react";

export function ServiceDetailItem({
  sectionLabel,
  secondItem,
}: {
  sectionLabel: ReactNode;
  secondItem: ReactNode;
}) {
  return (
    <div className="flex flex-col gap-1 w-[160px] w-max-[160px] md:w-[260px] md:w-max-[260px] line-clamp-1 truncate">
      {sectionLabel}
      <p className="text-sm line-clamp-1">{secondItem}</p>
    </div>
  );
}
