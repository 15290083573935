import * as Sentry from "@sentry/react";
import { z } from "zod";

import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingSpinner } from "components/LoadingSpinner";
import {
  useInsert as useInsertOrganization,
  useUpdate as useUpdateOrganization,
} from "features/organizations/queries/hooks";
import { useFetchOne } from "features/organizations/queries/hooks";
import { replaceNulls } from "features/organizations/types";
import { enumFromKeys } from "features/query-utils";
import { Route } from "features/routing/constants";
import { useAppNavigate } from "lib/routing";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import {
  PhoneNumberSchema,
  ZipCodeSchema,
  optionalString,
} from "shared/forms/types";
import { useToast } from "shared/hooks/use-toast";
import { Button } from "shared/ui/button";
import { Combobox } from "shared/ui/combobox";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "shared/ui/form";
import { Input } from "shared/ui/input";
import { PhoneInput } from "shared/ui/phone-input";
import { RadioGroup, RadioGroupItem } from "shared/ui/radio-group";
import { Separator } from "shared/ui/separator";
import { Text } from "shared/ui/text";
import { Title } from "shared/ui/title";
import { useActiveOrganizationId } from "state/organization/organization";
import queryClient from "../../../shared/query-client";
import {
  BankAccountTypeMap,
  CRANIOMETRIX_GUIDE_ID,
  CRANIOMETRIX_TIN,
  CarePlanDestinationMap,
  US_STATES,
} from "../constants";
import { queryKeys } from "../queries";

const formSchema = z
  .object({
    name: z.string().min(1, { message: "Organization name is required" }),
    address: z.string().min(1, { message: "Address is required" }),
    city: z.string().min(1, { message: "City is required" }),
    state: z.enum(US_STATES),
    zipcode: ZipCodeSchema,
    phone_number_business: PhoneNumberSchema,
    phone_number_clinical: PhoneNumberSchema,
    entity_npi: z
      .string()
      .regex(/^\d{10}$/, { message: "Facility NPI must be exactly 10 digits" }),
    finance_tin: z
      .string()
      .regex(/^\d{9}$/, { message: "Finance TIN must be exactly 9 digits" }),
    entity_id: z.string().regex(/^GUIDE-\d{4}$/, {
      message: "Entity ID must be in format GUIDE-####",
    }),
    software_only: z.boolean(),
    is_guide_participant: z.boolean(),
    billing_delegated: z.boolean(),
    billing_company_name: z
      .string()
      .min(1, { message: "Billing company name is required" }),
    care_plan_destination: enumFromKeys(CarePlanDestinationMap).optional(),
    fax_number: optionalString(PhoneNumberSchema),
    api_endpoint_url: optionalString(),
    api_key: optionalString(),
    redox_connection_slug: optionalString(),
    redox_connection_id: optionalString(),
    redox_organization_name: optionalString(),
    bank_name: optionalString(),
    bank_account_type: enumFromKeys(BankAccountTypeMap).optional(),
    bank_account_number: optionalString(
      z
        .string()
        .regex(/^\d+$/, {
          message: "Bank account number must be a number",
        })
        // Additional check for typical account number length
        .min(4, { message: "Account number too short" })
        .max(17, { message: "Account number too long" }),
    ),
    bank_routing_number: optionalString(
      z
        .string()
        .regex(/^\d+$/, {
          message: "Bank routing number must be a number",
        })
        // US routing numbers are exactly 9 digits
        .length(9, { message: "Routing number must be exactly 9 digits" }),
    ),
  })
  .superRefine((data, ctx) => {
    if (data.care_plan_destination === "fax" && !data.fax_number) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "fax_number is required when care plan destination is fax",
        path: ["fax_number"],
      });
    }

    if (
      data.care_plan_destination === "api_endpoint" &&
      !data.api_endpoint_url
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message:
          "api_endpoint_url is required when care plan destination is API endpoint",
        path: ["api_endpoint_url"],
      });
    }

    if (data.care_plan_destination === "api_endpoint" && !data.api_key) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message:
          "api_key is required when care plan destination is API endpoint",
        path: ["api_key"],
      });
    }

    if (data.care_plan_destination === "redox" && !data.redox_connection_slug) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message:
          "redox_connection_slug is required when care plan destination is Redox",
        path: ["redox_connection_slug"],
      });
    }

    if (data.care_plan_destination === "redox" && !data.redox_connection_id) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message:
          "redox_connection_id is required when care plan destination is Redox",
        path: ["redox_connection_id"],
      });
    }

    if (
      data.care_plan_destination === "redox" &&
      !data.redox_organization_name
    ) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message:
          "redox_organization_name is required when care plan destination is Redox",
        path: ["redox_organization_name"],
      });
    }
  });

type FormSchema = z.infer<typeof formSchema>;

const yesOrNoOptions = [
  { value: "true", label: "Yes" },
  { value: "false", label: "No" },
];

export default function NewOrganization() {
  const activeOrganizationId = useActiveOrganizationId();
  const isEditing = typeof activeOrganizationId === "string";
  const { data, error, isLoading } = useFetchOne(
    {
      equals: {
        id: isEditing ? activeOrganizationId : undefined,
      },
    },
    { enabled: isEditing },
  );
  const insertOrg = useInsertOrganization().mutateAsync;
  const updateOrg = useUpdateOrganization({
    equals: {
      id: isEditing ? activeOrganizationId : undefined,
    },
  }).mutateAsync;
  const navigate = useAppNavigate();
  const { toast } = useToast();
  const form = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: "",
      address: "",
      city: "",
      state: undefined,
      zipcode: "",
      phone_number_business: "",
      phone_number_clinical: "",
      entity_npi: "",
      finance_tin: "",
      entity_id: CRANIOMETRIX_GUIDE_ID,
      software_only: false,
      is_guide_participant: false,
      billing_delegated: true,
      billing_company_name: "",
      care_plan_destination: undefined,
      fax_number: "",
      api_endpoint_url: "",
      api_key: "",
      redox_connection_slug: "",
      redox_connection_id: "",
      redox_organization_name: "",
      bank_name: "",
      bank_account_type: undefined,
      bank_account_number: "",
      bank_routing_number: "",
    },
  });

  async function onSubmit(data: FormSchema) {
    try {
      // If software only we assume they are a GUIDE participant
      const isGuideParticipant = data.software_only
        ? true
        : data.is_guide_participant;
      // If organization is a GUIDE participant, use their TIN for guide_tin
      // Otherwise use Craniometrix's TIN
      const guideTin = isGuideParticipant ? data.finance_tin : CRANIOMETRIX_TIN;
      // Only allow billing delegation choice for GUIDE participants
      // Non-GUIDE participants always have billing delegated to Craniometrix
      const billingDelegated = isGuideParticipant
        ? data.billing_delegated
        : true;
      // Use default entity_id if not a guide participant
      const entityId = isGuideParticipant
        ? data.entity_id
        : CRANIOMETRIX_GUIDE_ID;

      if (isEditing) {
        await updateOrg({
          ...data,
          is_guide_participant: isGuideParticipant,
          guide_tin: guideTin,
          billing_delegated: billingDelegated,
          entity_id: entityId,
        });

        queryClient.invalidateQueries({
          queryKey: queryKeys.all,
        });

        toast({
          title: "Organization updated",
        });
        navigate({
          path: Route.ADMIN,
        });
      } else {
        await insertOrg({
          ...data,
          is_guide_participant: isGuideParticipant,
          guide_tin: guideTin,
          billing_delegated: billingDelegated,
          entity_id: entityId,
        });

        queryClient.invalidateQueries({
          queryKey: queryKeys.all,
        });

        toast({
          title: "Organization created",
          description: "Access the organization to add users",
        });
        navigate({
          path: Route.ORGANIZATIONS,
        });
      }
    } catch (error) {
      Sentry.captureException(error);
      toast({
        title: "Sorry, something went wrong",
        description: "Please contact support for help.",
        variant: "destructive",
      });
    }
  }

  const isSoftwareOnly = form.watch("software_only");
  const isGuideParticipant = form.watch("is_guide_participant");
  const carePlanDestination = form.watch("care_plan_destination");
  const carePlanOptions = Object.entries(CarePlanDestinationMap).map(
    ([value, label]) => ({ value, label }),
  );

  useEffect(() => {
    if (!isEditing || !data) return;
    form.reset({
      ...replaceNulls(data),
      software_only: data.software_only ?? false,
      state: data.state as (typeof US_STATES)[number],
    });
  }, [isEditing, data, form]);

  if (isEditing && isLoading) {
    return (
      <div className="flex flex-col gap-4 p-6 max-w-2xl">
        <LoadingSpinner />
      </div>
    );
  }

  if (isEditing && (error || !data)) {
    return (
      <div className="flex flex-col gap-4 p-6 max-w-2xl">
        <Title className="mb-8">Error</Title>
        <Text className="text-red-500">
          An error occurred while fetching the organization details. Please
          refresh the page.
        </Text>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4 p-6 max-w-2xl">
      <Title className="mb-8">
        {isEditing ? "Edit organization" : "New organization"}
      </Title>

      <Form form={form} onSubmit={form.handleSubmit(onSubmit)}>
        <Title order={3} color="gray">
          Organization Information
        </Title>
        <Separator />
        <FormField
          control={form.control}
          name="name"
          label="Name"
          render={({ field }) => <Input {...field} />}
        />

        <FormField
          control={form.control}
          name="address"
          label="Address"
          render={({ field }) => <Input {...field} />}
        />

        <FormField
          control={form.control}
          name="city"
          label="City"
          render={({ field }) => <Input {...field} />}
        />

        <div className="grid grid-cols-2 gap-4 mt-4">
          <FormField
            control={form.control}
            name="state"
            label="State"
            render={({ field }) => (
              <Combobox
                searchable
                options={US_STATES.map((state) => ({
                  value: state,
                  label: state,
                }))}
                {...field}
              />
            )}
          />

          <FormField
            control={form.control}
            name="zipcode"
            label="Zip Code"
            render={({ field }) => (
              <Input
                {...field}
                maxLength={5}
                inputMode="numeric"
                pattern="[0-9]*"
              />
            )}
          />
        </div>

        <Title order={3} className="mt-8" color="gray">
          Contact Details
        </Title>
        <Separator />

        <FormField
          control={form.control}
          name="phone_number_business"
          label="Business Contact Phone Number"
          render={({ field }) => <PhoneInput {...field} />}
        />

        <FormField
          control={form.control}
          name="phone_number_clinical"
          label="Clinical Escalation Phone Number"
          render={({ field }) => <PhoneInput {...field} />}
        />

        <Title order={3} className="mt-8" color="gray">
          Identification
        </Title>
        <Separator />

        <FormField
          control={form.control}
          name="entity_npi"
          label="Facility NPI (10 digits)"
          render={({ field }) => <Input {...field} maxLength={10} />}
        />

        <FormField
          control={form.control}
          name="finance_tin"
          label="Organization Tax ID Number"
          render={({ field }) => <Input {...field} maxLength={9} />}
        />

        <Title order={3} className="mt-8" color="gray">
          Guide and Billing Options
        </Title>
        <Separator />

        <FormField
          control={form.control}
          name="software_only"
          label="Is Software Only?"
          render={({ field }) => (
            <RadioGroup
              onValueChange={(value) => field.onChange(value === "true")}
              value={field.value.toString()}
              disabled={field.disabled}
              name={field.name}
              className="flex flex-col space-y-1"
            >
              {yesOrNoOptions.map((option) => (
                <FormItem
                  key={option.value}
                  className="flex items-center space-x-3 space-y-0"
                >
                  <FormControl>
                    <RadioGroupItem value={option.value} />
                  </FormControl>
                  <FormLabel>{option.label}</FormLabel>
                </FormItem>
              ))}
            </RadioGroup>
          )}
        />

        {!isSoftwareOnly && (
          <FormField
            control={form.control}
            name="is_guide_participant"
            label="Is GUIDE Participant?"
            render={({ field }) => (
              <RadioGroup
                onValueChange={(value) => field.onChange(value === "true")}
                value={field.value.toString()}
                disabled={field.disabled}
                name={field.name}
                className="flex flex-col space-y-1"
              >
                {yesOrNoOptions.map((option) => (
                  <FormItem
                    key={option.value}
                    className="flex items-center space-x-3 space-y-0"
                  >
                    <FormControl>
                      <RadioGroupItem value={option.value} />
                    </FormControl>
                    <FormLabel>{option.label}</FormLabel>
                  </FormItem>
                ))}
              </RadioGroup>
            )}
          />
        )}

        {isGuideParticipant && (
          <FormField
            control={form.control}
            name="entity_id"
            label="Entity ID"
            render={({ field }) => <Input {...field} />}
          />
        )}

        {!isSoftwareOnly && isGuideParticipant && (
          <FormField
            control={form.control}
            name="billing_delegated"
            label="Delegate Billing to Craniometrix"
            render={({ field }) => (
              <RadioGroup
                onValueChange={(value) => field.onChange(value === "true")}
                value={field.value.toString()}
                disabled={field.disabled}
                name={field.name}
                className="flex flex-col space-y-1"
              >
                {yesOrNoOptions.map((option) => (
                  <FormItem
                    key={option.value}
                    className="flex items-center space-x-3 space-y-0"
                  >
                    <FormControl>
                      <RadioGroupItem value={option.value} />
                    </FormControl>
                    <FormLabel>{option.label}</FormLabel>
                  </FormItem>
                ))}
              </RadioGroup>
            )}
          />
        )}

        <FormField
          control={form.control}
          name="billing_company_name"
          label="Billing Company Name"
          render={({ field }) => <Input {...field} />}
        />

        <Title order={3} className="mt-8" color="gray">
          Banking Details
        </Title>
        <Separator />

        <FormField
          control={form.control}
          name="bank_name"
          label="Bank Name"
          render={({ field }) => <Input {...field} />}
        />
        <FormField
          control={form.control}
          name="bank_account_type"
          label="Bank Account Type"
          render={({ field }) => (
            <Combobox
              options={[
                { value: "checking", label: "Checking" },
                { value: "savings", label: "Savings" },
              ]}
              {...field}
            />
          )}
        />
        <FormField
          control={form.control}
          name="bank_account_number"
          label="Bank Account Number"
          render={({ field }) => (
            <Input {...field} inputMode="numeric" pattern="[0-9]*" />
          )}
        />
        <FormField
          control={form.control}
          name="bank_routing_number"
          label="Bank Routing Number"
          render={({ field }) => (
            <Input
              {...field}
              inputMode="numeric"
              pattern="[0-9]*"
              maxLength={9}
            />
          )}
        />

        <Title order={3} className="mt-8" color="gray">
          Care Plan Settings
        </Title>
        <Separator />

        <FormField
          control={form.control}
          name="care_plan_destination"
          label="Care Plan Destination"
          render={({ field }) => (
            <Combobox options={carePlanOptions} {...field} />
          )}
        />

        {carePlanDestination === "fax" && (
          <FormField
            control={form.control}
            name="fax_number"
            label="Fax Number"
            render={({ field }) => <PhoneInput {...field} />}
          />
        )}

        {carePlanDestination === "api_endpoint" && (
          <>
            <FormField
              control={form.control}
              name="api_endpoint_url"
              label="API Endpoint URL"
              render={({ field }) => <Input {...field} />}
            />
            <FormField
              control={form.control}
              name="api_key"
              label="API Key"
              render={({ field }) => (
                <Input {...field} className="font-mono" autoComplete="off" />
              )}
            />
          </>
        )}

        {carePlanDestination === "redox" && (
          <>
            <FormField
              control={form.control}
              name="redox_connection_slug"
              label="Redox Connection Slug"
              render={({ field }) => <Input {...field} />}
            />
            <FormField
              control={form.control}
              name="redox_connection_id"
              label="Redox Connection ID"
              render={({ field }) => <Input {...field} />}
            />
            <FormField
              control={form.control}
              name="redox_organization_name"
              label="Redox Organization Name"
              render={({ field }) => <Input {...field} />}
            />
          </>
        )}

        <Button
          type="submit"
          disabled={form.formState.isSubmitting || !form.formState.isValid}
          isLoading={form.formState.isSubmitting}
          className="my-8"
        >
          Save
        </Button>
      </Form>
    </div>
  );
}
