import LocalResourceDetailView from "components/ResourcesPage/components/LocalResourceDetailView";
import { useParams } from "react-router-dom";
import type { YellowPagesListing } from "../../../backend/functions";
import { useOrgs } from "../../../backend/resources/orgRole";
import { useServiceResource } from "../../../backend/resources/services/serviceResource";

export default function ServiceProviderProfileTab() {
  const { id: service_resource_id } = useParams<{ id: string }>();

  // queries
  const { data: serviceProviderData } = useServiceResource(service_resource_id);
  const { hasCareCentralAccess } = useOrgs();

  if (!serviceProviderData) return null;
  return (
    <LocalResourceDetailView
      listing={serviceProviderData as YellowPagesListing}
      shouldHideListingName
      serviceResourceEditingOptions={{
        showEditButton: hasCareCentralAccess,
        showDMButton: hasCareCentralAccess,
        serviceResourceId: service_resource_id,
      }}
    />
  );
}
