import { useMutation, useQuery } from "@tanstack/react-query";

import type { Edge } from "@xyflow/react";
import {
  deleteEdges,
  deleteNodes,
  queryKeys,
  selectEdges,
  selectNodes,
  upsertEdges,
  upsertNodes,
} from ".";
import type { ActivityNodeProps } from "../types";

export function useFetchAll() {
  return useQuery({
    queryKey: queryKeys.all,
    queryFn: async () => {
      const [nodesResult, edgesResult] = await Promise.all([
        selectNodes(),
        selectEdges(),
      ]);

      return { nodes: nodesResult ?? [], edges: edgesResult ?? [] };
    },
  });
}

type SaveQueryData = {
  nodesToBeUpserted: ActivityNodeProps[];
  nodesToBeDeleted: ActivityNodeProps[];
  edgesToBeUpserted: Edge[];
  edgesToBeDeleted: Edge[];
};

export function useSave() {
  return useMutation({
    mutationKey: queryKeys.all,
    mutationFn: async (data: SaveQueryData) => {
      // This should be wrapped in a transaction but Supabase doesn't support transactions
      await Promise.all([
        upsertNodes(data.nodesToBeUpserted),
        deleteNodes(data.nodesToBeDeleted),
        upsertEdges(data.edgesToBeUpserted),
        deleteEdges(data.edgesToBeDeleted),
      ]);
    },
  });
}
