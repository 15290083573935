/**
 * @fileoverview Shared page header component, used as the main header for all page routes.
 */

import { type ReactNode, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

interface Props {
  children: ReactNode;
}

export function PageContainer({ children }: Props) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { pathname, search } = useLocation();

  useEffect(() => {
    const id = setTimeout(() => {
      if (containerRef.current) {
        containerRef.current.scrollTo(0, 0);
      }
    }, 0);

    return () => {
      clearTimeout(id);
    };
  }, [pathname, search]);

  return (
    <div ref={containerRef} className="p-6">
      {children}
    </div>
  );
}
