import { useQuery } from "@tanstack/react-query";
import { supabase } from "clients/supabaseClient";
import { useActiveCarespaceId } from "state/carespace/carespace";
import { QUERY_KEYS } from "../../query-keys";

const TABLE = "user_assessment";

export const useLatestCompletedCaregiverAssesment = (
  selectedCaregiver: string | undefined,
) => {
  const carespaceId = useActiveCarespaceId();
  return useQuery({
    queryKey: [
      QUERY_KEYS.pastNonSampleAssessments,
      { carespaceId, selectedCaregiver },
    ],
    queryFn: async () => {
      if (!carespaceId || !selectedCaregiver) return null;
      const { data } = await supabase
        .from(TABLE)
        .select("total_score,created_at, assessment_id")
        .order("created_at", { ascending: false })
        .eq("is_sample", false)
        .eq("subject_caregiver_id", selectedCaregiver)
        .eq("carespace_id", carespaceId)
        .not("completed_at", "is", null)
        .limit(1)
        .maybeSingle();
      return data;
    },
  });
};
