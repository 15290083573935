import { Tabs } from "shared/ui/tabs";
import { Title } from "shared/ui/title";

import Services from "./components/services";

export default function ServiceHub() {
  return (
    <div className="flex flex-col gap-4 p-6">
      <Title>Service Hub</Title>

      <Tabs
        items={[
          {
            value: "services",
            label: "Services",
            content: <Services />,
          },
        ]}
        defaultValue="serviceRequests"
      />
    </div>
  );
}
