import { Fragment } from "react";

import { Separator } from "shared/ui/separator";
import { Text } from "shared/ui/text";
import { Title } from "shared/ui/title";
import type { Activity } from "../types";
import ActivityCard from "./activity";

type CompletedActivityListProps = {
  activities: Activity[];
  isLoading: boolean;
};

export default function CompletedActivityList({
  activities,
  isLoading,
}: CompletedActivityListProps) {
  if (isLoading) {
    return <div className="p-2">Loading activities...</div>;
  }

  return (
    <div className="p-2">
      <Title order={4} color="gray">
        Past Activities
      </Title>
      {activities.map((activity, index) => (
        <Fragment key={activity.id}>
          <ActivityCard {...activity} />
          {index < activities.length - 1 && (
            <Separator orientation="vertical" className="h-2 ml-4" />
          )}
        </Fragment>
      ))}
      {activities.length === 0 && (
        <Text className="text-xs text-gray-500">
          No completed activities yet.
        </Text>
      )}
    </div>
  );
}
