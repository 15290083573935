import * as Sentry from "@sentry/react";

import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingSpinner } from "components/LoadingSpinner";
import {
  useFetchOne as useFetchOneOrg,
  useInviteUserToOrg,
  useUpdateRole,
} from "features/organizations/queries/hooks";
import { Route } from "features/routing/constants";
import { ALL_ORGANIZATIONS } from "features/routing/layouts/components/organization-switcher";
import { OrganizationRole } from "features/users/constants";
import { queryKeys } from "features/users/queries";
import { useAppNavigate } from "lib/routing";
import { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { PhoneNumberSchema, optionalString } from "shared/forms/types";
import { useToast } from "shared/hooks/use-toast";
import { Button } from "shared/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "shared/ui/form";
import { Input } from "shared/ui/input";
import { PhoneInput } from "shared/ui/phone-input";
import { RadioGroup, RadioGroupItem } from "shared/ui/radio-group";
import { Text } from "shared/ui/text";
import { Title } from "shared/ui/title";
import { useActiveOrganizationId } from "state/organization/organization";
import { z } from "zod";
import { InvitationStatus } from "../../../backend/resources/invitation/invitation";
import { ORG_ROLE_OPTIONS } from "../../../backend/resources/userRole/types";
import queryClient from "../../../shared/query-client";
import { useFetchOne, useUpdate } from "../queries/hooks";
import type { OrganizationInvitation } from "../types";

const formSchema = z
  .object({
    organization_id: z.string(),
    first_name: z.string().min(1, { message: "First name is required" }),
    last_name: z.string().min(1, { message: "Last name is required" }),
    email: z.string().email({ message: "Invalid email" }),
    cell_number: PhoneNumberSchema,
    org_role: z.nativeEnum(OrganizationRole),
    is_superuser: z.boolean(),
    npi: optionalString(),
  })
  .superRefine((data, ctx) => {
    // NPI is required when org_role is "provider"
    if (data.org_role === OrganizationRole.PROVIDER && !data.npi) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "NPI is required for provider role",
        path: ["npi"],
      });
    }

    if (data.npi && !data.npi.match(/^\d{10}$/)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "NPI must be 10 digits",
        path: ["npi"],
      });
    }
  })
  .transform((data) => {
    if (data.org_role !== OrganizationRole.PROVIDER) {
      return {
        ...data,
        npi: null,
      };
    }

    return data;
  });

type FormSchema = z.infer<typeof formSchema>;

function dbInvitationToForm(invitation: OrganizationInvitation): FormSchema {
  return {
    organization_id: invitation.organization_id,
    first_name: invitation.first_name,
    last_name: invitation.last_name,
    email: invitation.invited_email,
    cell_number: invitation.cell_number,
    org_role: invitation.role_type as OrganizationRole,
    is_superuser: invitation.is_superuser,
    npi: invitation.npi,
  };
}

type NewMemberProps = {
  superorgView?: boolean;
};

export default function NewMember({ superorgView = false }: NewMemberProps) {
  const { memberId } = useParams();
  const isEditing = typeof memberId !== "undefined";
  const { toast } = useToast();
  const activeOrganizationId = useActiveOrganizationId();

  // If we're viewing super org members (via the sidebar link) or if ALL_ORGANIZATIONS is selected,
  // we want to show super org members
  const showSuperOrgMembers =
    superorgView || activeOrganizationId === ALL_ORGANIZATIONS;

  const {
    data: organization,
    error,
    isLoading,
  } = useFetchOneOrg(
    showSuperOrgMembers
      ? {
          equals: { is_super_org: true },
        }
      : {
          equals: { id: activeOrganizationId },
        },
  );
  const {
    data: invitation,
    error: invitationError,
    isLoading: invitationLoading,
  } = useFetchOne(
    {
      equals: {
        id: memberId,
      },
    },
    { enabled: isEditing },
  );

  const inviteToOrg = useInviteUserToOrg().mutateAsync;
  const updateInvitation = useUpdate({
    equals: { id: invitation?.id },
  }).mutateAsync;
  const updateRole = useUpdateRole({
    equals: { id: invitation?.organization_role_id ?? undefined },
  }).mutateAsync;
  const navigate = useAppNavigate();
  const hasInitializedUserForm = useRef(false);
  const form = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      organization_id: "",
      first_name: "",
      last_name: "",
      email: "",
      cell_number: "",
      org_role: OrganizationRole.CARE_NAVIGATOR,
      is_superuser: false,
      npi: "",
    },
  });
  const orgRole = form.watch("org_role");

  useEffect(() => {
    if (!organization) return;
    form.setValue("organization_id", organization.id);
  }, [form, organization]);

  // When editing an existing member, prefill the form with the member's data
  useEffect(() => {
    if (!isEditing || !invitation || hasInitializedUserForm.current) return;
    form.reset(dbInvitationToForm(invitation));
    hasInitializedUserForm.current = true;
  }, [form, isEditing, invitation]);

  if (isLoading || invitationLoading) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <LoadingSpinner className="w-6 h-6" />
      </div>
    );
  }

  if (error || invitationError || !organization || (isEditing && !invitation)) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <Text>Error loading organization. Please reload the page.</Text>
      </div>
    );
  }

  async function onSubmit(data: FormSchema) {
    try {
      if (isEditing) {
        await updateInvitation({
          first_name: data.first_name,
          last_name: data.last_name,
          invited_email: data.email,
          cell_number: data.cell_number,
          role_type: data.org_role,
          organization_id: data.organization_id,
          is_superuser: data.org_role === OrganizationRole.ADMIN,
          npi: data.npi,
        });

        if (invitation?.organization_role_id) {
          await updateRole({
            id: invitation.organization_role_id,
            role: data.org_role,
          });
        }

        await queryClient.invalidateQueries({
          queryKey: queryKeys.all,
        });

        toast({
          title: "Success",
          description: "Member updated successfully",
        });
      } else {
        await inviteToOrg({
          first_name: data.first_name,
          last_name: data.last_name,
          invited_email: data.email,
          cell_number: data.cell_number,
          role_type: data.org_role,
          organization_id: data.organization_id,
          is_superuser: data.org_role === OrganizationRole.ADMIN,
          npi: data.npi,
        });

        queryClient.invalidateQueries({
          queryKey: queryKeys.all,
        });

        toast({
          title: "Success",
          description: "An invitation has been sent to the user",
        });
      }

      navigate({
        path: showSuperOrgMembers ? Route.SUPERORG_MEMBERS : Route.MEMBERS,
      });
    } catch (error) {
      Sentry.captureException(error);
      toast({
        title: "Sorry, something went wrong",
        description: "Please contact support for help.",
        variant: "destructive",
      });
    }
  }

  return (
    <div className="flex flex-col gap-4 h-full p-6 max-w-2xl">
      <Title>
        {isEditing
          ? showSuperOrgMembers
            ? "Edit Super Org Member"
            : "Edit Organization Member"
          : showSuperOrgMembers
            ? "New Super Org Member"
            : "New Organization Member"}
      </Title>
      {showSuperOrgMembers && (
        <p>This user will have access to all organizations</p>
      )}
      <Form form={form} onSubmit={form.handleSubmit(onSubmit)} className="mt-8">
        <FormField
          control={form.control}
          name="first_name"
          label="First Name"
          disabled={
            isEditing && invitation?.status !== InvitationStatus.PENDING
          }
          render={({ field }) => <Input {...field} />}
        />
        <FormField
          control={form.control}
          name="last_name"
          label="Last Name"
          disabled={
            isEditing && invitation?.status !== InvitationStatus.PENDING
          }
          render={({ field }) => <Input {...field} />}
        />

        <FormField
          control={form.control}
          name="email"
          label="Email"
          disabled={isEditing}
          render={({ field }) => <Input {...field} type="email" />}
        />

        <FormField
          control={form.control}
          name="cell_number"
          label="Phone Number"
          disabled={
            isEditing && invitation?.status !== InvitationStatus.PENDING
          }
          render={({ field }) => <PhoneInput {...field} />}
        />

        <FormField
          control={form.control}
          name="org_role"
          label="Role"
          render={({ field }) => (
            <RadioGroup
              onValueChange={(value) => field.onChange(value)}
              value={field.value}
              disabled={field.disabled}
              name={field.name}
              className="flex flex-col space-y-1"
            >
              {ORG_ROLE_OPTIONS.map((option) => (
                <FormItem
                  key={option.value}
                  className="flex items-center space-x-3 space-y-0"
                >
                  <FormControl>
                    <RadioGroupItem value={option.value} />
                  </FormControl>
                  <FormLabel>{option.label}</FormLabel>
                </FormItem>
              ))}
            </RadioGroup>
          )}
        />

        {orgRole === OrganizationRole.PROVIDER && (
          <FormField
            control={form.control}
            name="npi"
            label="National Provider Identifier (NPI)"
            disabled={
              isEditing && invitation?.status !== InvitationStatus.PENDING
            }
            render={({ field }) => (
              <Input {...field} maxLength={10} inputMode="numeric" />
            )}
          />
        )}

        <Button
          type="submit"
          isLoading={form.formState.isSubmitting}
          className="my-8"
        >
          Save
        </Button>
      </Form>
    </div>
  );
}
