import clamp from "lodash/clamp";
import { useState } from "react";
import { Checkbox } from "shared/ui/checkbox";
import { Combobox } from "shared/ui/combobox";
import { Input } from "shared/ui/input";
import { Label } from "shared/ui/label";
import { cn } from "shared/utils/cn";
import { z } from "zod";

const intervalSchema = z.string().transform((str, ctx) => {
  const allowedUnits = ["days", "weeks", "months", "years"];
  const intervalRegex = /^\s*(\d+(?:\.\d+)?)\s+([A-Za-z]+)\s*$/;

  const match = str.match(intervalRegex);
  if (!match) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message:
        "Invalid interval format. Expected format: '<number> <unit>', e.g., '2 days'.",
    });
    return z.NEVER;
  }

  const amount = Number.parseInt(match[1], 10);
  if (Number.isNaN(amount)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Invalid number in interval.",
    });
    return z.NEVER;
  }

  // Convert the unit to lower case for standardization.
  const unit = match[2].toLowerCase();

  if (!allowedUnits.includes(unit)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: `Invalid unit. Allowed units are: ${allowedUnits.join(", ")}.`,
    });
    return z.NEVER;
  }

  return {
    amount,
    unit,
  };
});

const unitOptions = [
  { value: "days", label: "Days" },
  { value: "weeks", label: "Weeks" },
  { value: "months", label: "Months" },
  { value: "years", label: "Years" },
];

type IntervalInputProps = {
  title: string;
  value?: string;
  onChange: (value?: string) => void;
  className?: string;
};

const defaultAmount = 1;
const defaultUnit = "days";
const defaultValue = `${defaultAmount} ${defaultUnit}`;

export function IntervalInput(props: IntervalInputProps) {
  const enabled = props.value !== undefined;
  const { amount, unit } = intervalSchema.parse(props.value ?? defaultValue);

  // We use a separate state for the raw amount to allow the user to
  // delete the default value and input new values freely.
  const [rawAmount, setRawAmount] = useState<string>(amount.toString());

  function onChange(amount?: number, unit?: string) {
    if (!amount || !unit) {
      setRawAmount(defaultAmount.toString());
      props.onChange(undefined);
    } else {
      setRawAmount(amount.toString());
      props.onChange(`${amount} ${unit}`);
    }
  }

  return (
    <div className={cn("flex flex-col", props.className)}>
      <div className="flex flex-row gap-2">
        <Checkbox
          checked={enabled}
          onChange={(value) => {
            if (value) {
              onChange(defaultAmount, defaultUnit);
            } else {
              onChange();
            }
          }}
        />
        <Label className="block mb-1 font-medium text-gray-700">
          {props.title}
        </Label>
      </div>
      {enabled ? (
        <div className="flex flex-row gap-2">
          <Input
            type="number"
            value={rawAmount}
            min={1}
            max={100}
            onChange={(e) => {
              setRawAmount(e.target.value);
            }}
            onBlur={(e) => {
              const newAmount = clamp(Number(e.target.value), 1, 100);
              onChange(newAmount, unit);
            }}
          />
          <Combobox
            id="unit"
            value={unit}
            options={unitOptions}
            onChange={(value = "days") => {
              onChange(amount, value);
            }}
          />
        </div>
      ) : null}
    </div>
  );
}
