import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { DefaultCheckbox } from "components/Checkbox";
import { useAuthUser } from "features/users/auth";
import { useFetchOne } from "features/users/queries/hooks";
import { useState } from "react";
import { ResponsiveModal } from "shared/ui/responsive-modal";
import {
  NotificationChannel,
  useUpdateNotificationPreferenceForSectionMutation,
} from "../../backend/resources/notificationPreferences/notificationPreferences";

export default function SMSNotificationsApprovalModal({
  onClose,
}: {
  onClose: () => void;
}) {
  const [
    wouldLikeToReceiveSMSNotifications,
    setWouldLikeToReceiveSMSNotifications,
  ] = useState<boolean | undefined>();
  const updateNotificationPreferences =
    useUpdateNotificationPreferenceForSectionMutation().mutateAsync;

  const { authUser } = useAuthUser();
  const { data: user } = useFetchOne(
    {
      equals: {
        id: authUser?.id,
      },
    },
    {
      enabled: !!authUser,
    },
  );

  async function submit() {
    if (wouldLikeToReceiveSMSNotifications && user) {
      await Promise.all([
        updateNotificationPreferences({
          user_id: user.id,
          channel: NotificationChannel.SMS,
          enabled: true,
        }),
      ]);
    }
    onClose();
  }

  return (
    <ResponsiveModal
      isOpen={true}
      title="SMS Notifications"
      onClose={onClose}
      closeText="Back"
      footerButtons={
        <ButtonWithIcon
          onClick={submit}
          text="Done"
          icon={IconOption.CHECKMARK}
          disabled={wouldLikeToReceiveSMSNotifications === undefined}
        />
      }
    >
      <div className="flex flex-col gap-4">
        <p>
          If you want to receive SMS notifications for alerts and reminders for
          things like your tasks, please select the yes option. If you aren’t
          sure, select Maybe later and then go into Profile {"->"} Settings to
          enable them at any time.
        </p>

        <DefaultCheckbox
          label="I agree to receive SMS based on my data"
          checked={wouldLikeToReceiveSMSNotifications === true}
          onChange={() => setWouldLikeToReceiveSMSNotifications(true)}
        />
        <p className="text-xs pl-8">
          By subscribing you agree to receive marketing text messages at the
          phone number provided. Reply STOP to cancel. Msg rates may apply.
        </p>
        <DefaultCheckbox
          label="Maybe later"
          checked={wouldLikeToReceiveSMSNotifications === false}
          onChange={() => setWouldLikeToReceiveSMSNotifications(false)}
        />
      </div>
    </ResponsiveModal>
  );
}
