import * as Sentry from "@sentry/react";
import { supabase } from "clients/supabaseClient";
import { US_STATES } from "features/organizations/constants";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { useToast } from "shared/hooks/use-toast";
import queryClient from "shared/query-client";
import { useActiveOrganizationId } from "state/organization/organization";
import CarespacesForm from "../components/carespace-form";
import { Sex, icd10Codes } from "../constants";
import type { AlignmentFormSchema } from "../schema";
import type { Carespace } from "../types";
import { createCaregiverUserIfNeeded } from "../utils";

function parseICD10Code(
  icd10Code: string | null | undefined,
): (typeof icd10Codes)[number] | undefined {
  if (!icd10Code) return undefined;

  const matchFound = icd10Codes.find((code) =>
    code.toLowerCase().startsWith(icd10Code.toLowerCase()),
  );

  if (matchFound) {
    return matchFound;
  }

  return undefined;
}

function parseState(state: unknown): (typeof US_STATES)[number] | undefined {
  if (!state) return undefined;
  if (typeof state !== "string") return undefined;
  const matchFound = US_STATES.includes(state as (typeof US_STATES)[number]);
  return matchFound ? (state as (typeof US_STATES)[number]) : undefined;
}

function parseSex(sex: unknown): Sex | undefined {
  if (!sex) return undefined;
  if (typeof sex !== "string") return undefined;
  const matchFound = Object.values(Sex).find((value) => value === sex);
  return matchFound;
}

type CarespaceAlignmentFormProps = {
  carespace: Carespace;
  title: string;
  submitLabel: string;
  rationale?: string;
  onSubmit: () => void;
};

export default function CarespaceAlignmentForm({
  carespace,
  title,
  submitLabel,
  rationale,
  ...props
}: CarespaceAlignmentFormProps) {
  const { carespaceId } = useParams();
  const activeOrganizationId = useActiveOrganizationId();
  const { toast } = useToast();

  const onSubmit = useCallback(async (data: AlignmentFormSchema) => {
    try {
      if (!activeOrganizationId || !carespaceId) {
        throw new Error(
          "No active organization selected or carespace ID missing",
        );
      }

      const { error } = await supabase.rpc("update_carespace_alignment", {
        input_carespace_id: carespaceId,
        patient_first_name: data.patient_first_name,
        patient_last_name: data.patient_last_name,
        patient_birthday: data.patient_birthday.toISOString(),
        patient_legal_sex: data.patient_legal_sex,
        patient_race: data.patient_race,
        patient_ethnicity: data.patient_ethnicity,
        patient_preferred_name: data.patient_preferred_name,
        patient_preferred_language: data.patient_preferred_language,
        patient_mbi: data.patient_mbi,
        patient_medicaid_id: data.patient_medicaid_id,
        patient_medical_record_number: data.patient_medical_record_number,
        patient_dementia_staging_tool: data.patient_dementia_staging_tool,
        patient_dementia_staging_score: data.patient_dementia_staging_score,
        patient_icd10_code: data.patient_icd10_code,
        patient_has_caregiver: data.patient_has_caregiver,
        patient_address: data.patient_address,
        patient_city: data.patient_city,
        patient_state: data.patient_state,
        patient_zipcode: data.patient_zipcode,
        patient_email: data.patient_email,
        patient_phone_number: data.patient_phone_number,
        patient_phone_type: data.patient_phone_type,
        patient_timezone: data.patient_timezone,
        patient_residence_type: data.patient_residence_type,
        patient_is_nursing_home_resident: data.patient_is_nursing_home_resident,
        patient_is_new_patient: data.patient_is_new_patient,
        patient_referral_source: data.patient_referral_source,
        input_pcp_first_name: data.pcp_first_name,
        input_pcp_last_name: data.pcp_last_name,
        input_pcp_phone_number: data.pcp_phone_number,
        input_provider_first_name: data.provider_first_name,
        input_provider_last_name: data.provider_last_name,
        input_provider_npi: data.provider_npi,
        input_clinician_first_name: data.clinician_first_name,
        input_clinician_last_name: data.clinician_last_name,
        input_clinician_npi: data.clinician_npi,
        input_clinician_attestation: data.clinician_attestation,
        input_assessment_date: data.assessment_date.toISOString(),
        caregiver_first_name: data.caregiver_first_name,
        caregiver_last_name: data.caregiver_last_name,
        caregiver_email: data.caregiver_email,
        caregiver_phone_number: data.caregiver_phone_number,
        caregiver_phone_type: data.caregiver_phone_type,
        caregiver_address: data.caregiver_address,
        caregiver_city: data.caregiver_city,
        caregiver_state: data.caregiver_state,
        caregiver_zipcode: data.caregiver_zipcode,
        caregiver_birthday: data.caregiver_birthday?.toISOString(),
        caregiver_legal_sex: data.caregiver_legal_sex,
        caregiver_race: data.caregiver_race,
        caregiver_ethnicity: data.caregiver_ethnicity,
        caregiver_relationship_type: data.caregiver_relationship_type,
        caregiver_lives_with_patient: data.caregiver_lives_with_patient,
        caregiver_is_medicare_beneficiary:
          data.caregiver_is_medicare_beneficiary,
        caregiver_mbi: data.caregiver_mbi,
        caregiver_since_years: data.caregiver_since_years,
        caregiver_since_months: data.caregiver_since_months,
      });

      if (error) {
        throw error;
      }

      // Create a caregiver user if needed
      await createCaregiverUserIfNeeded({
        carespaceId: carespace?.id,
        caregiverId: carespace?.caregiver?.user_id,
        first_name: data.caregiver_first_name,
        last_name: data.caregiver_last_name,
        email: data.caregiver_email,
        patient_has_caregiver: data.patient_has_caregiver,
      });

      toast({
        variant: "success",
        title: "Success",
        description: "Patient alignment completed successfully",
      });

      queryClient.invalidateQueries({
        queryKey: ["carespaces"],
      });
      queryClient.invalidateQueries({
        queryKey: ["tasks", carespaceId],
      });
      queryClient.invalidateQueries({
        queryKey: ["activities", carespaceId],
      });

      props.onSubmit();
    } catch (error) {
      Sentry.captureException(error);
      toast({
        variant: "destructive",
        title: "Error",
        description:
          error instanceof Error ? error.message : "Please try again",
      });
    }
  }, []);

  if (!carespace) {
    return <div>Carespace not found</div>;
  }

  if (!carespace.patient) {
    return <div>Patient not found</div>;
  }

  const patient = carespace.patient;
  const pafSubmission = carespace.current_paf_submission;
  const birthday = patient.birthday ? new Date(patient.birthday) : undefined;

  return (
    <CarespacesForm
      variant="alignment"
      title={title}
      submitLabel={submitLabel}
      rationale={rationale}
      onSubmit={onSubmit}
      defaultValues={{
        patient_first_name: patient.first_name ?? "",
        patient_last_name: patient.last_name ?? "",
        patient_birthday: birthday,
        patient_legal_sex: patient.sex,
        patient_race: patient.race,
        patient_ethnicity: patient.ethnicity,
        patient_preferred_name: patient.preferred_name ?? "",
        patient_preferred_language: patient.preferred_language ?? "",
        patient_mbi: patient.mbi ?? "",
        patient_medicaid_id: patient.medicaid_id ?? "",
        patient_medical_record_number: patient.medical_record_number ?? "",
        patient_dementia_staging_tool: pafSubmission?.dementia_staging_tool,
        patient_dementia_staging_score:
          pafSubmission?.dementia_staging_score ?? 0,
        patient_icd10_code: parseICD10Code(pafSubmission?.icd10_code),
        patient_has_caregiver: patient.has_caregiver,
        patient_address: patient.address ?? "",
        patient_city: patient.city ?? "",
        patient_state: patient.state ?? "",
        patient_zipcode: patient.zipcode ?? "",
        patient_email: patient.email ?? "",
        patient_phone_number: patient.phone_number ?? "",
        patient_is_new_patient: patient.is_new_patient ?? false,
        patient_referral_source: patient.referral_source ?? "self_referral",
        pcp_first_name: carespace.pcp_first_name ?? "",
        pcp_last_name: carespace.pcp_last_name ?? "",
        pcp_phone_number: carespace.pcp_phone_number ?? "",
        provider_first_name: carespace.provider_first_name ?? "",
        provider_last_name: carespace.provider_last_name ?? "",
        provider_npi: carespace.provider_npi ?? "",
        clinician_first_name: pafSubmission?.clinician_first_name ?? "",
        clinician_last_name: pafSubmission?.clinician_last_name ?? "",
        clinician_npi: pafSubmission?.clinician_npi ?? "",
        clinician_attestation: pafSubmission?.clinician_attestation,
        caregiver_first_name: carespace.caregiver?.first_name ?? "",
        caregiver_last_name: carespace.caregiver?.last_name ?? "",
        caregiver_email: carespace.caregiver?.email ?? "",
        caregiver_phone_number: carespace.caregiver?.phone_number ?? "",
        // Alignment form fields
        patient_timezone: patient.timezone ?? "",
        patient_phone_type: patient.phone_type ?? undefined,
        assessment_date: pafSubmission?.assessment_date
          ? new Date(pafSubmission.assessment_date)
          : undefined,
        patient_residence_type: patient.residence_type ?? undefined,
        patient_is_nursing_home_resident:
          patient.is_nursing_home_resident ?? false,
        caregiver_address: carespace.caregiver?.address ?? "",
        caregiver_city: carespace.caregiver?.city ?? "",
        caregiver_state: parseState(carespace.caregiver?.state),
        caregiver_zipcode: carespace.caregiver?.zipcode ?? "",
        caregiver_phone_type: carespace.caregiver?.phone_type ?? undefined,
        caregiver_birthday: carespace.caregiver?.birthday
          ? new Date(carespace.caregiver.birthday)
          : undefined,
        caregiver_legal_sex: parseSex(carespace.caregiver?.legal_sex),
        caregiver_race: carespace.caregiver?.race ?? undefined,
        caregiver_ethnicity: carespace.caregiver?.ethnicity ?? undefined,
        caregiver_relationship_type:
          carespace.caregiver?.relationship_type ?? undefined,
        caregiver_lives_with_patient:
          carespace.caregiver?.lives_with_patient ?? undefined,
        caregiver_is_medicare_beneficiary:
          carespace.caregiver?.is_medicare_beneficiary ?? undefined,
        caregiver_mbi: carespace.caregiver?.mbi ?? "",
        caregiver_since_years: carespace.caregiver?.since_years ?? undefined,
        caregiver_since_months: carespace.caregiver?.since_months ?? undefined,
      }}
    />
  );
}
