import * as Sentry from "@sentry/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { supabase } from "clients/supabaseClient";
import queryClient from "../../../shared/query-client";
import { QUERY_KEYS } from "../../query-keys";

const TABLE = "user_update_to_gallery_media";

export const useGalleryMediaQueryInUserUpdateQuery = (
  userUpdateId?: string,
) => {
  const {
    isLoading: isLoadingGalleryMediaInUserUpdate,
    data: galleryUpdateInUserUpdate,
    error: galleryUpdateInUserUpdateError,
    refetch: refetchGalleryUpdateInUserUpdateError,
  } = useQuery({
    queryKey: [QUERY_KEYS.userUpdateGalleryMedia, { userUpdateId }],
    queryFn: () => fetchAllGalleryMediaInUserUpdate(userUpdateId),
  });

  return {
    isLoadingGalleryMediaInUserUpdate,
    galleryUpdateInUserUpdate: galleryUpdateInUserUpdate || [],
    galleryUpdateInUserUpdateError,
    refetchGalleryUpdateInUserUpdateError,
  };
};

export function useSaveGalleryMediaInUserUpdateMutation(userUpdateId?: string) {
  const { galleryUpdateInUserUpdate: existingFileNames } =
    useGalleryMediaQueryInUserUpdateQuery(userUpdateId);

  return useMutation({
    mutationFn: async (newFileNames: string[]) => {
      const existingFileNamesAsSet = new Set(existingFileNames);
      const newFileNamesAsSet = new Set(newFileNames);

      const toDelete = existingFileNames.filter(
        (fileName) => !newFileNamesAsSet.has(fileName),
      );
      const toInsert = newFileNames.filter(
        (fileName) => !existingFileNamesAsSet.has(fileName),
      );

      await deleteGalleryMedia(userUpdateId, toDelete);
      await insertGalleryMedia(userUpdateId, toInsert);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.userUpdateGalleryMedia, { userUpdateId }],
      });
    },
  });
}

async function fetchAllGalleryMediaInUserUpdate(userUpdateId?: string) {
  if (!userUpdateId) return [];

  const { data, error } = await supabase
    .from(TABLE)
    .select("file_name")
    .eq("user_update_id", userUpdateId)
    .order("created_at");
  if (error) {
    Sentry.captureException(error);
  }
  return data?.map((item) => item.file_name) ?? [];
}

async function deleteGalleryMedia(
  userUpdateId: string | undefined,
  fileNames: string[],
) {
  if (fileNames.length === 0 || !userUpdateId) return;

  const { error } = await supabase
    .from(TABLE)
    .delete()
    .in("file_name", fileNames)
    .eq("user_update_id", userUpdateId);

  if (error) {
    Sentry.captureException(error);
  }
}

async function insertGalleryMedia(
  userUpdateId: string | undefined,
  fileNames: string[],
) {
  if (fileNames.length === 0 || !userUpdateId) return;

  const itemsToInsert = fileNames.map((fileName) => ({
    user_update_id: userUpdateId,
    file_name: fileName,
  }));

  const { error } = await supabase.from(TABLE).insert(itemsToInsert);

  if (error) {
    Sentry.captureException(error);
  }
}
