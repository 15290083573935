export function calculateAge(birthday: Date): number {
  if (Number.isNaN(birthday.getTime())) {
    throw new TypeError("Invalid date format");
  }

  const today = new Date();

  if (birthday > today) {
    throw new Error("Birthday cannot be in the future");
  }

  const age = today.getFullYear() - birthday.getFullYear();

  // Adjust age if birthday hasn't occurred yet this year
  const monthDiff = today.getMonth() - birthday.getMonth();
  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < birthday.getDate())
  ) {
    return age - 1;
  }

  return age;
}
