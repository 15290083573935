import * as Sentry from "@sentry/react";
import { supabase } from "clients/supabaseClient";
import parsePostgresInterval from "postgres-interval";

import type { Edge } from "@xyflow/react";
import type { Database } from "types/supabase";
import { EDGE_TABLE_NAME, NODE_TABLE_NAME } from "../constants";
import type {
  ActivityNodeProps,
  ActivityTypeWithoutUserCreated,
} from "../types";

const all = ["careflow"];

export const queryKeys = {
  all,
};

type DatabaseNode = Database["public"]["Tables"]["careflow_node"]["Row"];

function parsePoint(point: string) {
  const pointRegex = /\((-?\d+(?:\.\d+)?),(-?\d+(?:\.\d+)?)\)/;
  const match = pointRegex.exec(point);

  if (!match) {
    throw new Error(`Invalid point format: ${point}`);
  }

  return {
    x: Number.parseFloat(match[1]),
    y: Number.parseFloat(match[2]),
  };
}

function parseInterval(interval: unknown): string | undefined {
  if (typeof interval !== "string") return undefined;

  const intervalObject = parsePostgresInterval(interval);
  const match = Object.entries(intervalObject).find((x) => x[1] > 0);

  if (!match) return undefined;

  if (match[0] === "days" && match[1] % 7 === 0) {
    return `${match[1] / 7} weeks`;
  }

  return `${match[1]} ${match[0]}`;
}

function parseNodes(nodes: DatabaseNode[]): ActivityNodeProps[] {
  // We shouldn't have any user_created nodes in the database
  // as the editor doesn't allow the user to create them
  // but we filter them out just in case and to satisfy the type system
  return nodes
    .filter(
      (
        node: DatabaseNode,
      ): node is DatabaseNode & {
        type: ActivityTypeWithoutUserCreated;
      } => node.type !== "user_created",
    )
    .map((node) => {
      const { x, y } =
        typeof node.editor_position === "string"
          ? parsePoint(node.editor_position)
          : { x: 0, y: 0 };

      return {
        ...node,
        deadline: parseInterval(node.deadline),
        recurrence: parseInterval(node.recurrence),
        editor_position: {
          x,
          y,
        },
      };
    });
}

function formatNodes(nodes: ActivityNodeProps[]) {
  return nodes.map((node) => ({
    ...node,
    editor_position: `(${node.editor_position.x},${node.editor_position.y})`,
  }));
}

function formatEdges(edges: Edge[]) {
  return edges.map((edge) => ({
    id: edge.id,
    source: edge.source,
    target: edge.target,
  }));
}

export const selectEdges = async () => {
  const { data, error } = await supabase.from(EDGE_TABLE_NAME).select("*");

  if (error) {
    Sentry.captureException(error);
    throw error;
  }

  return data;
};

export const selectNodes = async () => {
  const { data, error } = await supabase
    .from(NODE_TABLE_NAME)
    .select("*")
    .eq("active", true);

  if (error) {
    Sentry.captureException(error);
    throw error;
  }

  return parseNodes(data);
};

export const upsertNodes = (nodes: ActivityNodeProps[]) =>
  supabase.from(NODE_TABLE_NAME).upsert(formatNodes(nodes));

export const upsertEdges = (edges: Edge[]) =>
  supabase.from(EDGE_TABLE_NAME).upsert(formatEdges(edges));

export const deleteNodes = (nodes: ActivityNodeProps[]) =>
  supabase
    .from(NODE_TABLE_NAME)
    .upsert(formatNodes(nodes).map((node) => ({ ...node, active: false })));

export const deleteEdges = (edges: Edge[]) =>
  supabase
    .from(EDGE_TABLE_NAME)
    .delete()
    .in(
      "id",
      edges.map((edge) => edge.id),
    );
