import { supabase } from "clients/supabaseClient";

import type { Filter } from "features/query-utils";

import { ORGANIZATION_ROLE_TABLE_NAME } from "features/organizations/constants";
import {
  ACTIVITY_TABLE_NAME,
  ASSESSMENT_TABLE_NAME,
  GUIDE_STATUS_TABLE_NAME,
  TABLE_NAME,
  USER_ASSESSMENT_ANSWER_TABLE_NAME,
  USER_ASSESSMENT_TABLE_NAME,
} from "../constants";
import type {
  GuideStatusInsert,
  NewActivity,
  TableName,
  UserAssessmentAnswerInsert,
  UserAssessmentInsert,
} from "../types";

const all = ["carespaces"];
const lists = [...all, "list"];
const detail = [...all, "detail"];

export const queryKeys = {
  all,
  lists,
  list: (filter: Filter<TableName>) => [...lists, filter],
  detail: (filter: Filter<TableName>) => [...detail, filter],
};

export const select = () =>
  // TODO: load carespace invitations as well
  supabase
    .from(TABLE_NAME)
    .select(
      `
      *,
      paf_submission(
        status,
        clinician_first_name,
        clinician_last_name,
        clinician_npi,
        clinician_attestation,
        dementia_staging_tool,
        dementia_staging_score,
        icd10_code,
        assessment_date
      ),
      organization(
        id,
        name,
        software_only
      ),
      patient(
        *
      ),
      user_role(
        user(
          *
        ),
        role
      ),
      guide_status(
        status,
        created_at,
        rationale
      ),
      caregiver(
        *
      )
    `,
    )
    .order("created_at", { referencedTable: "guide_status", ascending: false });

export const selectAllActivities = (carespace_id: string) => {
  return supabase
    .from(ACTIVITY_TABLE_NAME)
    .select(
      "id, title, description, type, owner, deadline, recurrence, created_at, completed_at, skipped_at, node_id, carespace_id, effective_date, created_by_id, created_by_type, scheduled_at, custom_fields",
    )
    .eq("carespace_id", carespace_id)
    .order("created_at", { ascending: false });
};

export const updateActivityStatus = (
  activity_id: string,
  status: "completed" | "skipped",
) =>
  supabase
    .from(ACTIVITY_TABLE_NAME)
    .update({ [`${status}_at`]: new Date().toISOString() })
    .eq("id", activity_id);

export const addActivity = (activity: NewActivity) =>
  supabase.from(ACTIVITY_TABLE_NAME).insert(activity);

export const selectAssessments = () =>
  supabase
    .from(ASSESSMENT_TABLE_NAME)
    .select("*, assessment_question(*)")
    .order("question_number", {
      referencedTable: "assessment_question",
      ascending: true,
    });

export const insertAssessment = (assessment: UserAssessmentInsert) =>
  supabase
    .from(USER_ASSESSMENT_TABLE_NAME)
    .insert(assessment)
    .select()
    .single();

export const insertAssessmentAnswers = (
  answers: UserAssessmentAnswerInsert[],
) => supabase.from(USER_ASSESSMENT_ANSWER_TABLE_NAME).insert(answers);

export const selectUserAssessments = () =>
  supabase.from(USER_ASSESSMENT_TABLE_NAME).select("*, assessment(*)");

export const insertGuideStatus = (guideStatus: GuideStatusInsert) =>
  supabase.from(GUIDE_STATUS_TABLE_NAME).insert(guideStatus);

export const selectUserRole = (user_id: string, organization_id: string) =>
  supabase
    .from(ORGANIZATION_ROLE_TABLE_NAME)
    .select("*, organization(*)")
    .eq("user_id", user_id)
    .eq("is_deactivated", false)
    .or(`id.eq."${organization_id}",is_super_org.eq.true`, {
      referencedTable: "organization",
    })
    .single();
