import * as Sentry from "@sentry/react";
import {
  type ColumnFiltersState,
  createColumnHelper,
} from "@tanstack/react-table";
import { format } from "date-fns";
import capitalize from "lodash/capitalize";
import { useCallback, useMemo, useRef, useState } from "react";

import { backend } from "clients/backend";
import { supabase } from "clients/supabaseClient";
import { buildPAFFileName } from "components/CarespacePage/Tabs/CarespaceMainTab/carespaceMainTabUtil";
import { useAppNavigate } from "lib/routing";
import { useOrgs } from "../../../backend/resources/orgRole/orgRole";

import { LoadingSpinner } from "components/LoadingSpinner";

import { Download, Plus, Upload } from "lucide-react";
import { useToast } from "shared/hooks/use-toast";
import { Button } from "shared/ui/button";
import { SplitButton } from "shared/ui/split-button";
import { DataTable } from "shared/ui/table";
import { Text } from "shared/ui/text";
import { Title } from "shared/ui/title";

import { FunctionsHttpError } from "@supabase/supabase-js";
import type { RowSelectionState } from "@tanstack/react-table";
import { Route } from "features/routing/constants";
import { ALL_ORGANIZATIONS } from "features/routing/layouts/components/organization-switcher";
import { useActiveOrganizationId } from "state/organization/organization";
import { useDownloadPaf, useFetchMany } from "../queries/hooks";
import type { Carespace } from "../types";

const columnHelper = createColumnHelper<Carespace>();

export default function CarespaceList() {
  const organizationId = useActiveOrganizationId();
  const listOptions = useMemo(() => {
    return organizationId === ALL_ORGANIZATIONS
      ? {}
      : { equals: { organization_id: organizationId } };
  }, [organizationId]);
  const {
    data: carespaces = [],
    error,
    isLoading,
    isError,
    refetch,
  } = useFetchMany(listOptions, { enabled: !!organizationId });
  const navigate = useAppNavigate();
  const { toast } = useToast();
  const carespaceInputRef = useRef<HTMLInputElement>(null);
  const pafInputRef = useRef<HTMLInputElement>(null);
  const [isUploadingCarespaces, setIsUploadingCarespaces] = useState(false);
  const [isUploadingPaf, setIsUploadingPaf] = useState(false);
  const downloadPaf = useDownloadPaf();

  const [selectedRows, setSelectedRows] = useState<RowSelectionState>({});
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const { isSuperSuperUser, allOrgIdentities } = useOrgs();
  const orgRole = allOrgIdentities?.find(
    (role) => role.organization_id === organizationId,
  );

  const handleUploadCsv = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files;
      if (!files || files.length === 0) return;

      if (organizationId === ALL_ORGANIZATIONS || !organizationId) {
        toast({
          variant: "destructive",
          title: "Error",
          description: "No active organization selected",
        });
        return;
      }

      try {
        setIsUploadingCarespaces(true);
        const file = files[0];

        const formData = new FormData();
        formData.append("file", file);
        formData.append("organization_id", organizationId);

        // Use supabase.functions.invoke with FormData to avoid CORS issues
        const { data: result, error } = await supabase.functions.invoke(
          "upload_carespaces",
          {
            body: formData,
          },
        );

        if (error && error instanceof FunctionsHttpError) {
          const data = await error.context.json();
          const errorMessage = data.error;
          throw new Error(errorMessage ?? "Failed to upload file");
        }

        if (error) {
          throw new Error("Failed to upload file");
        }

        if (!result) {
          throw new Error("No result returned");
        }

        toast({
          variant: "success",
          title: "Success",
          description: `${result.totalProcessed} carespaces processed successfully`,
        });

        await refetch();
      } catch (error) {
        Sentry.captureException(error);
        toast({
          variant: "destructive",
          title: "Error",
          description:
            error instanceof Error
              ? error.message
              : "Failed to upload carespaces",
        });
      } finally {
        setIsUploadingCarespaces(false);
        if (carespaceInputRef.current) {
          carespaceInputRef.current.value = "";
        }
      }
    },
    [toast, refetch, setIsUploadingCarespaces, organizationId],
  );

  const handleUploadPaf = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files;
      if (!files || files.length === 0) return;

      try {
        setIsUploadingPaf(true);
        const file = files[0];

        const formData = new FormData();
        formData.append("file", file);

        const { data: result, error } = await backend.fetch<{
          totalProcessed: number;
        }>("/paf/upload", {
          method: "POST",
          body: formData,
        });

        if (error) {
          throw error;
        }

        toast({
          variant: "success",
          title: "Success",
          description: `${result.totalProcessed} carespaces processed successfully`,
        });

        await refetch();
      } catch (error) {
        Sentry.captureException(error);
        toast({
          variant: "destructive",
          title: "Error",
          description:
            error instanceof Error ? error.message : "Failed to upload paf",
        });
      } finally {
        setIsUploadingPaf(false);
        if (pafInputRef.current) {
          pafInputRef.current.value = "";
        }
      }
    },
    [toast, setIsUploadingPaf, refetch],
  );

  const columns = useMemo(() => {
    return [
      columnHelper.accessor("name", {
        header: "Carespace",
        meta: {
          columnFiltering: {
            filterLabel: "Carespace",
          },
        },
      }),
      columnHelper.accessor("patient.birthday", {
        header: "Date of Birth",
        cell: (ctx) => {
          const value = ctx.getValue();
          if (!value) {
            return "N/A";
          }
          return format(value, "MM/dd/yyyy");
        },
        meta: {
          columnFiltering: {
            filterLabel: "Date of Birth",
            filterType: "date",
          },
        },
      }),
      columnHelper.accessor("patient.sex", {
        header: "Sex",
        meta: {
          columnFiltering: {
            filterLabel: "Sex",
            formatOptionLabel: (value) => capitalize(value),
          },
        },
      }),
      columnHelper.accessor("patient.tier", {
        header: "Complexity",
        cell: (ctx) => capitalize(ctx.getValue()),
        meta: {
          columnFiltering: {
            filterLabel: "Complexity",
          },
        },
      }),
      columnHelper.accessor("guide_status", {
        header: "Status",
        cell: (ctx) => capitalize(ctx.getValue()),
        meta: {
          columnFiltering: {
            filterLabel: "Status",
            formatOptionLabel: capitalize,
          },
        },
      }),
      columnHelper.accessor(
        (row) => row.care_team.primary_caregiver?.name || "Pending",
        {
          header: "Primary Caregiver",
          meta: {
            columnFiltering: {
              filterLabel: "Primary Caregiver",
            },
          },
        },
      ),
      columnHelper.accessor((row) => row.provider_name || "TBD", {
        header: "Provider",
        meta: {
          columnFiltering: {
            filterLabel: "Provider",
          },
        },
      }),
    ];
  }, []);

  if (isLoading) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <LoadingSpinner className="w-6 h-6" />
      </div>
    );
  }

  if (isError) {
    Sentry.captureException(error);
    return (
      <div className="p-6">
        <Text>Sorry, something went wrong. Please try again.</Text>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4 p-6">
      <Title>Carespaces</Title>
      <div className="flex items-center gap-4">
        {orgRole?.is_superuser || isSuperSuperUser ? (
          <>
            <input
              type="file"
              accept=".csv"
              name="carespaces-upload-input"
              ref={carespaceInputRef}
              onChange={handleUploadCsv}
              style={{ display: "none" }}
            />

            <input
              type="file"
              accept=".xlsx"
              name="paf-upload-input"
              ref={pafInputRef}
              onChange={handleUploadPaf}
              style={{ display: "none" }}
            />

            <SplitButton
              icon={<Plus className="size-4" />}
              title="Add Carespace"
              disabled={
                (!orgRole && !isSuperSuperUser) ||
                organizationId === ALL_ORGANIZATIONS
              }
              onClick={() => {
                navigate({
                  path: Route.CARESPACES_NEW,
                });
              }}
              options={[
                {
                  label: "Upload CSV",
                  onClick: () => carespaceInputRef.current?.click(),
                  icon: <Upload className="h-4 w-4" />,
                  disabled:
                    isUploadingCarespaces ||
                    organizationId === ALL_ORGANIZATIONS,
                },
                {
                  label: "Download sample file",
                  onClick: () =>
                    window.open(Route.SAMPLE_CARESPACES_CSV, "_blank"),
                  icon: <Download className="h-4 w-4" />,
                },
              ]}
              isLoading={isUploadingCarespaces}
            />

            <SplitButton
              icon={<Download className="size-4" />}
              title="Download PAFs"
              variant="secondary"
              disabled={
                isUploadingPaf ||
                Object.keys(selectedRows).length === 0 ||
                Object.keys(selectedRows).some((id) => {
                  const carespace = carespaces.find((cs) => cs.id === id);
                  return (
                    carespace?.guide_status === "new" ||
                    carespace?.guide_status === "screened"
                  );
                })
              }
              onClick={() => {
                const fileName = buildPAFFileName(null);
                downloadPaf.mutate(
                  {
                    ids: Object.keys(selectedRows),
                    fileName,
                  },
                  {
                    onSuccess: () => {
                      refetch();
                    },
                  },
                );
              }}
              options={[
                {
                  label: "Upload PAAF Results Tracker",
                  onClick: () => pafInputRef.current?.click(),
                  icon: <Upload className="h-4 w-4" />,
                  disabled: isUploadingPaf,
                },
              ]}
              isLoading={downloadPaf.isPending || isUploadingPaf}
            />
          </>
        ) : (
          <Button
            onClick={() => {
              navigate({
                path: Route.CARESPACES_NEW,
              });
            }}
          >
            <Plus className="size-4 mr-2" />
            Add Carespace
          </Button>
        )}
      </div>

      <DataTable
        columns={columns}
        data={carespaces}
        onRowClick={(row) =>
          navigate({
            path: Route.CARESPACE,
            params: {
              carespaceId: row.id,
            },
          })
        }
        rowSelection={{
          selectedRows,
          setSelectedRows,
          getRowId: (row) => row.id,
        }}
        columnFiltering={{
          columnFilters,
          setColumnFilters,
        }}
      />
    </div>
  );
}
