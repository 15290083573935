import { supabase } from "clients/supabaseClient";

import type { Filter } from "features/query-utils";

import { OrganizationRole } from "features/users/constants";

import { ORGANIZATION_ROLE_TABLE_NAME, TABLE_NAME } from "../constants";
import type {
  OrganizationInsert,
  OrganizationRoleTableName,
  OrganizationRoleUpdate,
  OrganizationUpdate,
  TableName,
} from "../types";

const all = ["organizations"];
const lists = [...all, "list"];
const detail = [...all, "detail"];
const userRole = [...all, "userRole"];

export const queryKeys = {
  all,
  lists,
  list: (filter?: Filter<TableName>) => [...lists, filter],
  detail: (filter?: Filter<TableName>) => [...detail, filter],
  userRole: (filter: Filter<OrganizationRoleTableName>) => [
    ...userRole,
    filter,
  ],
};

export const fields = `
    id,
    created_at,
    name,
    address,
    city,
    state,
    zipcode,
    is_super_org,
    phone_number_business,
    phone_number_clinical,
    entity_npi,
    finance_tin,
    software_only,
    is_guide_participant,
    billing_delegated,
    billing_company_name,
    bank_name,
    bank_account_type,
    bank_account_number,
    bank_routing_number,
    care_plan_destination,
    fax_number,
    api_endpoint_url,
    redox_connection_slug,
    redox_connection_id,
    redox_organization_name
  `;

export const select = () => supabase.from(TABLE_NAME).select(fields);

export const update = (organizationUpdate: OrganizationUpdate) =>
  supabase.from(TABLE_NAME).update(organizationUpdate);

export const insert = (organizationInsert: OrganizationInsert) =>
  supabase.from(TABLE_NAME).insert(organizationInsert).select(fields);

const assignUserToOrg = ({
  userId,
  organizationId,
  role,
  isSuperuser,
}: {
  userId: string;
  organizationId: string;
  role: OrganizationRole;
  isSuperuser?: boolean;
}) =>
  supabase
    .from(ORGANIZATION_ROLE_TABLE_NAME)
    .insert({
      user_id: userId,
      organization_id: organizationId,
      is_superuser: isSuperuser ?? role === OrganizationRole.ADMIN,
      role,
    })
    .select()
    .maybeSingle();

export const selectUserRoles = () =>
  supabase.from(ORGANIZATION_ROLE_TABLE_NAME).select(
    `
        role,
        is_superuser,
        organization(
          id,
          is_super_org
        )
      `,
  );

export const updateRole = (organizationRoleUpdate: OrganizationRoleUpdate) =>
  supabase.from(ORGANIZATION_ROLE_TABLE_NAME).update(organizationRoleUpdate);
