import BackButton from "components/BackButton/BackButton";
import { LoadingSpinner } from "components/LoadingSpinner";
import { PageContainer } from "components/PageContainer";
import { PageMainHeader } from "components/PageMainHeader";
import { useAuthUser } from "features/users/auth";
import { CraniometrixProduct, useProductAccess } from "hooks/product/product";
import { useParams } from "react-router-dom";
import { useAlfredPageSideBar } from "../../backend/resources/chatGptConversation";
import { useLatestCompletedCaregiverAssesment } from "../../backend/resources/userAssessment";

import { useFetchOne } from "features/users/queries/hooks";

const SHORT_ZARIT_ASSESMENT_ID = "9a52a105-2885-442f-9e80-0050ea7dd504";

export default function MyCarePage() {
  // Store
  const { authUser } = useAuthUser();
  let { id } = useParams();
  id = id || authUser?.id;

  // queries
  useAlfredPageSideBar("myCarePage");
  const { data: productAccess } = useProductAccess();
  const { data: caregiver } = useFetchOne(
    { equals: { id } },
    { enabled: !!id },
  );
  const {
    data: lastCompletedCaregiverAssessment,
    isLoading: isLoadingLatestCompletedCaregiverAssessment,
  } = useLatestCompletedCaregiverAssesment(id);

  // consts
  const isOwnPage = id === authUser?.id;
  const latestScore = lastCompletedCaregiverAssessment?.total_score as number;
  const hasTakenAnAssessment = !!lastCompletedCaregiverAssessment;

  const isLatestAssessmentShortZarit =
    lastCompletedCaregiverAssessment?.assessment_id ===
    SHORT_ZARIT_ASSESMENT_ID;
  const highestScorePossible = isLatestAssessmentShortZarit ? 48 : 88;

  // +-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+
  return (
    <PageContainer>
      {productAccess === CraniometrixProduct.CARE_CENTRAL ? (
        <BackButton className="mb-2" />
      ) : null}
      <PageMainHeader
        text={isOwnPage ? "My Care" : `${caregiver?.first_name}'s Care`}
        className="mb-10"
      />

      {!isLoadingLatestCompletedCaregiverAssessment ? (
        <>
          <p className="font-bold py-4 text-lg">ZARIT Stress Score</p>
          {hasTakenAnAssessment ? (
            <p className="text-sm">
              As of{" "}
              {isOwnPage
                ? "your"
                : `${caregiver?.first_name} ${caregiver?.last_name}'s`}{" "}
              last assessment taken on{" "}
              {new Date(
                lastCompletedCaregiverAssessment?.created_at,
              ).toLocaleDateString()}
              , {isOwnPage ? "your" : "their"} state of stress was rated a{" "}
              <span className="font-bold">{latestScore} </span>
              out of {highestScorePossible}, where 0 is the lowest and{" "}
              {highestScorePossible} is the highest.
              {/* {burdenText} */}
            </p>
          ) : (
            <p className="text-sm">
              No self assessments have been taken yet. Your doctor will assign
              one to you at the appropriate time. If you have any questions,
              please ask in the Discussions section.
            </p>
          )}
        </>
      ) : (
        <div className="w-full flex justify-center items-center">
          <LoadingSpinner className="w-10" />
        </div>
      )}
    </PageContainer>
  );
}
