import { useEffect, useRef, useState } from "react";

import { format } from "date-fns";
import dayjs from "dayjs";
import { useSearchParams } from "react-router-dom";
import { NotificationType, sendNotification } from "../../backend/functions";
import { useAlfredPageSideBar } from "../../backend/resources/chatGptConversation/chatGptConversation";
import { useGalleryMediaQueryInUserUpdateQuery } from "../../backend/resources/userUpdates/useUserUpdateToGalleryMedia";
import {
  useDeleteUserUpdateMutation,
  useHasInProgressUpdates,
  useInsertUserUpdateMutation,
  useUserUpdate,
} from "../../backend/resources/userUpdates/userUpdates";

import BackButton from "components/BackButton/BackButton";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { PageContainer } from "components/PageContainer";
import { PageMainHeaderWithCarespaceName } from "components/PageMainHeader/PageMainHeaderWithCarespace";
import { Popup } from "components/Popup";
import { Select } from "components/Select";
import { BottomSheet } from "components/Sheet";
import UpdatePageDiscussionSection from "components/UpdatesPage/UpdatePageDiscussionSection";
import GallerySection from "components/UpdatesPage/UpdatesPageGallerySection";
import { useAuthUser } from "features/users/auth";
import { CraniometrixProduct, useProductAccess } from "hooks/product/product";
import { useWindowSize } from "hooks/useWindowSize";
import { Checkmark } from "icons/Checkmark";
import ActionButtons from "shared/ui/action-buttons";
import { FreeFormText } from "shared/ui/free-form-text";
import { useAssessmentStore } from "state/assessment";
import { useActiveCarespaceId } from "state/carespace/carespace";
import {
  getCurrentUserUpdate,
  setActiveUserUpdateId,
  setGalleryMedia,
  setInProgressUserUpdate,
  setSummary,
  setUserUpdates,
  useSaveCurrentUserUpdate,
  userUpdatesStore,
} from "state/userUpdate/userUpdate";
import { createDropDownOptions } from "utils";
import { useOrgs } from "../../backend/resources/orgRole/orgRole";

import { useFetchOne } from "features/users/queries/hooks";
import type { UserUpdateState } from "state/userUpdate/userUpdate";

export default function UpdatesPage() {
  // URL
  const [searchParams, setSearchParams] = useSearchParams();
  const wasEditing = searchParams.get("isEditing");

  // States
  const [isEditing, setIsEditing] = useState(wasEditing === "true");
  const [
    isSummaryRequiredErrorMessageShowing,
    setIsSummaryRequiredErrorMessageShowing,
  ] = useState(false);
  const [isDeletingPopupOpen, setIsDeletingPopupOpen] = useState(false);
  const [
    isSuccessfullyPublishedPopupOpen,
    setIsSuccessfullyPublishedPopupOpen,
  ] = useState(false);

  const targetRef = useRef(null);

  // Mutations
  const createUserUpdate = useInsertUserUpdateMutation().mutateAsync;
  const deleteUserUpdate = useDeleteUserUpdateMutation().mutateAsync;
  const saveUserUpdateChanges = useSaveCurrentUserUpdate();

  // Hooks
  useAlfredPageSideBar("userUpdatePage");
  const { authUser } = useAuthUser();
  const carespaceId = useActiveCarespaceId();
  const activeUserAssessmentId = useAssessmentStore(
    (state) => state.activeUserAssessmentId,
  );
  const setActiveUserAssessmentId = useAssessmentStore(
    (state) => state.setActiveUserAssessmentId,
  );
  const { hasCareCentralAccess } = useOrgs();

  const { isMobile } = useWindowSize();
  const { userUpdates, isLoadingUserUpdates } = useUserUpdate(); // published user updates
  const { inProgressUserUpdate } = useHasInProgressUpdates(); // draft

  const userUpdateSelectorOptions = createDropDownOptions(userUpdates);
  const summary = userUpdatesStore((state: UserUpdateState) => state.summary);
  const activeUserUpdateId = userUpdatesStore(
    (state: UserUpdateState) => state.activeUserUpdateId,
  );

  const { galleryUpdateInUserUpdate } =
    useGalleryMediaQueryInUserUpdateQuery(activeUserUpdateId);

  const { data } = useProductAccess();
  const createdById = getCurrentUserUpdate()?.created_by;

  const { data: createdBy } = useFetchOne(
    {
      equals: { id: createdById },
    },
    {
      enabled: !!createdById,
    },
  );
  // Effects

  useEffect(() => {
    if (userUpdates) {
      setUserUpdates(userUpdates);
    }
  }, [userUpdates]);

  useEffect(() => {
    if (isLoadingUserUpdates) return;
    // Whenever userUpdates changes,
    // we make the current activeUserId be the newest one and update the store
    // If the activeUserUpdateId is already in the userUpdates, we don't need to update the store
    if (
      !(
        activeUserUpdateId &&
        userUpdates?.find((update) => update.id === activeUserUpdateId)
      )
    ) {
      setActiveUserUpdateId(userUpdates?.[0]?.id);
    }
  }, [userUpdates, activeUserUpdateId, isLoadingUserUpdates]);

  useEffect(() => {
    // always update inProgressUserUpdate in the store
    if (inProgressUserUpdate) {
      setInProgressUserUpdate(inProgressUserUpdate);
      setActiveUserUpdateId(inProgressUserUpdate?.id);
    }
    const currentlySelectedUserUpdate = getCurrentUserUpdate();
    // If the current userUpdate changes, load it into page

    if (currentlySelectedUserUpdate) {
      setActiveUserUpdateId(currentlySelectedUserUpdate?.id);
      setSummary(currentlySelectedUserUpdate?.summary);
      setActiveUserAssessmentId(
        currentlySelectedUserUpdate?.user_assessment_id ||
          activeUserAssessmentId,
      );
      setGalleryMedia(galleryUpdateInUserUpdate);
    }
  }, [
    activeUserUpdateId,
    userUpdates,
    inProgressUserUpdate,
    galleryUpdateInUserUpdate,
    setActiveUserAssessmentId,
    activeUserAssessmentId,
  ]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isCurrentUserUpdateInProgress = () =>
    activeUserUpdateId === inProgressUserUpdate?.id;

  function resetCurrentUserUpdate() {
    const newestUserUpdate = userUpdates?.[0];
    setActiveUserUpdateId(newestUserUpdate?.id);
    setSummary(newestUserUpdate?.summary);
    setActiveUserAssessmentId(
      newestUserUpdate?.user_assessment_id || activeUserAssessmentId,
    );
    setGalleryMedia(galleryUpdateInUserUpdate);
    setIsEditing(false);
  }

  return (
    <PageContainer>
      <div className="flex flex-col gap-2 sticky top-0 bg-white z-[2] w-full pb-6 border-b pt-4 md:pt-8 px-6 md:px-10">
        {data === CraniometrixProduct.CARE_CENTRAL ? (
          <BackButton className="mb-2 " />
        ) : null}
        <PageMainHeaderWithCarespaceName
          text={
            isEditing
              ? `${
                  isCurrentUserUpdateInProgress() ? "Create" : "Edit"
                } Update for ${format(
                  new Date(getCurrentUserUpdate()?.completed_at || Date.now()),
                  "MM/dd/yy",
                )}`
              : "Family Hub"
          }
        />
        {/* Nav Bar */}
        <div className="flex items-center justify-center md:justify-start md:flex-wrap gap-4 overflow-x-scroll">
          <p>Update</p>
          {userUpdates && userUpdates.length > 0 ? (
            <Select
              classNames="whitespace-nowrap w-min"
              onChange={(item) => {
                setActiveUserUpdateId(item);
              }}
              disabled={userUpdateSelectorOptions.length === 0}
              options={
                userUpdateSelectorOptions.length > 0
                  ? userUpdateSelectorOptions
                  : [{ label: "N/A", value: "N/A" }]
              }
              currentOption={
                userUpdateSelectorOptions.find(
                  (option) => option.value === activeUserUpdateId,
                ) ||
                userUpdateSelectorOptions.find(
                  (option) => option.value === userUpdates?.[0]?.id,
                )
              }
            />
          ) : (
            <p className="whitespace-nowrap"> N / A</p>
          )}
          {!hasCareCentralAccess ? (
            <ActionButtons
              children={<UserUpdateButtons />}
              title="Update Actions"
            />
          ) : null}
        </div>
      </div>
      <div className="relative max-w-screen overflow-x-clip pb-40 px-6 md:px-10 py-4 md:py-8 md:pb-20">
        {/* Update Sections */}
        <div className="flex items-center gap-4 w-full">
          <div className="w-full">
            {/* Summary Section */}
            <div className="flex flex-col gap-4 pb-5 w-full">
              <p className="text-xl">Summary{isEditing ? "*" : ""}</p>
              {isEditing ? (
                <div>
                  {isSummaryRequiredErrorMessageShowing ? (
                    <p className="text-red-500">
                      Must have a summary to publish
                    </p>
                  ) : null}

                  <FreeFormText
                    className="min-h-[100px] w-full"
                    placeholder="Enter a summary here..."
                    answer={summary as string | undefined}
                    onChange={(value) => {
                      setSummary(value.toString());
                    }}
                  />
                </div>
              ) : (
                <p>{getCurrentUserUpdate()?.summary}</p>
              )}
              {/* updated by */}
              {createdBy ? (
                <div className="italic">
                  Created by {createdBy.first_name} at{" "}
                  {dayjs(getCurrentUserUpdate()?.created_at).format("h:mm A")}{" "}
                  on{" "}
                  {dayjs(getCurrentUserUpdate()?.created_at).format("M/D/YY")}.
                </div>
              ) : null}
            </div>

            {!activeUserUpdateId ? (
              <p className="text-sm mb-5">
                Once an update is posted, you'll be able to see the summary
                here.
              </p>
            ) : null}

            {/* Recommendations */}
            {/* <div className="my-4 mb-10">
              <UpdatePageRecommendationSection isEditing={isEditing} />
            </div> */}
            {/* Gallery Section */}
            <GallerySection isEditing={isEditing} />
            {!isEditing ? (
              <div ref={targetRef}>
                <UpdatePageDiscussionSection />
              </div>
            ) : null}
          </div>
        </div>

        {/* Deleting Popup */}
        {isDeletingPopupOpen ? (
          !isMobile ? (
            <Popup close={() => setIsDeletingPopupOpen(false)}>
              <DeletingModal />
            </Popup>
          ) : (
            <BottomSheet
              isOpen={true}
              onClose={() => setIsDeletingPopupOpen(false)}
            >
              <DeletingModal />
            </BottomSheet>
          )
        ) : null}
        {isSuccessfullyPublishedPopupOpen ? (
          !isMobile ? (
            <Popup close={() => setIsSuccessfullyPublishedPopupOpen(false)}>
              <SuccessfullyPublishedModal />
            </Popup>
          ) : (
            <BottomSheet
              isOpen={true}
              onClose={() => setIsSuccessfullyPublishedPopupOpen(false)}
            >
              <SuccessfullyPublishedModal />
            </BottomSheet>
          )
        ) : null}
      </div>
    </PageContainer>
  );

  function PublishOrUpdateButton() {
    return (
      <ButtonWithIcon
        onClick={async () => {
          if (!activeUserUpdateId) return;

          if (summary) {
            setIsSummaryRequiredErrorMessageShowing(false);
            await saveUserUpdateChanges({ publish: true });
            setIsEditing(false);
            setIsSuccessfullyPublishedPopupOpen(true);
            sendNotification(
              activeUserUpdateId,
              NotificationType.UPDATE_POSTED_IN_HUB,
            );
          } else {
            setIsSummaryRequiredErrorMessageShowing(true);
          }
        }}
        text={isCurrentUserUpdateInProgress() ? "Publish" : "Update"}
        icon={IconOption.ARROW}
        size="small"
      />
    );
  }

  function CreateButton() {
    return (
      <ButtonWithIcon
        onClick={async () => {
          if (carespaceId && authUser) {
            resetCurrentUserUpdate();
            // Create new user update:
            const newUserUpdate = await createUserUpdate({
              created_by: authUser.id,
              carespace_id: carespaceId,
            });
            setIsEditing(true);
            setSummary();
            setActiveUserUpdateId(newUserUpdate?.id);
          }
        }}
        text={`Create${isMobile ? " new update" : ""}`}
        icon={IconOption.PLUS}
        size="small"
      />
    );
  }

  function SaveDraftButton() {
    return (
      <ButtonWithIcon
        onClick={async () => {
          await saveUserUpdateChanges({ publish: false });
          setIsEditing(false);
          resetCurrentUserUpdate();
        }}
        text="Save Draft"
        icon={IconOption.SAVE_DRAFT}
        size="small"
      />
    );
  }

  function EditCancelButton() {
    return (
      <ButtonWithIcon
        onClick={() => {
          // if cancel, we reset the update
          if (isEditing) {
            resetCurrentUserUpdate();
            setSearchParams(); // wipes out search params
          } else {
            // otherwise, load the update
            setActiveUserUpdateId(activeUserUpdateId);
          }
          // flip it
          setIsEditing(!isEditing);
        }}
        text={isEditing ? "Cancel" : `Edit${isMobile ? " update" : ""}`}
        icon={isEditing ? IconOption.CANCEL : IconOption.EDIT}
        size="small"
      />
    );
  }

  function DeleteButton() {
    return (
      <ButtonWithIcon
        disabled={!activeUserUpdateId}
        onClick={() => {
          setIsDeletingPopupOpen(true);
        }}
        size="small"
        text={`Delete ${isMobile ? " update" : ""}`}
        icon={IconOption.TRASH}
      />
    );
  }

  function ResumeButton() {
    return (
      <ButtonWithIcon
        onClick={() => {
          setActiveUserUpdateId(inProgressUserUpdate?.id);
          setSummary(inProgressUserUpdate?.summary);
          setIsEditing(true);
        }}
        size="small"
        text="Resume Draft"
        icon={IconOption.ARROW}
      />
    );
  }

  function UserUpdateButtons() {
    const EditCancelButtonComponent = (isEditing ||
      (userUpdates && userUpdates?.length > 0)) && <EditCancelButton />;

    if (!isEditing) {
      return (
        <div className="flex gap-4 mr-10 flex-col md:flex-row ">
          {EditCancelButtonComponent}
          <DeleteButton />
          {inProgressUserUpdate && !!activeUserUpdateId ? (
            <ResumeButton />
          ) : (
            <CreateButton />
          )}
        </div>
      );
    }

    return (
      <div className="flex gap-4  flex-col md:flex-row mr-10 w-full justify-end">
        {EditCancelButtonComponent}
        {isCurrentUserUpdateInProgress() ? <SaveDraftButton /> : null}
        <PublishOrUpdateButton />
      </div>
    );
  }

  function DeletingModal() {
    return (
      <div className="md:w-[480px]  w-full px-6 md:px-0 flex flex-col h-full text-center gap-10 justify-center items-center text-base pb-10 md:pb-0">
        <p className="text-lg w-full text-center ">Delete Update</p>
        <p> Are you sure you want to delete this update?</p>
        <div className="flex justify-around w-full ">
          <ButtonWithIcon
            icon={IconOption.CANCEL}
            onClick={() => setIsDeletingPopupOpen(false)}
            text="Cancel"
            size="small"
          />
          <ButtonWithIcon
            icon={IconOption.ARROW}
            onClick={async () => {
              setIsDeletingPopupOpen(false);
              if (activeUserUpdateId && carespaceId) {
                await deleteUserUpdate({
                  id: activeUserUpdateId,
                  carespaceId,
                });
                resetCurrentUserUpdate();
              }
            }}
            size="small"
            text="Yes"
          />
        </div>
      </div>
    );
  }
  function SuccessfullyPublishedModal() {
    return (
      <div className="md:w-[240px]  w-full px-6 md:px-0 flex flex-col h-full text-center gap-6 justify-center items-center text-base">
        <div className="bg-green-500 rounded-full p-1">
          <Checkmark size="18px" fill="#FFFFFF" />
        </div>
        <p className="text-lg w-full text-center ">Successful Update</p>
        <div className="flex justify-center md:justify-end w-full pb-5 md:pb-0 ">
          <ButtonWithIcon
            icon={IconOption.CHECKMARK}
            onClick={async () => {
              setIsSuccessfullyPublishedPopupOpen(false);
            }}
            text="Ok"
            size="small"
          />
        </div>
      </div>
    );
  }
}
