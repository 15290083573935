import { supabase } from "clients/supabaseClient";

import type { Filter } from "features/query-utils";
import { TABLE_NAME } from "../constants";
import type { OrganizationInvitationUpdate, TableName } from "../types";

const all = ["organization_invitations"];
const detail = [...all, "detail"];

export const queryKeys = {
  all,
  detail: (filter?: Filter<TableName>) => [...detail, filter],
};

export const select = () => supabase.from(TABLE_NAME).select();

export const update = (data: OrganizationInvitationUpdate) =>
  supabase.from(TABLE_NAME).update(data);

export const remove = (id: string) =>
  supabase.from(TABLE_NAME).delete().eq("id", id);
