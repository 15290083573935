import { useAuthUser } from "features/users/auth";
import { useProductAccess } from "hooks/product/product";
import { useAppOnboardingStore } from "state/appOnboarding";
import { useCarespaceStore } from "state/carespace/carespace";
import { useOrgs } from "../../backend/resources/orgRole/orgRole";
import { useCarespaceIds } from "../../backend/resources/userRole/userRole";

export function useUserOnboarding() {
  const { authUser } = useAuthUser();
  const { isLoading: isOrgsLoading, hasOneOrMoreOrgIdentities } = useOrgs();

  const { activeCarespaceId } = useCarespaceStore();
  const { carespaceIdsIsLoading, carespaceIds } = useCarespaceIds();

  const { data: productAccess, isLoading: isLoadingProductAccess } =
    useProductAccess();

  const selectedOrgRole = useAppOnboardingStore(
    (state) => state.selectedOrgRole,
  );

  const currentCareCentralOnboardingPage = useAppOnboardingStore(
    (state) => state.currentCareCentralOnboardingPage,
  );

  const currentCarePilotOnboardingPage = useAppOnboardingStore(
    (state) => state.currentCarePilotOnboardingPage,
  );

  function checkIsCareCentralOnboardingShowing() {
    return !!currentCareCentralOnboardingPage;
  }

  function checkIsCarePilotOnboardingShowing() {
    const isCarePilotOnboardingOngoing =
      !!currentCarePilotOnboardingPage &&
      currentCarePilotOnboardingPage !== "completed";
    return (
      isCarePilotOnboardingOngoing ||
      (!!authUser &&
        productAccess === "care pilot" &&
        !isLoadingProductAccess &&
        activeCarespaceId !== undefined &&
        carespaceIds &&
        carespaceIds.length > 0)
    );
  }

  return {
    isLoading: isLoadingProductAccess || isOrgsLoading || carespaceIdsIsLoading,
    isUserRoleSelectionShowing:
      authUser &&
      !isOrgsLoading &&
      !hasOneOrMoreOrgIdentities &&
      !selectedOrgRole &&
      !activeCarespaceId &&
      !carespaceIdsIsLoading &&
      carespaceIds &&
      carespaceIds.length === 0,
    isCareCentralOnboardingShowing: checkIsCareCentralOnboardingShowing(),
    isCarePilotOnboardingShowing: checkIsCarePilotOnboardingShowing(),
  };
}
