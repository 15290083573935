import * as Sentry from "@sentry/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import capitalize from "lodash/capitalize";

import {
  type Filter,
  type QueryOptions,
  buildFilters,
} from "features/query-utils";

import { supabase } from "clients/supabaseClient";
import type { CarespaceRole } from "features/users/types";
import queryClient from "shared/query-client";
import { Sex } from "../constants";
import type {
  Activity,
  AssessmentTableName,
  AssessmentTypes,
  Carespace,
  DementiaStagingTool,
  GuideStatus,
  HasCaregiver,
  NewActivityPayload,
  PhoneType,
  TableName,
  UserAssessmentAnswerInsert,
  UserAssessmentInsert,
  UserAssessmentTableName,
} from "../types";
import { isPatientTier, isSex } from "../types";

import type { QueryData } from "@supabase/supabase-js";
import type { US_STATES } from "features/organizations/constants";
import { useAuthUser } from "features/users/auth";
import { calculateScore } from "../utils";
import {
  addActivity,
  insertAssessment,
  insertAssessmentAnswers,
  insertGuideStatus,
  queryKeys,
  select,
  selectAllActivities,
  selectAssessments,
  selectUserAssessments,
  selectUserRole,
  updateActivityStatus,
} from "./index";

type CarespaceQueryData = QueryData<ReturnType<typeof select>>[0];

function formatGuideStatus(carespace: CarespaceQueryData): GuideStatus {
  if (
    !Array.isArray(carespace.guide_status) ||
    carespace.guide_status.length === 0
  ) {
    return "new";
  }

  const guideStatus = carespace.guide_status[0].status;

  if (guideStatus) {
    return guideStatus;
  }

  return "new";
}

function formatName(
  user: { first_name: string | null; last_name: string | null } | null,
): string | null {
  return user?.first_name && user?.last_name
    ? `${capitalize(user.first_name)} ${capitalize(user.last_name)}`
    : null;
}

function formatCarespace(carespace: CarespaceQueryData): Carespace {
  const patient = carespace.patient;
  const pafSubmission = carespace.paf_submission.find(
    (submission) => submission.status === "draft",
  );

  return {
    ...carespace,
    pcp_name: formatName({
      first_name: carespace.pcp_first_name,
      last_name: carespace.pcp_last_name,
    }),
    provider_name: formatName({
      first_name: carespace.provider_first_name,
      last_name: carespace.provider_last_name,
    }),
    clinician_name: pafSubmission
      ? formatName({
          first_name: pafSubmission.clinician_first_name,
          last_name: pafSubmission.clinician_last_name,
        })
      : null,
    patient: patient
      ? {
          ...patient,
          sex: isSex(patient?.sex) ? patient.sex : Sex.UNKNOWN,
          tier: isPatientTier(patient?.tier) ? patient.tier : "na",
          birthday: patient.birthday ? new Date(patient.birthday) : null,
          name: formatName(patient) ?? "Unknown",
          race: patient.race,
          ethnicity: patient.ethnicity,
          has_caregiver: patient.has_caregiver as HasCaregiver,
          state: patient.state as (typeof US_STATES)[number],
          phone_type: patient.phone_type as PhoneType,
        }
      : null,
    guide_status: formatGuideStatus(carespace),
    guide_status_rationale:
      Array.isArray(carespace.guide_status) && carespace.guide_status.length > 0
        ? carespace.guide_status[0]?.rationale
        : null,
    name: formatName(patient) ?? "Unknown",
    care_team: carespace.user_role.reduce(
      (acc, { role, user }) => {
        // FIXME: this won't work for multiple caregivers
        acc[role] = {
          role,
          name: formatName(user),
        };
        return acc;
      },
      {} as Record<string, unknown>,
    ),
    user_role: carespace.user_role
      .map((userRole) => ({
        role: userRole.role as CarespaceRole,
        user: {
          ...userRole.user,
          name: formatName(userRole.user),
        },
      }))
      .filter((userRole): userRole is Carespace["user_role"][0] =>
        Boolean(userRole.user),
      ),
    current_paf_submission: pafSubmission
      ? {
          status: pafSubmission.status,
          assessment_date: pafSubmission.assessment_date ?? undefined,
          dementia_staging_tool:
            pafSubmission.dementia_staging_tool as DementiaStagingTool,
          dementia_staging_score:
            pafSubmission.dementia_staging_score ?? undefined,
          icd10_code: pafSubmission.icd10_code ?? undefined,
          clinician_first_name: pafSubmission.clinician_first_name ?? undefined,
          clinician_last_name: pafSubmission.clinician_last_name ?? undefined,
          clinician_npi: pafSubmission.clinician_npi ?? undefined,
          clinician_attestation:
            pafSubmission.clinician_attestation ?? undefined,
        }
      : null,
  };
}

export function useFetchOne(
  filter: Filter<TableName>,
  options: QueryOptions = {},
) {
  const { enabled } = options;

  return useQuery({
    queryKey: queryKeys.detail(filter),
    queryFn: async () => {
      const query = buildFilters(select(), filter);

      const { data, error } = await query.limit(1).single();

      if (error) {
        Sentry.captureException(error);
        throw error;
      }

      return formatCarespace(data);
    },
    enabled,
  });
}

export function useFetchMany(
  filter?: Filter<TableName>,
  options: QueryOptions = {},
) {
  const { enabled } = options;

  return useQuery({
    queryKey: filter ? queryKeys.list(filter) : queryKeys.lists,
    queryFn: async () => {
      const query = buildFilters(select(), filter);

      const { data, error } = await query
        .order("name", { ascending: true })
        .order("created_at", {
          referencedTable: "paf_submission",
          ascending: true,
        });

      if (error) {
        Sentry.captureException(error);
        throw error;
      }

      return data.map((carespace) => formatCarespace(carespace));
    },
    enabled,
  });
}

async function fetchCreatorNames(
  activities: Omit<Activity, "creatorName">[],
): Promise<Activity[]> {
  const userIds = [
    ...new Set(
      activities
        .filter((activity) => activity.created_by_type === "user")
        .map((activity) => activity.created_by_id),
    ),
  ];

  // If there are user IDs, fetch their names from the public.user table
  if (userIds.length === 0) {
    return activities;
  }

  const { data: userData, error: userError } = await supabase
    .from("user")
    .select("id, first_name, last_name")
    .in("id", userIds);

  if (userError) {
    Sentry.captureException(userError);
    // Don't throw here, just log the error and continue with activities without user names
    console.error("Error fetching user names:", userError);
    return activities;
  }

  if (!userData) {
    return activities;
  }

  // Create a map of user IDs to names
  const userMap = new Map(
    userData.map((user) => [
      user.id,
      user.first_name && user.last_name
        ? `${user.first_name} ${user.last_name}`
        : undefined,
    ]),
  );

  // Add creator names to activities
  return activities.map((activity) => {
    if (
      activity.created_by_type === "user" &&
      userMap.has(activity.created_by_id)
    ) {
      return {
        ...activity,
        creatorName: userMap.get(activity.created_by_id),
      };
    }
    return activity;
  });
}

export function useFetchActivities(carespace_id?: string) {
  return useQuery<Activity[]>({
    queryKey: ["activities", carespace_id],
    queryFn: async () => {
      if (!carespace_id) return [];

      const { data, error } = await selectAllActivities(carespace_id);

      if (error) {
        Sentry.captureException(error);
        throw error;
      }

      return fetchCreatorNames(data as Omit<Activity, "creatorName">[]);
    },
  });
}

export function useUpdateActivityStatus(carespace_id?: string) {
  return useMutation({
    mutationFn: async ({
      activity_id,
      status,
    }: {
      activity_id: string;
      status: "completed" | "skipped";
    }) => {
      const { error } = await updateActivityStatus(activity_id, status);

      if (error) {
        Sentry.captureException(error);
        throw error;
      }

      queryClient.invalidateQueries({
        queryKey: ["activities", carespace_id],
      });
    },
  });
}

export function useAddActivity(carespace_id?: string) {
  const { authUser } = useAuthUser();

  return useMutation({
    mutationFn: async (activity: NewActivityPayload) => {
      if (!carespace_id) {
        throw new Error("Carespace not found");
      }

      if (!authUser) {
        throw new Error("User not found");
      }

      const { error } = await addActivity({
        ...activity,
        carespace_id,
        created_at: new Date().toISOString(),
        created_by_id: authUser.id,
        created_by_type: "user",
      });

      if (error) {
        Sentry.captureException(error);
        throw error;
      }

      queryClient.invalidateQueries({
        queryKey: ["activities", carespace_id],
      });
    },
  });
}

export function useGenerateActivities(carespace_id?: string) {
  return useMutation({
    mutationFn: async () => {
      if (!carespace_id) {
        throw new Error("Carespace not found");
      }

      await supabase.functions.invoke("generate_activities", {
        body: {
          carespace_id,
        },
      });

      queryClient.invalidateQueries({
        queryKey: ["activities", carespace_id],
      });
    },
  });
}

export function useDownloadPaf() {
  return useMutation({
    mutationFn: async ({
      ids,
      fileName,
    }: { ids: string[]; fileName: string }) => {
      try {
        const { data, error } = await supabase.functions.invoke(
          "download_paf",
          {
            body: { ids },
          },
        );

        if (error) {
          console.error("Failed to download PAF file:", error);
          throw new Error("Failed to download PAF file");
        }

        // The response from the function should be a base64-encoded Excel file
        if (data?.fileContent) {
          // Convert base64 to blob
          const binaryString = window.atob(data.fileContent);
          const bytes = new Uint8Array(binaryString.length);
          for (let i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
          }
          const blob = new Blob([bytes], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });

          // Download the blob
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = `${fileName}.xlsx`;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);

          return true;
        }

        throw new Error("No file content returned");
      } catch (error) {
        console.error("Error downloading PAF file:", error);
        Sentry.captureException(error);
        throw error;
      }
    },
  });
}

export function useFetchOneAssessment(
  filter: Filter<AssessmentTableName>,
  options: QueryOptions = {},
) {
  const { enabled } = options;

  return useQuery({
    queryKey: queryKeys.detail(filter),
    queryFn: async () => {
      const query = buildFilters(selectAssessments(), filter);

      const { data, error } = await query.limit(1).single();

      if (error) {
        Sentry.captureException(error);
        throw error;
      }

      return data;
    },
    enabled,
  });
}

export function useFetchManyUserAssessements(
  filter: Filter<UserAssessmentTableName>,
  options: QueryOptions = {},
) {
  const { enabled } = options;

  return useQuery({
    queryKey: queryKeys.detail(filter),
    queryFn: async () => {
      const query = buildFilters(selectUserAssessments(), filter);

      const { data, error } = await query;

      if (error) {
        Sentry.captureException(error);
        throw error;
      }

      return data;
    },
    enabled,
  });
}

export function useCreateAssessment() {
  return useMutation({
    mutationFn: async ({
      slug,
      assessment,
      answers,
    }: {
      slug?: AssessmentTypes;
      assessment: UserAssessmentInsert;
      answers: Omit<UserAssessmentAnswerInsert, "user_assessment_id">[];
    }) => {
      assessment.total_score = calculateScore(answers, slug);
      const { data, error } = await insertAssessment(assessment);

      if (error) {
        Sentry.captureException(error);
        throw error;
      }

      if (!data) {
        const error = new Error("Error saving assessment");
        Sentry.captureException(error);
        throw error;
      }

      const { error: errorAnswers } = await insertAssessmentAnswers(
        answers.map((answer) => ({
          ...answer,
          user_assessment_id: data.id,
        })),
      );

      if (errorAnswers) {
        Sentry.captureException(errorAnswers);
        throw errorAnswers;
      }

      queryClient.invalidateQueries({
        queryKey: ["assessments"],
      });

      queryClient.invalidateQueries({
        queryKey: queryKeys.detail({
          equals: { id: assessment.carespace_id ?? undefined },
        }),
      });
    },
  });
}

export function useUpdateGuideStatus() {
  return useMutation({
    mutationFn: async ({
      carespaceId,
      status,
    }: {
      carespaceId: string;
      status: GuideStatus;
    }) => {
      const { error } = await insertGuideStatus({
        carespace_id: carespaceId,
        status,
      });

      if (error) {
        Sentry.captureException(error);
        throw error;
      }

      queryClient.invalidateQueries({
        queryKey: queryKeys.detail({
          equals: { id: carespaceId },
        }),
      });
    },
  });
}

export function useCarespaceUserRole(organizationId?: string | null) {
  const { authUser } = useAuthUser();

  return useQuery({
    queryKey: ["carespace_user_role", organizationId],
    queryFn: async () => {
      if (!authUser?.id || !organizationId) {
        throw new Error("User or organization not found");
      }

      const { data, error } = await selectUserRole(authUser.id, organizationId);

      if (error) {
        Sentry.captureException(error);
        throw error;
      }

      return data;
    },
  });
}
