import type { AuthenticatorAssuranceLevels } from "@supabase/supabase-js";
import { supabase } from "clients/supabaseClient";
import { LoadingSpinner } from "components/LoadingSpinner";
import { Route } from "features/routing/constants";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card } from "shared/ui/card";
import { Text } from "shared/ui/text";
import BasicLogin from "../auth/components/basic_login";
import MFA from "../auth/components/mfa";

export default function Login() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [currentLevel, setCurrentLevel] =
    useState<AuthenticatorAssuranceLevels | null>();

  const verifyAuthLevel = async (): Promise<void> => {
    try {
      const { data, error } =
        await supabase.auth.mfa.getAuthenticatorAssuranceLevel();
      if (error) {
        throw error;
      }

      if (data.currentLevel === "aal2") return onSuccess();

      setCurrentLevel(data.currentLevel);

      // User has logged in but did not enable MFA
      if (data.nextLevel === "aal1" && data.nextLevel === data.currentLevel)
        navigate(Route.MFA_ENROLL);
    } finally {
      setIsLoading(false);
    }
  };

  const onSuccess = () => navigate(Route.HOME);

  const title = () => {
    if (currentLevel === null) return "Welcome to Craniometrix";
    return "Security Code Required";
  };

  useEffect(() => {
    verifyAuthLevel();
  }, []);

  if (isLoading)
    return (
      <>
        <Text className="text-sm">Verifying session</Text>
        <LoadingSpinner />
      </>
    );

  return (
    <div className="flex items-center justify-center w-full h-full">
      <Card title={title()} className="w-[450px]">
        {currentLevel === null ? (
          <BasicLogin onSuccess={verifyAuthLevel} />
        ) : (
          <MFA onSuccess={onSuccess} />
        )}
      </Card>
    </div>
  );
}
