import { useMutation, useQuery } from "@tanstack/react-query";
import { supabase } from "clients/supabaseClient";
import { ALL_ORGANIZATIONS } from "features/routing/layouts/components/organization-switcher";
import { useActiveOrganizationId } from "state/organization/organization";
import type { Database } from "../../../../../types/supabase";
import queryClient from "../../../shared/query-client";
import { QUERY_KEYS } from "../../query-keys";
import type { ServiceEngagement } from "./serviceEngagement";

const TABLE = "service_resource";
export type ServiceResource =
  Database["public"]["Tables"]["service_resource"]["Row"];
type ServiceResourceUpdate =
  Database["public"]["Tables"]["service_resource"]["Update"];
export type ServiceResourceWithServiceEngagements = ServiceResource & {
  service_engagement: ServiceEngagement[];
};

export function useServiceResources() {
  const activeOrgId = useActiveOrganizationId();
  return useQuery({
    queryKey: [QUERY_KEYS.serviceResource, { activeOrgId }],
    queryFn: async () => {
      if (!activeOrgId) return null;
      let queryBuilder = supabase
        .from(TABLE)
        .select("*, service_engagement(*)")
        .order("created_at", { ascending: false });

      if (activeOrgId !== ALL_ORGANIZATIONS) {
        queryBuilder = queryBuilder.eq("organization_id", activeOrgId);
      }

      const { data, error } = await queryBuilder;

      if (error) {
        throw new Error(error.message);
      }

      return data;
    },
  });
}

export function useServiceResource(
  serviceResourceEventId: string | undefined | null,
) {
  return useQuery({
    queryKey: [QUERY_KEYS.serviceResource, serviceResourceEventId],
    queryFn: async () => {
      if (!serviceResourceEventId) return null;
      const { data } = await supabase
        .from(TABLE)
        .select("*")
        .eq("id", serviceResourceEventId)
        .limit(1)
        .maybeSingle();

      return data;
    },
  });
}

export function useUpdateServiceResource() {
  return useMutation({
    mutationFn: async (serviceResourceUpdate: ServiceResourceUpdate) => {
      if (!serviceResourceUpdate.id) return null;
      return supabase
        .from(TABLE)
        .update(serviceResourceUpdate)
        .eq("id", serviceResourceUpdate.id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.serviceResource] });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.serviceEngagements],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.chatGptConversation],
      });
    },
  });
}
