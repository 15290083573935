import type { QueryData } from "@supabase/supabase-js";

import type { Database, TablesInsert, TablesUpdate } from "types/supabase";

import type { ORGANIZATION_ROLE_TABLE_NAME, TABLE_NAME } from "./constants";
import type { select } from "./queries";

export type TableName = typeof TABLE_NAME;
export type OrganizationRoleTableName = typeof ORGANIZATION_ROLE_TABLE_NAME;

export type BankAccountType =
  Database["public"]["Enums"]["bank_account_type_enum"];
export type CarePlanDestination =
  Database["public"]["Enums"]["care_plan_destination_enum"];

type DatabaseOrganization = QueryData<ReturnType<typeof select>>[0];

export type Organization = DatabaseOrganization;

export type OrganizationUpdate = Omit<
  TablesUpdate<"organization">,
  "updated_at"
>;
export type OrganizationInsert = Omit<
  TablesInsert<"organization">,
  "updated_at"
>;

export type OrganizationRoleUpdate = TablesUpdate<"organization_role">;

// Transforms a type: if T is null, yield undefined; otherwise, keep T.
type ReplaceNull<T> = T extends null ? undefined : T;

// Maps over the object type T, replacing null with undefined in each property.
type ReplaceNulls<T> = {
  [K in keyof T]: ReplaceNull<T[K]>;
};

export function replaceNulls<T extends object>(obj: T): ReplaceNulls<T> {
  const result = {} as ReplaceNulls<T>;
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      // If value is exactly null, assign undefined; otherwise, keep the value.
      result[key] = (value === null ? undefined : value) as ReplaceNull<
        T[typeof key]
      >;
    }
  }
  return result;
}
