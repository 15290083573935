import * as Sentry from "@sentry/react";
import { supabase } from "clients/supabaseClient";
import type { AssessmentTypes, UserAssessmentAnswerInsert } from "./types";

interface CaregiverInfo {
  carespaceId?: string | null;
  caregiverId?: string | null;
  first_name: string;
  last_name: string;
  email: string;
  patient_has_caregiver?: string;
}

/**
 * Creates a caregiver user through the create_caregiver_user edge function
 * This will create a Supabase auth user and associate it with the carespace as a primary_caregiver
 *
 * @returns A boolean indicating whether the operation was successful
 */
export async function createCaregiverUserIfNeeded({
  carespaceId,
  caregiverId,
  first_name,
  last_name,
  email,
  patient_has_caregiver,
}: CaregiverInfo): Promise<boolean> {
  if (!carespaceId) {
    return false;
  }

  // Don't create a caregiver user if one already exists
  if (caregiverId) {
    return false;
  }

  // Check if we should create a caregiver user
  // Conditions:
  // 1. The patient has a caregiver
  // 2. The caregiver has an email, first name, and last name
  if (
    patient_has_caregiver &&
    patient_has_caregiver !== "no" &&
    patient_has_caregiver !== "undetermined" &&
    email &&
    first_name &&
    last_name
  ) {
    try {
      // Call the edge function to create the caregiver user and role
      const { error } = await supabase.functions.invoke(
        "create_caregiver_user",
        {
          body: {
            email,
            first_name,
            last_name,
            carespace_id: carespaceId,
          },
        },
      );

      if (error) {
        console.error("Failed to create caregiver user:", error);
        Sentry.captureException(error);
        return false;
      }

      return true;
    } catch (error) {
      // Log error but don't prevent carespace creation/update
      console.error("Error creating caregiver user:", error);
      Sentry.captureException(error);
      return false;
    }
  }

  return false;
}

export function calculateScore(
  answers: Omit<UserAssessmentAnswerInsert, "user_assessment_id">[],
  slug?: AssessmentTypes,
) {
  if (slug === "ZARIT") {
    return answers.reduce((acc, answer) => {
      if (typeof answer.answer === "number") {
        return acc + answer.answer;
      }

      throw new Error(`Invalid answer type: ${typeof answer.answer}`);
    }, 0);
  }

  if (slug === "PROMIS") {
    // We're not calculating a score for PROMIS
    return undefined;
  }

  return undefined;
}
