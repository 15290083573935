import type { OwnerType } from "features/careflow/types";
import { Text } from "shared/ui/text";
import { Title } from "shared/ui/title";
import { useUpdateActivityStatus } from "../queries/hooks";
import type { Activity } from "../types";
import ActivityCard from "./activity";

type ActivityListProps = {
  carespaceId: string;
  activities: Activity[];
  isLoading: boolean;
};

function sortByOwner(a: Activity, b: Activity) {
  const ownerOrder: Record<OwnerType, number> = {
    carenavigator: 0,
    caregiver: 1,
  };

  return ownerOrder[a.owner] - ownerOrder[b.owner];
}

export default function ActivityList({
  carespaceId,
  activities,
  isLoading,
}: ActivityListProps) {
  const updateActivityStatus = useUpdateActivityStatus(carespaceId).mutateAsync;

  if (isLoading) {
    return <div className="p-2">Loading activities...</div>;
  }

  return (
    <div className="flex flex-col py-2 gap-1">
      <Title order={4} color="gray">
        Current Activities
      </Title>
      {activities.length === 0 ? (
        <Text className="text-xs text-gray-500">
          All caught up! No current activities to tackle.
        </Text>
      ) : (
        activities
          .slice()
          .sort(sortByOwner)
          .map((activity) => (
            <ActivityCard
              key={activity.id}
              onComplete={() =>
                updateActivityStatus({
                  activity_id: activity.id,
                  status: "completed",
                })
              }
              onSkip={() =>
                updateActivityStatus({
                  activity_id: activity.id,
                  status: "skipped",
                })
              }
              {...activity}
            />
          ))
      )}
    </div>
  );
}
