import { DefaultCheckbox } from "components/Checkbox";
import SMSNotificationsApprovalModal from "components/SMSNotificationsApprovalModal/SMSNotificationsApprovalModal";
import { useAuthUser } from "features/users/auth";
import { useFetchOne } from "features/users/queries/hooks";
import React, { useState } from "react";
import OneSignal from "react-onesignal";
import type { NotificationPreference } from "../../backend/resources/notificationPreferences/notificationPreferences";
import {
  NotificationChannel,
  NotificationSection,
  useNotificationPreferences,
  useUpsertNotificationPreferenceMutation,
} from "../../backend/resources/notificationPreferences/notificationPreferences";
import PushSettingPopup from "./Popups/PushSettingPopup";

export default function SettingsPage() {
  const { authUser } = useAuthUser();
  const { data: user } = useFetchOne(
    { equals: { id: authUser?.id } },
    { enabled: !!authUser },
  );
  const [showSmsPopup, setShowSmsPopup] = useState(false);
  const [showPushPopup, setShowPushPopup] = useState(false);

  // Queries/Mutations
  const { notificationPreferences } = useNotificationPreferences();
  const upsertUserPreference =
    useUpsertNotificationPreferenceMutation().mutateAsync;

  // Helpers
  async function handleCheckboxChange(
    section: NotificationSection,
    type: NotificationChannel,
  ) {
    if (!user) return;
    const currentPreference = notificationPreferences?.find(
      (pref) => pref.section_name === section,
    );
    const currentPreferenceValue = currentPreference?.[type];
    if (type === NotificationChannel.SMS && !user.cell_number) {
      setShowSmsPopup(true);
    } else if (
      type === NotificationChannel.PUSH &&
      !OneSignal.User.PushSubscription.optedIn &&
      !currentPreferenceValue
    ) {
      setShowPushPopup(true);
      OneSignal.User.PushSubscription.optIn();
    } else {
      // Update the preference
      await upsertUserPreference({
        section_name: section,
        [type]: !currentPreferenceValue,
        user_id: user?.id,
      });
    }
  }

  function getSectionDisplayName(section: NotificationSection): string {
    switch (section) {
      case "My Plan": {
        return "Tasks";
      }
      default: {
        return section;
      }
    }
  }

  return (
    <div>
      {showSmsPopup && (
        <SMSNotificationsApprovalModal onClose={() => setShowSmsPopup(false)} />
      )}
      {showPushPopup && (
        <PushSettingPopup onClose={() => setShowPushPopup(false)} />
      )}
      <div className="flex flex-col gap-5 sticky  -top-[88px] z-[2]">
        <p className="text-xl mt-4">Notifications</p>
        <div className="grid grid-cols-[5fr,1fr,1fr,1fr]  text-base  text-center items-center mb-3 w-full gap-4">
          <p /> <p>SMS</p> <p>Email</p> <p>Push</p>
          {getNotificationSections().map((section) => {
            const currentPreference = notificationPreferences?.find(
              (pref: NotificationPreference) => pref.section_name === section,
            );
            return (
              <React.Fragment key={section}>
                <p className="text-left">{getSectionDisplayName(section)}</p>
                {Object.values(NotificationChannel).map((type) => (
                  <div className="w-full flex justify-center" key={type}>
                    <DefaultCheckbox
                      checked={currentPreference?.[type] ?? false}
                      onChange={() => handleCheckboxChange(section, type)}
                    />
                  </div>
                ))}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
}

function getNotificationSections(): NotificationSection[] {
  return Object.values(NotificationSection);
}
