import BackButton from "components/BackButton/BackButton";
import { DiscussionType, UserDiscussion } from "components/ChatGptSideBar";
import { DiscussionTypeToColor } from "components/DiscussionComponent/components/DiscussionsFilter";
import { PageContainer } from "components/PageContainer";
import { PageMainHeader } from "components/PageMainHeader";
import { HeaderNamePill } from "components/Pill";
import type { DocumentSchemaType } from "components/ServicePage/components/DocumentsSection";
import DocumentsUploadSection from "components/ServicePage/components/DocumentsSection";
import { useParams } from "react-router-dom";
import { useCarespace } from "../../backend/resources/carespace/carespace";
import type { UserAssessmentChatGptConversation } from "../../backend/resources/chatGptConversation";
import { useConversation } from "../../backend/resources/chatGptConversation";
import { useConversationDocumentsByConversationId } from "../../backend/resources/conversation_document/conversation_document";

export default function ConversationPage() {
  const { conversation_id } = useParams();
  const { data: conversation } = useConversation(conversation_id);

  // Queries
  const { data: carespace } = useCarespace(
    conversation?.carespace_id ?? undefined,
  );
  const { data: documents } =
    useConversationDocumentsByConversationId(conversation_id);

  function getTitle(
    conversation: undefined | null | UserAssessmentChatGptConversation,
  ) {
    if (!conversation) return "";

    if (conversation.type === "General") {
      return "General Discussion";
    }
    if (conversation.type === "Private") {
      return "Private Discussion";
    }
    return "";
  }

  if (!carespace) return null;
  return (
    <PageContainer>
      <BackButton className="mb-2" />
      <div className="flex gap-4 items-center">
        <HeaderNamePill text={carespace?.name} />
        {conversation?.type && (
          <HeaderNamePill
            text={conversation?.type}
            color={DiscussionTypeToColor[conversation?.type]}
          />
        )}
      </div>
      <PageMainHeader text={getTitle(conversation)} />
      <UserDiscussion
        discussionType={DiscussionType.Private}
        hideBorder
        threadId={conversation_id}
      />
      <DocumentsUploadSection
        documents={
          documents?.map((doc: DocumentSchemaType) => ({
            ...doc,
            is_new: false,
          })) ?? []
        }
        conversationId={conversation_id}
        carespaceId={carespace.id}
      />
    </PageContainer>
  );
}
