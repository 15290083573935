import BillingPage from "components/BillingPage/BillingPage";
import ConversationPage from "components/ConversationPage/ConversationPage";
import { CookieUse } from "components/CookieUse/cookieUse";
import DirectoryPage from "components/DirectoryPage/DirectoryPage";
import GeneralDiscussionPage from "components/GeneralDiscussionPage/GeneralDiscussionPage";
import { HomePage } from "components/HomePage";
import PatientSearch from "components/HomePage/SuperSuperUserHome/PatientSearch";
import MyAccountPage from "components/MyAccountPage/MyAccountPage";
import MyCarePage from "components/MyCarePage/MyCarePage";
import { PrivacyPolicyPage } from "components/PrivacyPolicyPage";
import ServicePage from "components/ServicePage/ServicePage";
import ServiceProviderPage from "components/ServiceProviderPage.tsx/ServiceProviderPage";
import SettingsPage from "components/SettingsPage/SettingsPage";
import { TermsOfServicePage } from "components/TermsOfServicePage";
import UpdatesPage from "components/UpdatesPage/UpdatesPage";
import CarespaceViewPage from "features/carespaces/pages/view";
import Login from "features/users/pages/login";
import ResetPassword from "features/users/pages/reset-password";
import UpdatePassword from "features/users/pages/update-password";

import MemberList from "features/members/pages/list";
import NewMember from "features/members/pages/new";
import OrganizationDetails from "features/organizations/pages/details";
import OrganizationList from "features/organizations/pages/list";
import NewOrganization from "features/organizations/pages/new";

import ServiceHub from "features/service-resources/pages/service-hub";

import CarespaceAssessmentPage from "features/carespaces/pages/assessment";
import CarespaceList from "features/carespaces/pages/list";
import CarespacesNew from "features/carespaces/pages/new";

import CareFlow from "features/careflow/pages/view";

import AcceptInvitation from "features/users/pages/accept-invitation";
import { EnrollMfa } from "features/users/pages/enroll_mfa";
import { Layout, Route } from "./constants";
import type { RouteConfig } from "./types";

const routes: RouteConfig[] = [
  {
    path: Route.CARESPACES,
    layout: Layout.PRIVATE,
    element: <CarespaceList />,
  },
  {
    path: Route.SERVICE_HUB,
    layout: Layout.PRIVATE,
    element: <ServiceHub />,
  },
  {
    path: Route.BILLING_PAGE,
    layout: Layout.PRIVATE,
    element: <BillingPage />,
  },
  {
    path: Route.DIRECTORY,
    layout: Layout.PRIVATE,
    element: <DirectoryPage />,
  },
  {
    path: Route.CARESPACE,
    layout: Layout.PRIVATE,
    element: <CarespaceViewPage />,
  },
  {
    path: Route.CARESPACE_ASSESSMENT,
    layout: Layout.PRIVATE,
    element: <CarespaceAssessmentPage />,
  },
  {
    layout: Layout.PRIVATE,
    path: Route.MY_CARE,
    element: <MyCarePage />,
  },
  {
    layout: Layout.PRIVATE,
    path: Route.MY_CARE_ID,
    element: <MyCarePage />,
  },
  {
    layout: Layout.PRIVATE,
    path: Route.GENERAL_DISCUSSION,
    element: <GeneralDiscussionPage />,
  },
  {
    layout: Layout.PRIVATE,
    path: Route.CONVERSATION,
    element: <ConversationPage />,
  },
  {
    layout: Layout.PRIVATE,
    path: Route.FAMILY_HUB,
    element: <UpdatesPage />,
  },
  {
    layout: Layout.PRIVATE,
    path: Route.ADMIN,
    element: <OrganizationDetails />,
  },
  {
    layout: Layout.PRIVATE,
    path: Route.MY_ACCOUNT,
    element: <MyAccountPage />,
  },
  {
    layout: Layout.PRIVATE,
    path: Route.SETTINGS,
    element: <SettingsPage />,
  },
  {
    layout: Layout.PRIVATE,
    path: Route.SERVICE_PROVIDER_PAGE,
    element: <ServiceProviderPage />,
  },
  {
    layout: Layout.PRIVATE,
    path: Route.SERVICE_PAGE,
    element: <ServicePage />,
  },
  {
    layout: Layout.PUBLIC,
    path: Route.ROOT,
    element: <Login />,
  },
  {
    layout: Layout.PUBLIC,
    path: Route.LOGIN,
    element: <Login />,
  },
  {
    layout: Layout.PRIVATE,
    path: Route.HOME,
    element: <HomePage />,
  },
  {
    layout: Layout.PUBLIC,
    path: Route.TERMS_OF_SERVICE,
    element: <TermsOfServicePage />,
  },
  {
    layout: Layout.PUBLIC,
    path: Route.COOKIE_USE,
    element: <CookieUse />,
  },
  {
    layout: Layout.PUBLIC,
    path: Route.PRIVACY_POLICY,
    element: <PrivacyPolicyPage />,
  },
  {
    layout: Layout.PUBLIC,
    path: Route.RESET_PASSWORD,
    element: <ResetPassword />,
  },
  {
    layout: Layout.RESTRICTED,
    path: Route.UPDATE_PASSWORD,
    element: <UpdatePassword />,
  },
  {
    layout: Layout.PRIVATE,
    path: Route.PATIENT_SEARCH,
    element: <PatientSearch />,
  },

  {
    layout: Layout.RESTRICTED,
    path: Route.MFA_ENROLL,
    element: <EnrollMfa />,
  },
  {
    path: Route.ORGANIZATIONS,
    layout: Layout.PRIVATE,
    element: <OrganizationList />,
  },
  {
    path: Route.ORGANIZATIONS_NEW,
    layout: Layout.PRIVATE,
    element: <NewOrganization />,
  },
  {
    path: Route.ADMIN_EDIT,
    layout: Layout.PRIVATE,
    element: <NewOrganization />,
  },
  {
    path: Route.MEMBERS,
    layout: Layout.PRIVATE,
    element: <MemberList />,
  },
  {
    path: Route.MEMBERS_NEW,
    layout: Layout.PRIVATE,
    element: <NewMember />,
  },
  {
    path: Route.MEMBERS_EDIT,
    layout: Layout.PRIVATE,
    element: <NewMember />,
  },
  {
    path: Route.SUPERORG_MEMBERS,
    layout: Layout.PRIVATE,
    element: <MemberList superorgView />,
  },
  {
    path: Route.SUPERORG_MEMBERS_NEW,
    layout: Layout.PRIVATE,
    element: <NewMember superorgView />,
  },
  {
    path: Route.SUPERORG_MEMBERS_EDIT,
    layout: Layout.PRIVATE,
    element: <NewMember superorgView />,
  },
  {
    path: Route.CAREFLOW,
    layout: Layout.PRIVATE,
    element: <CareFlow />,
  },
  {
    path: Route.ACCEPT_INVITATION,
    layout: Layout.RESTRICTED,
    element: <AcceptInvitation />,
  },
  {
    path: Route.CARESPACES_NEW,
    layout: Layout.PRIVATE,
    element: <CarespacesNew />,
  },
];

export default routes;
