import { zodResolver } from "@hookform/resolvers/zod";
import { ownerOptions } from "features/shared/types";
import { useForm } from "react-hook-form";
import { Button } from "shared/ui/button";
import { Combobox } from "shared/ui/combobox";
import { Form, FormField } from "shared/ui/form";
import { Input } from "shared/ui/input";
import { Text } from "shared/ui/text";
import { Textarea } from "shared/ui/textarea";
import { z } from "zod";
import type { ActivityNodeProps } from "../types";
import { IntervalInput } from "./interval-input";

type CareFlowNodeEditorProps = {
  node: ActivityNodeProps;
  onCancel: () => void;
  onSave: (node: ActivityNodeProps) => void;
};

const typeOptions: { value: ActivityNodeProps["type"]; label: string }[] = [
  { value: "onboarding", label: "Onboarding" },
];

const formSchema = z.object({
  title: z.string(),
  description: z.string().optional(),
  owner: z.enum(["carenavigator", "caregiver"]),
  type: z.enum(["onboarding"]),
  deadline: z.string().optional(),
  recurrence: z.string().optional(),
});

type FormSchema = z.infer<typeof formSchema>;

export default function CareFlowNodeEditor(props: CareFlowNodeEditorProps) {
  const form = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: props.node,
  });

  function onSubmit(data: FormSchema) {
    props.onSave({
      ...props.node,
      ...data,
    });
  }

  return (
    <div className="flex flex-col gap-4">
      <Text size="lg">Activity Details</Text>

      <Form form={form} onSubmit={form.handleSubmit(onSubmit)}>
        <div>
          <FormField
            control={form.control}
            name="title"
            label="Title"
            render={({ field }) => (
              <Input
                placeholder="Activity Title"
                className="w-full"
                {...field}
              />
            )}
          />
        </div>

        <div>
          <FormField
            control={form.control}
            name="description"
            label="Description"
            render={({ field }) => (
              <Textarea
                placeholder="Activity Description"
                className="w-full"
                {...field}
              />
            )}
          />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <FormField
              control={form.control}
              name="owner"
              label="Owner"
              render={({ field }) => (
                <Combobox
                  options={ownerOptions}
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
          </div>
          <div>
            <FormField
              control={form.control}
              name="type"
              label="Type"
              render={({ field }) => (
                <Combobox
                  options={typeOptions}
                  value={field.value}
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
          </div>
        </div>

        <IntervalInput
          title="Set deadline"
          value={form.watch("deadline")}
          onChange={(value) => form.setValue("deadline", value)}
        />

        <IntervalInput
          title="Set recurrence"
          value={form.watch("recurrence")}
          onChange={(value) => form.setValue("recurrence", value)}
        />

        <div className="flex space-x-2 mt-10">
          <Button variant="outline" onClick={props.onCancel}>
            Cancel
          </Button>
          <Button type="submit">Save</Button>
        </div>
      </Form>
    </div>
  );
}
