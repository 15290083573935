import type { HeaderGroup, Row } from "@tanstack/react-table";
import { flexRender } from "@tanstack/react-table";

import { cn } from "shared/utils/cn";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "./core";

export interface DisplayTableProps<TData> {
  headerGroups: HeaderGroup<TData>[];
  rows: Row<TData>[];
  colSpan: number;
  hasRowSelection?: boolean;
  onRowClick?: (row: TData) => void;
}

export function DisplayTable<TData>({
  headerGroups,
  rows,
  colSpan,
  hasRowSelection,
  onRowClick,
}: DisplayTableProps<TData>) {
  return (
    <Table>
      <TableHeader>
        {headerGroups.map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <TableHead key={header.id}>
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
              </TableHead>
            ))}
          </TableRow>
        ))}
      </TableHeader>

      <TableBody>
        {rows.length > 0 ? (
          rows.map((row) => (
            <TableRow
              key={row.id}
              {...(hasRowSelection
                ? { "data-state": row.getIsSelected() && "selected" }
                : {})}
            >
              {row.getVisibleCells().map((cell) => (
                <TableCell
                  key={cell.id}
                  onClick={
                    cell.column.columnDef.id !== "row-selection" && onRowClick
                      ? () => onRowClick(row.original)
                      : undefined
                  }
                  className={cn(
                    cell.column.columnDef.id !== "row-selection" && onRowClick
                      ? "cursor-pointer"
                      : "",
                  )}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))
        ) : (
          <TableRow>
            <TableCell colSpan={colSpan} className="text-center">
              No results.
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}
