import { zodResolver } from "@hookform/resolvers/zod";
import * as Sentry from "@sentry/react";
import { supabase } from "clients/supabaseClient";
import { useForm } from "react-hook-form";
import { useToast } from "shared/hooks/use-toast";
import { Button } from "shared/ui/button";
import { Form, FormField } from "shared/ui/form";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSeparator,
  InputOTPSlot,
} from "shared/ui/input-otp";
import { z } from "zod";

const formSchema = z.object({
  otp: z
    .string({ required_error: "OTP is required" })
    .length(6, { message: "OTP must be 6 digits" }),
});

type FormSchema = z.infer<typeof formSchema>;

interface TotpMfaProps {
  onSuccess: () => void;
  factorId: string;
}

export function TotpMfa({ onSuccess, factorId }: TotpMfaProps) {
  const { toast } = useToast();

  const form = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      otp: "",
    },
  });

  const onSubmit = async ({ otp }: FormSchema) =>
    verifyMfa(factorId, otp)
      .then(onSuccess)
      .catch((error) => {
        Sentry.captureException(error);
        toast({
          title: "Sorry, something went wrong",
          description: error.message,
          variant: "destructive",
        });
      });

  return (
    <div className="flex flex-col gap-4 py-4">
      <Form form={form} onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          control={form.control}
          name="otp"
          label="Enter Code"
          render={({ field }) => (
            <InputOTP maxLength={6} {...field}>
              <InputOTPGroup>
                <InputOTPSlot index={0} />
                <InputOTPSlot index={1} />
                <InputOTPSlot index={2} />
              </InputOTPGroup>
              <InputOTPSeparator />
              <InputOTPGroup>
                <InputOTPSlot index={3} />
                <InputOTPSlot index={4} />
                <InputOTPSlot index={5} />
              </InputOTPGroup>
            </InputOTP>
          )}
        />

        <Button
          type="submit"
          disabled={form.formState.isSubmitting}
          isLoading={form.formState.isSubmitting}
        >
          Submit
        </Button>
      </Form>
    </div>
  );
}

const verifyMfa = async (factorId: string, otpCode: string) => {
  const challenge = await supabase.auth.mfa
    .challenge({ factorId })
    .then(({ data, error }) => {
      if (error) throw error;
      return data;
    });

  return supabase.auth.mfa
    .verify({
      factorId,
      challengeId: challenge.id,
      code: otpCode,
    })
    .then(({ data, error }) => {
      if (error) throw error;
      return data;
    });
};
