import type { Edge } from "@xyflow/react";
import { ReactFlowProvider } from "@xyflow/react";
import isEqual from "lodash/isEqual";
import { useEffect, useState } from "react";
import useRequireSuperOrg from "shared/hooks/use-require-super-org";

import CareFlowViewer from "../components/careflow-viewer";
import { useFetchAll, useSave } from "../queries/hooks";
import type { ActivityNodeProps } from "../types";

export default function CareFlow() {
  useRequireSuperOrg();
  const { data, error, isLoading } = useFetchAll();
  const saveData = useSave().mutateAsync;
  const [isDirty, setIsDirty] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    // TODO: This won't work for navigations inside react router
    // to fix this we'll need to use the useBlocker hook from react-router
    // However, we'll need to migrate from a BrowserRouter to a Data Router first
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (!isDirty) return;

      e.preventDefault();
      e.returnValue = "";
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [isDirty]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error || !data) {
    return <div>Error loading data</div>;
  }

  async function onSave(nodes: ActivityNodeProps[], edges: Edge[]) {
    if (!data) {
      return;
    }

    setIsSaving(true);

    // Diff with data.nodes and data.edges
    // Split into create, update, delete
    const existingNodes = data.nodes;
    const existingEdges = data.edges;

    const nodesToBeInserted = nodes.filter(
      (newNode) =>
        !existingNodes.some((existingNode) => existingNode.id === newNode.id),
    );

    const nodesToBeUpdated = nodes.filter((newNode) => {
      const existing = existingNodes.find(
        (existingNode) => existingNode.id === newNode.id,
      );
      return existing && !isEqual(existing, newNode);
    });

    const nodesToBeDeleted = existingNodes.filter(
      (existingNode) =>
        !nodes.some((newNode) => newNode.id === existingNode.id),
    );

    const edgesToBeInserted = edges.filter(
      (newEdge) =>
        !existingEdges.some((existingEdge) => existingEdge.id === newEdge.id),
    );

    const edgesToBeUpdated = edges.filter((newEdge) => {
      const existing = existingEdges.find(
        (existingEdge) => existingEdge.id === newEdge.id,
      );
      return existing && !isEqual(existing, newEdge);
    });

    const edgesToBeDeleted = existingEdges.filter(
      (existingEdge) =>
        !edges.some((newEdge) => newEdge.id === existingEdge.id),
    );

    await saveData({
      nodesToBeUpserted: [...nodesToBeInserted, ...nodesToBeUpdated],
      nodesToBeDeleted,
      edgesToBeUpserted: [...edgesToBeInserted, ...edgesToBeUpdated],
      edgesToBeDeleted,
    });

    setIsSaving(false);
  }

  return (
    <div className="h-full w-full">
      <ReactFlowProvider>
        <CareFlowViewer
          nodes={data.nodes}
          edges={data.edges}
          onSave={onSave}
          isSaving={isSaving}
          isDirty={isDirty}
          setIsDirty={setIsDirty}
        />
      </ReactFlowProvider>
    </div>
  );
}
