import { LoadingSpinner } from "components/LoadingSpinner";
import { Route } from "features/routing/constants";
import { ALL_ORGANIZATIONS } from "features/routing/layouts/components/organization-switcher";
import { useAppNavigate } from "lib/routing";
import capitalize from "lodash/capitalize";
import { Button } from "shared/ui/button";
import { Text } from "shared/ui/text";
import { Title } from "shared/ui/title";
import { useActiveOrganizationId } from "state/organization/organization";
import SectionHeader from "../components/section-header";
import { useFetchOne } from "../queries/hooks";

export default function OrganizationDetailsPage() {
  const activeOrganizationId = useActiveOrganizationId();
  const navigate = useAppNavigate();
  const { data, error, isLoading } = useFetchOne(
    {
      equals: {
        id:
          typeof activeOrganizationId === "string"
            ? activeOrganizationId
            : undefined,
      },
    },
    { enabled: typeof activeOrganizationId === "string" },
  );

  if (activeOrganizationId === ALL_ORGANIZATIONS) {
    return null;
  }

  if (isLoading) {
    return (
      <div className="flex flex-col gap-4 p-6 max-w-2xl">
        <LoadingSpinner />
      </div>
    );
  }

  if (error || !data) {
    return (
      <div className="flex flex-col gap-4 p-6 max-w-2xl">
        <Title className="mb-8">Error</Title>
        <Text className="text-red-500">
          An error occurred while fetching the organization details. Please
          refresh the page.
        </Text>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4 p-6 max-w-2xl">
      <Title>Organization</Title>

      <SectionHeader title="Organization Information" />

      <Title order={5}>Name</Title>
      <Text>{data.name}</Text>

      <Title order={5}>Address</Title>
      <Text>
        {data.address}
        <br />
        {data.city} - {data.state}
        <br />
        {data.zipcode}
      </Text>

      <SectionHeader title="Contact Details" />

      <Title order={5}>Business Contact Phone Number</Title>
      <Text>{data.phone_number_business}</Text>

      <Title order={5}>Clinical Escalation Phone Number</Title>
      <Text>{data.phone_number_clinical}</Text>

      <SectionHeader title="Identification" />

      <Title order={5}>Facility NPI</Title>
      <Text>{data.entity_npi}</Text>

      <Title order={5}>Organization Tax ID Number</Title>
      <Text>{data.finance_tin}</Text>

      <SectionHeader title="Guide and Billing Options" />

      <Title order={5}>Is software only?</Title>
      <Text>{data.software_only ? "Yes" : "No"}</Text>

      <Title order={5}>Is GUIDE Participant?</Title>
      <Text>{data.is_guide_participant ? "Yes" : "No"}</Text>

      <Title order={5}>Is billing delegated to Craniometrix?</Title>
      <Text>{data.billing_delegated ? "Yes" : "No"}</Text>

      <Title order={5}>Billing Company Name</Title>
      <Text>{data.billing_company_name}</Text>

      <SectionHeader title="Banking Details" />

      <Title order={5}>Bank Name</Title>
      <Text>{data.bank_name ?? "N/A"}</Text>

      <Title order={5}>Bank Account Type</Title>
      <Text>
        {data.bank_account_type ? capitalize(data.bank_account_type) : "N/A"}
      </Text>

      <Title order={5}>Bank Account Number</Title>
      <Text>{data.bank_account_number ?? "N/A"}</Text>

      <Title order={5}>Bank Routing Number</Title>
      <Text>{data.bank_routing_number ?? "N/A"}</Text>

      <SectionHeader title="Care Plan Settings" />

      <Title order={5}>Care Plan Destination</Title>
      <Text>{data.care_plan_destination ?? "None"}</Text>

      {data.care_plan_destination === "fax" && (
        <>
          <Title order={5}>Fax Number</Title>
          <Text>{data.fax_number ?? "N/A"}</Text>
        </>
      )}

      {data.care_plan_destination === "api_endpoint" && (
        <>
          <Title order={5}>API Endpoint</Title>
          <Text>{data.api_endpoint_url ?? "N/A"}</Text>

          <Title order={5}>API Key</Title>
          <Text>Hidden</Text>
        </>
      )}

      {data.care_plan_destination === "redox" && (
        <>
          <Title order={5}>Redox Connection Slug</Title>
          <Text>{data.redox_connection_slug ?? "N/A"}</Text>

          <Title order={5}>Redox Connection ID</Title>
          <Text>{data.redox_connection_id ?? "N/A"}</Text>

          <Title order={5}>Redox Organization Name</Title>
          <Text>{data.redox_organization_name ?? "N/A"}</Text>
        </>
      )}

      <Button
        variant="outline"
        className="mt-8"
        onClick={() => navigate({ path: Route.ADMIN_EDIT })}
      >
        Edit Organization
      </Button>
    </div>
  );
}
