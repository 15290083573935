import * as Sentry from "@sentry/react";
import type { Factor } from "@supabase/supabase-js";
import { useMutation, useQuery } from "@tanstack/react-query";
import { supabase } from "clients/supabaseClient";
import { LoadingSpinner } from "components/LoadingSpinner";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useToast } from "shared/hooks/use-toast";
import { Button } from "shared/ui/button";
import { Card } from "shared/ui/card";
import { Dialog, type DialogProps } from "shared/ui/dialog";
import { Text } from "shared/ui/text";
import { Title } from "shared/ui/title";
import { EnrollPhoneMfa } from "../auth/components/enroll_phone_mfa";
import { EnrollTotpMfa } from "../auth/components/enroll_totp_mfa";

// Phone factors have an additional "phone" field not provided in supabase types
type PhoneFactor = Factor & {
  phone: string;
};

export default function MfaSettings() {
  const { toast } = useToast();
  const { data, isLoading, refetch, error } = useFactors();

  useEffect(() => {
    if (!error) return;
    toast({
      description: error.message,
      variant: "destructive",
    });
  }, [error]);

  const onEnrollSuccess = useCallback(() => {
    toast({
      description: "MFA factor is successfully added",
      variant: "success",
    });
    refetch();
  }, [toast, refetch]);

  const onResetSuccess = useCallback(() => {
    toast({
      description: "MFA factor is successfully reset",
      variant: "success",
    });
    refetch();
  }, [toast, refetch]);

  if (isLoading) {
    return <LoadingSpinner className="w-6 h-6" />;
  }

  return (
    <div className="flex flex-col gap-4">
      <Title order={2}>Manage your MFA Factors</Title>
      <Text>
        You must be enrolled in an MFA factor. If you are not, you'll be
        prompted to the next time you log in.
      </Text>

      {!data?.totpFactor && !data?.phoneFactor && (
        <Text>No MFA factors enrolled currently.</Text>
      )}

      <FactorInfo
        factor={data?.totpFactor}
        factorType="totp"
        onEnrollSuccess={onEnrollSuccess}
        onResetSuccess={onResetSuccess}
      />

      <FactorInfo
        factor={data?.phoneFactor}
        factorType="phone"
        onEnrollSuccess={onEnrollSuccess}
        onResetSuccess={onResetSuccess}
      />
    </div>
  );
}

type FactorInfoProps = {
  factor?: Factor;
  factorType: Factor["factor_type"];
  onEnrollSuccess: () => void;
  onResetSuccess: () => void;
};

const FactorInfo = ({
  factor,
  factorType,
  onEnrollSuccess,
  onResetSuccess,
}: FactorInfoProps) => {
  const { mutate, isPending } = useResetFactor(factor?.id);
  const [enrollModalOpen, setEnrollModalOpen] = useState(false);

  const title = useMemo(() => {
    if (factorType === "totp") return "Authenticator App";
    if (factor) return `Phone number (${(factor as PhoneFactor).phone})`;
    return "Phone number";
  }, [factor, factorType]);

  const onButtonClick = useCallback(() => {
    if (factor)
      return mutate(undefined, {
        onSuccess: () => {
          setEnrollModalOpen(false);
          onResetSuccess();
        },
      });
    return setEnrollModalOpen(true);
  }, [factor, mutate, onResetSuccess]);

  return (
    <Card
      header={<Title order={3}>{title}</Title>}
      contentClassName="grid grid-cols-3 gap-1"
    >
      <div className={`col-span-1 ${factor ? "text-green-600" : ""}`}>
        {factor ? "Connected" : "Not connected"}
      </div>
      <Button
        className="col-span-1"
        variant={factor ? "outline" : "default"}
        isLoading={isPending}
        onClick={onButtonClick}
      >
        {factor ? "Reset" : "Connect"}
      </Button>

      <EnrollMfaModal
        open={enrollModalOpen}
        onOpenChange={(value) => setEnrollModalOpen(value)}
        title="Enroll MFA"
        factorType={factorType}
        onSuccess={() => {
          onEnrollSuccess();
          setEnrollModalOpen(false);
        }}
      />
    </Card>
  );
};

type EnrollMfaModalProps = Omit<DialogProps, "content" | "children"> & {
  factorType: Factor["factor_type"];
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onSuccess: () => void;
};

const EnrollMfaModal = ({
  factorType,
  open,
  onOpenChange,
  onSuccess,
  ...modalProps
}: EnrollMfaModalProps) => {
  return (
    <Dialog
      {...modalProps}
      open={open}
      onOpenChange={onOpenChange}
      content={
        factorType === "totp" ? (
          <EnrollTotpMfa onSuccess={onSuccess} />
        ) : (
          <EnrollPhoneMfa onSuccess={onSuccess} />
        )
      }
    />
  );
};

const useFactors = () =>
  useQuery({
    queryKey: ["mfaFactors"],
    queryFn: () =>
      supabase.auth.mfa.listFactors().then(({ data, error }) => {
        if (error) {
          Sentry.captureException(error);
          throw error;
        }

        return {
          phoneFactor: data.phone.length > 0 ? data.phone[0] : undefined,
          totpFactor: data.totp.length > 0 ? data.totp[0] : undefined,
        };
      }),
  });

const useResetFactor = (factorId?: string) =>
  useMutation({
    mutationKey: ["mfaFactors", factorId],
    mutationFn: () => {
      if (!factorId) throw new Error("factorId not provided");

      return supabase.auth.mfa
        .unenroll({ factorId })
        .then(({ data, error }) => {
          if (error) {
            Sentry.captureException(error);
            throw error;
          }

          return data;
        });
    },
  });
