import AlfredIcon from "components/Alfred/Alfred";
import type { ChatGptMessage } from "../../../../backend/resources/chatGptMessage";

interface Props {
  message: ChatGptMessage;
}

export function SystemMessage({ message }: Props) {
  return (
    <div className="flex min-w-0 gap-3">
      <AlfredIcon className="flex-shrink-0 w-8 h-8 mt-1" />
      <p
        className="font-light text-gray-700"
        dangerouslySetInnerHTML={{
          __html: message.content,
        }}
      />
    </div>
  );
}
