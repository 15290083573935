import { zodResolver } from "@hookform/resolvers/zod";
import * as Sentry from "@sentry/react";

import { supabase } from "clients/supabaseClient";
import { Route } from "features/routing/constants";
import { useAppNavigate } from "lib/routing";
import { useForm } from "react-hook-form";
import { useToast } from "shared/hooks/use-toast";
import { Button } from "shared/ui/button";
import { Card } from "shared/ui/card";
import { Form, FormField } from "shared/ui/form";
import { Input } from "shared/ui/input";
import { Text } from "shared/ui/text";
import { z } from "zod";
import { useAuthUser } from "../auth";

const formSchema = z.object({
  password: z
    .string()
    .min(10, { message: "Password must be at least 8 characters" }),
});

type FormSchema = z.infer<typeof formSchema>;

function isErrorWithMessage(error: unknown): error is { message: string } {
  return (
    error !== null &&
    typeof error === "object" &&
    "message" in error &&
    typeof error.message === "string"
  );
}

export default function AcceptInvitation() {
  const { authUser } = useAuthUser();
  const navigate = useAppNavigate();
  const form = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      password: "",
    },
  });
  const { toast } = useToast();
  const queryParams = new URLSearchParams(window.location.search);
  const invitationId = queryParams.get("invitation_id");
  const organizationId = queryParams.get("organization_id");

  async function onSubmit(data: FormSchema) {
    try {
      if (!invitationId || !authUser) {
        throw new Error("Invalid invitation link");
      }

      const { error: updatePasswordError } = await supabase.auth.updateUser({
        password: data.password,
      });

      if (updatePasswordError) {
        throw updatePasswordError;
      }

      if (organizationId) {
        const { error } = await supabase.rpc("accept_organization_invitation", {
          invitation_id: invitationId,
        });

        if (error) {
          throw error;
        }
      } else {
        const { error } = await supabase.rpc("accept_carespace_invitation", {
          invitation_id: invitationId,
        });

        if (error) {
          throw error;
        }
      }

      navigate({
        path: Route.LOGIN,
      });
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
      toast({
        title: "Error",
        description: isErrorWithMessage(error) ? error.message : "",
        variant: "destructive",
      });
    }
  }

  if (!invitationId) {
    return <div>Invalid invitation link</div>;
  }

  return (
    <div className="flex items-center justify-center w-full h-full">
      <Card
        title={`You've been invited to join ${
          organizationId ? "an organization" : "a caregiving team"
        }`}
        className="w-[450px]"
      >
        <Form form={form} onSubmit={form.handleSubmit(onSubmit)}>
          <FormField
            control={form.control}
            name="password"
            label="Please create a password"
            className="mt-4"
            render={({ field }) => (
              <Input type="password" placeholder="Password" {...field} />
            )}
          />

          <Button
            type="submit"
            className="w-full mb-4"
            isLoading={form.formState.isSubmitting}
          >
            Submit
          </Button>
        </Form>
        <Text className="transition-colors [&>a]:text-brand-orange [&>a]:hover:brightness-110">
          By signing up, you agree to the{" "}
          <a href={Route.TERMS_OF_SERVICE} target="_blank" rel="noreferrer">
            Terms of Service
          </a>{" "}
          and{" "}
          <a href={Route.PRIVACY_POLICY} target="_blank" rel="noreferrer">
            Privacy Policy
          </a>{" "}
          , including{" "}
          <a href={Route.COOKIE_USE} target="_blank" rel="noreferrer">
            Cookie Use
          </a>
          .
        </Text>
      </Card>
    </div>
  );
}
