import { useMutation } from "@tanstack/react-query";
import { supabase } from "clients/supabaseClient";
import { useState } from "react";
import { useToast } from "shared/hooks/use-toast";
import { Button } from "shared/ui/button";
import { Text } from "shared/ui/text";
import { v4 as uuidv4 } from "uuid";
import { TotpMfa } from "./totp_mfa";

interface EnrollTotpMfaProps {
  onSuccess: () => void;
}

export function EnrollTotpMfa({ onSuccess }: EnrollTotpMfaProps) {
  const { toast } = useToast();
  const [factorId, setFactorId] = useState<string>();
  const [enrollData, setEnrollData] = useState<{
    qr_code: string;
    secret: string;
  }>();
  const { mutate, isPending } = useEnroll();

  const onEnrollStart = () =>
    mutate(undefined, {
      onSuccess: (data) => {
        setFactorId(data.id);
        setEnrollData(data.totp);
      },
      onError: (error) => {
        toast({ description: error.message, variant: "destructive" });
      },
    });

  return (
    <div className="flex flex-col gap-4 py-4">
      {!factorId && (
        <Button onClick={onEnrollStart} isLoading={isPending}>
          Get QR code
        </Button>
      )}
      {enrollData && (
        <div className="flex flex-col gap-4 items-center justify-center bg-neutral-100 rounded-xl p-4">
          <div className="border rounded-md border-neutral-900">
            <img src={enrollData.qr_code} alt="QR Code" className="w-60 h-60" />
          </div>

          <div className="flex flex-col items-center justify-center">
            <Text className="text-sm">
              If you are unable to scan the QRCode, use the secret below
              instead:
            </Text>
            <Text id="totp-secret" className="text-sm">
              <code>{enrollData.secret}</code>
            </Text>
          </div>
        </div>
      )}
      {factorId && <TotpMfa factorId={factorId} onSuccess={onSuccess} />}
    </div>
  );
}

const useEnroll = () =>
  useMutation({
    mutationFn: () =>
      supabase.auth.mfa
        .enroll({
          factorType: "totp",
          friendlyName: `totp-${uuidv4()}`,
        })
        .then(({ data, error }) => {
          if (error) throw error;
          return data;
        }),
  });
