import { Calendar } from "lucide-react";
import { useState } from "react";
import { Button } from "shared/ui/button";
import { Dialog } from "shared/ui/dialog";
import { Text } from "shared/ui/text";
import { useUpdateActivityStatus } from "../queries/hooks";
import type { Activity } from "../types";
import ActivityCard from "./activity";

type ScheduledActivityListProps = {
  carespaceId: string;
  activities: Activity[];
  isLoading: boolean;
};

export default function ScheduledActivityList({
  carespaceId,
  activities,
  isLoading,
}: ScheduledActivityListProps) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const updateActivityStatus = useUpdateActivityStatus(carespaceId).mutateAsync;

  if (isLoading) {
    return null;
  }

  if (activities.length === 0) {
    return null;
  }

  return (
    <>
      <Button
        variant="link"
        onClick={() => setDialogOpen(true)}
        className="text-primary flex items-center gap-2 my-2 p-0 pl-0 justify-start w-full"
      >
        <Calendar className="w-4 h-4 mr-1" />
        <Text>
          {activities.length} scheduled{" "}
          {activities.length === 1 ? "activity" : "activities"}
        </Text>
      </Button>

      <Dialog
        open={dialogOpen}
        onOpenChange={setDialogOpen}
        title="Scheduled Activities"
        content={
          <div className="flex flex-col gap-3 w-full max-h-[400px] overflow-y-auto">
            {activities.map((activity) => (
              <ActivityCard
                key={activity.id}
                {...activity}
                onComplete={() =>
                  updateActivityStatus({
                    activity_id: activity.id,
                    status: "completed",
                  })
                }
                onSkip={() =>
                  updateActivityStatus({
                    activity_id: activity.id,
                    status: "skipped",
                  })
                }
              />
            ))}
          </div>
        }
      />
    </>
  );
}
