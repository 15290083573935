import type { Patient } from "../../../../backend/resources/patient";

export const buildPAFFileName = (patient: Patient | null) => {
  if (!patient) {
    return `paf_submission_${new Date().toLocaleString()}`;
  }
  return `${patient?.first_name}_${
    patient?.last_name
  }_paf_submission_${new Date().toLocaleString()}`;
};
