import { zodResolver } from "@hookform/resolvers/zod";
import * as Sentry from "@sentry/react";
import { NotebookPen } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useToast } from "shared/hooks/use-toast";
import { Button } from "shared/ui/button";
import { Dialog, DialogClose, DialogFooter } from "shared/ui/dialog";
import { Form, FormField } from "shared/ui/form";
import { Input } from "shared/ui/input";
import { Textarea } from "shared/ui/textarea";

const formSchema = z.object({
  title: z.string().min(1),
  description: z.string().optional(),
});

type NewNoteSchema = z.infer<typeof formSchema>;
type NewNoteProps = {
  onSave: (
    note: NewNoteSchema & {
      owner: "carenavigator";
      completed_at: string;
      type: "note";
    },
  ) => Promise<void>;
};

export default function NewNote({ onSave }: NewNoteProps) {
  const [open, setOpen] = useState(false);
  const { toast } = useToast();

  const form = useForm<NewNoteSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      title: "",
      description: "",
    },
  });

  async function onSubmit(data: NewNoteSchema) {
    try {
      await onSave({
        ...data,
        owner: "carenavigator",
        completed_at: new Date().toISOString(),
        type: "note",
      });
      form.reset();
      setOpen(false);
      toast({
        title: "Note added",
        description: "Your note has been added to the activity list",
      });
    } catch (error) {
      Sentry.captureException(error);
      toast({
        variant: "destructive",
        title: "Failed to save note",
        description: "Please try again",
      });
    }
  }

  const content = (
    <Form form={form} onSubmit={form.handleSubmit(onSubmit)}>
      <>
        <FormField
          control={form.control}
          name="title"
          label="Title"
          render={({ field }) => (
            <Input placeholder="Note Title" className="w-full" {...field} />
          )}
        />

        <FormField
          control={form.control}
          name="description"
          label="Description"
          render={({ field }) => (
            <Textarea
              placeholder="Note Description"
              className="w-full"
              {...field}
            />
          )}
        />
      </>
      <DialogFooter className="sm:justify-start">
        <DialogClose asChild>
          <Button type="button" variant="secondary">
            Cancel
          </Button>
        </DialogClose>
        <Button type="submit">Save</Button>
      </DialogFooter>
    </Form>
  );

  return (
    <Dialog
      open={open}
      onOpenChange={setOpen}
      content={content}
      title="Add Note"
    >
      <Button variant="outline">
        <NotebookPen className="w-4 h-4 mr-2" />
        Add Note
      </Button>
    </Dialog>
  );
}
