import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { supabase } from "clients/supabaseClient";

import { Button } from "shared/ui/button";
import { Card } from "shared/ui/card";
import { Form, FormField } from "shared/ui/form";
import { Input } from "shared/ui/input";

import { useToast } from "shared/hooks/use-toast";

import { useAuthUser } from "../auth";

const formSchema = z.object({
  password: z.string().min(1, { message: "Password is required" }),
});

type FormSchema = z.infer<typeof formSchema>;

export default function UpdatePassword() {
  const { logout } = useAuthUser();
  const form = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      password: "",
    },
  });
  const { toast } = useToast();

  const onSubmit = async ({ password }: FormSchema) => {
    const { error } = await supabase.auth.updateUser({ password });

    if (error) {
      toast({
        description: error.message,
        variant: "destructive",
      });
    } else {
      toast({
        title: "Success!",
        description: "Sign in with your new password",
        variant: "success",
      });

      logout();
    }
  };

  return (
    <div className="flex items-center justify-center w-full h-full">
      <Card title="Enter new password" className="w-[450px]">
        <Form form={form} onSubmit={form.handleSubmit(onSubmit)}>
          <FormField
            control={form.control}
            name="password"
            label="Password"
            render={({ field }) => <Input type="password" {...field} />}
          />

          <Button
            type="submit"
            className="w-full"
            isLoading={form.formState.isSubmitting}
          >
            Update password
          </Button>
        </Form>
      </Card>
    </div>
  );
}
