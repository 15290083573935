import MoreInfoIcon from "assets/modal-info.svg?react";
import { formatDistanceToNow } from "date-fns";
import { Arrow } from "icons/Arrow";
import { useState } from "react";
import { NotificationType, sendNotification } from "../../backend/functions";
import { useUserUpdateDiscussion } from "../../backend/resources/chatGptConversation";
import {
  useChatGptMessages,
  useMutateChatGptMessages,
} from "../../backend/resources/chatGptMessage";
import { useServiceEngagementById } from "../../backend/resources/services/serviceEngagement";

import { ChatGptSideBarInput } from "components/ChatGptSideBar/Input";
import { PrivateDiscussion } from "components/ChatGptSideBar/Pages/PrivateDiscussion";
import { ServiceDiscussion } from "components/ChatGptSideBar/Pages/ServiceDiscussion";
import { ServiceExternalDiscussion } from "components/ChatGptSideBar/Pages/ServiceExternalDiscussion";
import { ServiceRequestDiscussion } from "components/ChatGptSideBar/Pages/ServiceRequestDiscussion";
import { ServiceRequestExternalDiscussion } from "components/ChatGptSideBar/Pages/ServiceRequestExternalDiscussion";
import { CarespaceDiscussion } from "components/ChatGptSideBar/Pages/UserCarespaceDiscussion";
import { UserUpdateDiscussion } from "components/ChatGptSideBar/Pages/UserUpdateDiscussion";
import { useAuthUser } from "features/users/auth";
import ReactMarkdown from "react-markdown";
import { EmailForm } from "shared/forms/ServiceResourceEmailForm";
import { ResponsiveModal } from "shared/ui/responsive-modal";
import type { SideBarPageType } from "state/gpt";
import { useGptStore } from "state/gpt";
import { useActiveCarespace } from "../../backend/resources/carespace/carespace";

import { useFetchOne } from "features/users/queries/hooks";

export enum DiscussionType {
  UserUpdate = 0,
  Carespace = 1,
  ServiceRequest = 2,
  ServiceRequestExternal = 3,
  Service = 4,
  ServiceExternal = 5,
  Private = 6,
}

type Props = {
  hideBorder?: boolean;
};

const useConversationId = (
  discussionType: DiscussionType,
  threadId: string | undefined,
) => {
  const { data: userUpdateDiscussion } = useUserUpdateDiscussion(threadId);
  const { data: carespace } = useActiveCarespace();
  const { data: serviceEngagementDiscussion } =
    useServiceEngagementById(threadId);
  switch (discussionType) {
    case DiscussionType.UserUpdate: {
      return userUpdateDiscussion?.conversation_id;
    }
    case DiscussionType.Carespace: {
      return carespace?.conversation_id;
    }
    case DiscussionType.Service: {
      return serviceEngagementDiscussion?.conversation_id;
    }
    case DiscussionType.ServiceExternal: {
      return serviceEngagementDiscussion?.external_conversation_id;
    }
    case DiscussionType.Private: {
      return threadId;
    }
    default:
  }
};

export function CollapsedUserDiscussion({
  discussionType,
  threadId,
  title,
}: Props & {
  discussionType: DiscussionType;
  threadId: string | undefined;
  title: string;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const sidebarPageTypeMapping: Record<DiscussionType, SideBarPageType> = {
    [DiscussionType.UserUpdate]: "userUpdate",
    [DiscussionType.Carespace]: "carespace",
    [DiscussionType.ServiceRequest]: "serviceRequest",
    [DiscussionType.ServiceRequestExternal]: "serviceRequestExternal",
    [DiscussionType.Service]: "service",
    [DiscussionType.ServiceExternal]: "serviceExternal",
    [DiscussionType.Private]: "private",
  };

  const pageType = sidebarPageTypeMapping[discussionType];
  const { isLoadingMessages, messages } = useChatGptMessages(
    threadId,
    pageType,
  );
  const latestMessage = messages?.[messages.length - 1];
  const { data: messageAuthorUser } = useFetchOne(
    {
      equals: { id: latestMessage?.user_id || undefined },
    },
    { enabled: !!latestMessage?.user_id },
  );

  return (
    <>
      <ResponsiveModal
        isOpen={isOpen}
        title={title}
        closeText="Close"
        onClose={() => setIsOpen(false)}
        fixedHeight="h-[90vh]"
      >
        <div className="flex flex-col justify-end h-[60vh]">
          <UserDiscussion discussionType={discussionType} threadId={threadId} />
        </div>
      </ResponsiveModal>
      <div
        className="flex flex-col gap-2 cursor-pointer"
        onClick={() => setIsOpen(true)}
      >
        <p className="text-lg">{title}</p>
        <div className="grid grid-cols-[5fr,2fr,2fr]">
          <p>Latest Message</p>
          <p>From</p>
          <p>Sent</p>

          {latestMessage && !isLoadingMessages ? (
            <>
              <p
                style={{
                  fontWeight: "normal",
                }}
              >
                <ReactMarkdown
                  components={{
                    a: ({ node, children, ...props }) => (
                      <a
                        {...props}
                        style={{ textDecoration: "underline" }}
                        target="_blank"
                      >
                        {children}
                      </a>
                    ),
                  }}
                >
                  {latestMessage?.content}
                </ReactMarkdown>
              </p>
              <p
                style={{
                  fontWeight: "normal",
                }}
              >
                {messageAuthorUser?.first_name} {messageAuthorUser?.last_name}
              </p>
              <p
                style={{
                  fontWeight: "normal",
                }}
              >
                {latestMessage?.created_at
                  ? formatDistanceToNow(new Date(latestMessage.created_at))
                  : ""}
              </p>
            </>
          ) : (
            <p className="text-sm">
              No messages yet. Click to start discussion.
            </p>
          )}
        </div>
      </div>
    </>
  );
}

const TITLE_EXTERNAL_DISCUSSION =
  "External Discussion (includes external provider)";

export function ProviderEmailForm({ resourceId }: { resourceId: string }) {
  return (
    <div className="bg-[#D2ECFF] p-5 rounded-md">
      <p className="text-lg mb-2 ">{TITLE_EXTERNAL_DISCUSSION}</p>
      <div className="flex gap-5 items-center ">
        <MoreInfoIcon />
        <p className=" text-sm">
          In order to send a message to this provider, their email address must
          be added to their profile. After you add it, all messages here will be
          sent to that provider via email.
        </p>
      </div>
      <EmailForm service_resource_id={resourceId} />
    </div>
  );
}

export function UserDiscussion({
  discussionType,
  hideBorder,
  threadId,
}: Props & { discussionType: DiscussionType; threadId: string | undefined }) {
  const { authUser } = useAuthUser();
  const setUserMessage = useGptStore((state) => state.setUserMessage);
  const currentConversationId = useConversationId(discussionType, threadId);

  const chatGptMessagesMutation = useMutateChatGptMessages();

  async function persistUserMessage(userMessage: string) {
    if (currentConversationId && userMessage && threadId) {
      const persistedMessage = await chatGptMessagesMutation.mutateAsync({
        message: {
          chat_gpt_conversation_id: currentConversationId,
          content: userMessage,
          role: "user",
          send_to_chat_gpt: true,
          user_id: authUser?.id,
        },
        threadId,
      });
      setUserMessage("");

      const lastMessage = persistedMessage[persistedMessage.length - 1];
      switch (discussionType) {
        case DiscussionType.UserUpdate: {
          sendNotification(
            lastMessage.id,
            NotificationType.MESSAGE_POSTED_IN_HUB,
          );
          break;
        }
        case DiscussionType.Carespace: {
          sendNotification(
            lastMessage.id,
            NotificationType.MESSAGE_POSTED_IN_CARESPACE,
          );
          break;
        }
        case DiscussionType.ServiceExternal: {
          sendNotification(lastMessage.id, NotificationType.EXTERNAL_SERVICE);
          break;
        }
        case DiscussionType.ServiceRequestExternal: {
          sendNotification(
            lastMessage.id,
            NotificationType.EXTERNAL_SERVICE_REQUEST,
          );
          break;
        }
        case DiscussionType.Private: {
          sendNotification(
            lastMessage.id,
            NotificationType.EXTERNAL_DIRECT_MESSAGE,
          );
          break;
        }
      }
    }
  }

  return (
    <div
      style={{
        height: "90%",
      }}
      className={`"w-full h-full flex flex-col max-h-full transition-all
      ${hideBorder ? "" : "border-l border-neutral-200 drop-shadow-sm"}
       bg-white shrink-0`}
    >
      <div className="flex-grow overflow-y-auto">
        {renderUserDiscussion(discussionType)}
      </div>
      <ChatGptSideBarInput
        closeEventSource={() => {}}
        persistMessage={() => {}}
        persistUserMessage={persistUserMessage}
        isDiscussion={true}
        inputPlaceholderText="Type Here"
      />
    </div>
  );
}

function renderUserDiscussion(discussionType: DiscussionType) {
  const discussionTypeComponents = {
    [DiscussionType.UserUpdate]: <UserUpdateDiscussion />,
    [DiscussionType.Carespace]: <CarespaceDiscussion />,
    [DiscussionType.ServiceRequest]: <ServiceRequestDiscussion />,
    [DiscussionType.Service]: <ServiceDiscussion />,
    [DiscussionType.ServiceExternal]: <ServiceExternalDiscussion />,
    [DiscussionType.ServiceRequestExternal]: (
      <ServiceRequestExternalDiscussion />
    ),
    [DiscussionType.Private]: <PrivateDiscussion />,
  };
  return discussionTypeComponents[discussionType];
}

function CollapseButton() {
  const isOpen = useGptStore((state) => state.isOpen);
  const setIsOpen = useGptStore((state) => state.setIsOpen);

  return (
    <button
      className="hover:bg-gray-100 flex items-center justify-center top-9 w-6 h-6  rounded-full border border-neutral-200 bg-white shrink-0"
      onClick={() => {
        setIsOpen(!isOpen);
      }}
    >
      <Arrow
        className={`w-2 h-2 ${
          isOpen ? "rotate-90" : "-rotate-90"
        } transition-all fill-brand-orange`}
      />
    </button>
  );
}
