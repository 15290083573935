import { Route } from "features/routing/constants";
import { Lock } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { Button } from "shared/ui/button";
import { Card } from "shared/ui/card";
import { Tabs } from "shared/ui/tabs";
import { Text } from "shared/ui/text";
import { useAuthUser } from "../auth";
import { EnrollPhoneMfa } from "../auth/components/enroll_phone_mfa";
import { EnrollTotpMfa } from "../auth/components/enroll_totp_mfa";

export const EnrollMfa = () => {
  const { logout } = useAuthUser();
  const navigate = useNavigate();
  const onSuccess = () => navigate(Route.HOME);

  return (
    <div className="flex items-center justify-center w-full h-full">
      <Card
        icon={<Lock />}
        title="Secure Your Account"
        className="w-[450px] flex flex-col"
      >
        <div className="flex flex-col gap-5">
          <Text className="text-sm">
            Multi-Factor Authentication (MFA) adds an extra layer of security to
            protect your account. Even if someone gets your password, they won’t
            be able to access your account without this extra step.
          </Text>
          <Text className="text-sm">
            Using an authenticator app (e.g., Google Authenticator) is more
            secure than an SMS code, as it is less vulnerable to interception or
            SIM-swapping attacks.
          </Text>
          <Tabs
            defaultValue="totp"
            items={[
              {
                value: "totp",
                label: "Authenticator App",
                content: <EnrollTotpMfa onSuccess={onSuccess} />,
              },
              {
                value: "phone",
                label: "SMS Verification",
                content: <EnrollPhoneMfa onSuccess={onSuccess} />,
              },
            ]}
          />
        </div>

        <Button variant="outline" className="w-full" onClick={logout}>
          Back to login
        </Button>
      </Card>
    </div>
  );
};
