interface Props {
  className?: string;
}

export function LoadingSpinner({ className }: Props) {
  return (
    <svg className={`infinite-rotate ${className}`} viewBox="0 0 50 50">
      <title>Loading spinner</title>
      <circle
        className="animate-spinner"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        stroke="lightgray"
        strokeLinecap="round"
        strokeWidth="5"
      />
    </svg>
  );
}
