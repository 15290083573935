import "components/TypingIndicator/typingIndicator.css";

export function TypingIndicator() {
  return (
    <div className="typing w-fit">
      <div className="typing__dot" />
      <div className="typing__dot" />
      <div className="typing__dot" />
    </div>
  );
}
