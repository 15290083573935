import { zodResolver } from "@hookform/resolvers/zod";
import * as Sentry from "@sentry/react";
import { Route } from "features/routing/constants";
import { useAuthUser } from "features/users/auth";
import { useAppNavigate } from "lib/routing";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams, useSearchParams } from "react-router-dom";
import { useToast } from "shared/hooks/use-toast";
import { Button } from "shared/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "shared/ui/form";
import { RadioGroup, RadioGroupItem } from "shared/ui/radio-group";
import { Text } from "shared/ui/text";
import { Title } from "shared/ui/title";
import { z } from "zod";
import {
  useCreateAssessment,
  useFetchOne,
  useFetchOneAssessment,
} from "../queries/hooks";
import type { AssessmentTypes } from "../types";

const formSchema = z.object({
  answers: z.array(z.number().int().nonnegative()),
});

type FormSchema = z.infer<typeof formSchema>;

function isScalarQuestion(
  question: unknown,
): question is { options: string[]; scale: number } {
  return (
    typeof question === "object" &&
    question !== null &&
    "options" in question &&
    "scale" in question
  );
}

function numberOptions(scale: number, options: string[]) {
  // Calculate the starting value based on scale and number of options
  const start = scale - options.length + 1;

  return options.map((option, index) => ({
    value: start + index,
    label: option,
  }));
}

const isValidAssessmentType = (slug: string): slug is AssessmentTypes =>
  ["ZARIT", "PROMIS"].includes(slug);

export default function CarespaceAssessmentPage() {
  const { carespaceId } = useParams();
  const [searchParams] = useSearchParams();
  const assessmentSlug = searchParams.get("assessment_slug");
  const { data: carespace, isLoading } = useFetchOne({
    equals: { id: carespaceId },
  });
  const { data: assessment, isLoading: isLoadingAssessment } =
    useFetchOneAssessment(
      {
        equals: {
          slug: assessmentSlug ?? undefined,
        },
      },
      {
        enabled: !!assessmentSlug,
      },
    );
  const { toast } = useToast();
  const navigate = useAppNavigate();
  const createAssessment = useCreateAssessment().mutateAsync;
  const { authUser } = useAuthUser();

  const form = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      answers: assessment?.assessment_question.map(() => undefined),
    },
  });

  useEffect(() => {
    form.reset({
      answers: assessment?.assessment_question.map(() => undefined),
    });
  }, [assessment, form]);

  async function handleFormSubmit(data: FormSchema) {
    if (!authUser) {
      const error = new Error("User not authenticated");
      Sentry.captureException(error);
      throw error;
    }

    if (!assessment) {
      const error = new Error("Assessment not found");
      Sentry.captureException(error);
      throw error;
    }

    if (!carespaceId) {
      const error = new Error("Carespace ID not found");
      Sentry.captureException(error);
      throw error;
    }

    try {
      const slug = isValidAssessmentType(assessment.slug)
        ? assessment.slug
        : undefined;
      await createAssessment({
        slug,
        assessment: {
          patient_id:
            assessment.subject === "patient"
              ? carespace?.patient?.id
              : undefined,
          subject_caregiver_id:
            assessment.subject === "caregiver"
              ? carespace?.caregiver?.user_id
              : undefined,
          user_id: authUser.id,
          carespace_id: carespaceId,
          assessment_id: assessment.id,
          completed_at: new Date().toISOString(),
          status: "completed",
        },
        answers: data.answers.map((answer, index) => ({
          assessment_question_id: assessment?.assessment_question[index].id,
          answer,
        })),
      });
      toast({
        title: "Assessment saved",
        description: "Your assessment has been saved successfully.",
        variant: "success",
      });
      navigate({
        path: Route.CARESPACE,
        params: { carespaceId },
      });
    } catch (error) {
      Sentry.captureException(error);
      toast({
        title: "Failed to save assessment",
        description:
          error instanceof Error ? error.message : "An error occurred",
        variant: "destructive",
      });
    }
  }

  if (!assessmentSlug) {
    // TODO: list available assessments
    return null;
  }

  if (isLoading || isLoadingAssessment) {
    return <div>Loading...</div>;
  }

  if (!carespace) {
    return <div>Carespace not found</div>;
  }

  if (!carespace.patient) {
    return <div>Patient not found</div>;
  }

  if (!assessment) {
    return <div>Assessment not found</div>;
  }

  return (
    <div className="flex flex-col gap-8 p-6 max-w-2xl">
      <Title>{assessment.name}</Title>
      <Text className="text-neutral-600">{assessment.instructions}</Text>

      <Form
        form={form}
        onSubmit={form.handleSubmit(handleFormSubmit)}
        className="gap-8"
      >
        {assessment.assessment_question.map((question, index) => (
          <FormField
            key={question.id}
            control={form.control}
            name={`answers.${index}`}
            label={`${question.question_number}. ${question.title}`}
            labelClassName="text-lg"
            render={({ field }) => {
              const data = question.raw_data;

              if (isScalarQuestion(data)) {
                return (
                  <RadioGroup
                    value={
                      typeof field.value === "number"
                        ? field.value.toString()
                        : ""
                    }
                    onValueChange={(value) =>
                      field.onChange(Number.parseInt(value))
                    }
                    className="flex flex-col space-y-1 text-neutral-600"
                  >
                    {numberOptions(data.scale, data.options).map(
                      ({ label, value }) => (
                        <FormItem
                          key={label}
                          className="flex items-center space-x-3 space-y-0"
                        >
                          <FormControl>
                            <RadioGroupItem value={value.toString()} />
                          </FormControl>
                          <FormLabel>{label}</FormLabel>
                        </FormItem>
                      ),
                    )}
                  </RadioGroup>
                );
              }

              toast({
                title: "Unsupported question type",
                description: "Please contact support",
                variant: "destructive",
              });
              throw new Error("Unsupported question type");
            }}
          />
        ))}

        <Button type="submit" isLoading={form.formState.isSubmitting}>
          Submit
        </Button>
      </Form>
    </div>
  );
}
