import { format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";
import * as React from "react";

import { Button } from "shared/ui/button";
import { Calendar } from "shared/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "shared/ui/popover";

import { cn } from "shared/utils/cn";

import useControlledProps, {
  type ControlledProps,
} from "shared/hooks/use-controlled-props";

interface DatePickerProps extends ControlledProps<Date> {
  className?: string;
  id?: string;
  disabled?: boolean;
  placeholder?: string;
  max?: Date;
  min?: Date;
}

function formatDisabledDays(min?: Date, max?: Date) {
  if (min && max) {
    return {
      before: min,
      after: max,
    };
  }

  if (min) {
    return {
      before: min,
    };
  }

  if (max) {
    return {
      after: max,
    };
  }

  return undefined;
}

const DatePicker = React.forwardRef<
  React.ElementRef<typeof Button>,
  DatePickerProps
>(
  (
    {
      className,
      id,
      max,
      min,
      disabled,
      placeholder = "Select date",
      ...props
    },
    ref,
  ) => {
    const [open, setOpen] = React.useState(false);
    const { value, onChange } = useControlledProps(props);

    return (
      <Popover
        open={open}
        onOpenChange={(newOpen) => !disabled && setOpen(newOpen)}
      >
        <PopoverTrigger asChild>
          <Button
            ref={ref}
            id={id}
            variant="combobox"
            role="combobox"
            disabled={disabled}
            aria-expanded={open}
            className={cn(className, "justify-start")}
          >
            <CalendarIcon className="mr-2 h-4 w-4 text-brand-orange" />
            {value ? format(value, "PPP") : placeholder}
          </Button>
        </PopoverTrigger>

        <PopoverContent className="p-2" sideOffset={6} align="start">
          <Calendar
            mode="single"
            defaultMonth={value}
            selected={value}
            onSelect={(date) =>
              value?.getTime() === date?.getTime()
                ? onChange(undefined)
                : onChange(date)
            }
            disabled={formatDisabledDays(min, max)}
            initialFocus
          />
        </PopoverContent>
      </Popover>
    );
  },
);
DatePicker.displayName = "DatePicker";

export { DatePicker };
