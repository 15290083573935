import { zodResolver } from "@hookform/resolvers/zod";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { useForm } from "react-hook-form";
import { DatePicker } from "shared/ui/date-picker";
import { Form, FormField } from "shared/ui/form";
import { Input } from "shared/ui/input";
import { Title } from "shared/ui/title";
import * as z from "zod";

export const patientSearchFormSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  birthday: z.string(),
});

type PatientSearchFormSchema = z.infer<typeof patientSearchFormSchema>;

const usePatientSearchForm = (defaultValues?: PatientSearchFormSchema) =>
  useForm<z.infer<typeof patientSearchFormSchema>>({
    resolver: zodResolver(patientSearchFormSchema),
    defaultValues: defaultValues ?? {},
    mode: "onSubmit",
  });

export default function PatientSearchForm(props: {
  onSubmit: (data: PatientSearchFormSchema) => void;
}) {
  const form = usePatientSearchForm();
  function onSubmit(data: z.infer<typeof patientSearchFormSchema>) {
    props.onSubmit(data);
  }
  return (
    <Form form={form} id="patient-search-form">
      <Title>Patient Search</Title>
      <div className="flex gap-4 items-end">
        <FormField
          control={form.control}
          name="firstName"
          label="First Name"
          render={({ field }) => <Input {...field} />}
        />
        <FormField
          control={form.control}
          name="lastName"
          label="Last Name"
          render={({ field }) => <Input {...field} />}
        />
        <FormField
          control={form.control}
          name="birthday"
          label="Date of Birth"
          render={({ field }) => (
            <DatePicker
              value={field.value ? new Date(field.value) : undefined}
              onChange={(date) => field.onChange(date?.toDateString())}
              className="h-[40px] w-[165px] text-sm font-normal font-['Poppins']"
              placeholder="Select date"
            />
          )}
        />
        <ButtonWithIcon
          className="mb-1"
          onClick={() => {
            form.handleSubmit((values) => {
              onSubmit(values);
            })();
          }}
          text="Search"
          icon={IconOption.ARROW}
        />
      </div>
    </Form>
  );
}
