import type { OrganizationRole } from "features/users/constants";
import { RoleLabel } from "features/users/constants";
import type { CarespaceRole } from "features/users/types";
import { Mail, MessageSquare, Phone } from "lucide-react";
import { Button } from "shared/ui/button";
import { Title } from "shared/ui/title";

type ContactInfoProps = {
  name: string;
  roleType: CarespaceRole | OrganizationRole.PROVIDER | "pcp";
  email?: string | null;
  cellNumber?: string | null;
};

export default function ContactInfo({
  name,
  roleType,
  email,
  cellNumber,
}: ContactInfoProps) {
  return (
    <div className="p-4">
      <Title order={4}>{name}</Title>
      <Title order={5} color="gray" className="mb-2">
        {RoleLabel[roleType]}
      </Title>
      <div className="flex flex-row gap-1 mt-4">
        <a href={cellNumber ? `tel:${cellNumber}` : undefined}>
          <Button variant="outline" size="icon" disabled={!cellNumber}>
            <Phone className="text-gray-600 w-4 h-4" />
          </Button>
        </a>
        <a href={email ? `mailto:${email}` : undefined}>
          <Button variant="outline" size="icon" disabled={!email}>
            <Mail className="text-gray-600 w-4 h-4" />
          </Button>
        </a>
        <Button variant="outline" size="icon" disabled>
          <MessageSquare className="text-gray-600 w-4 h-4" />
        </Button>
      </div>
    </div>
  );
}
