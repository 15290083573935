import { flexRender } from "@tanstack/react-table";
import type { Cell } from "@tanstack/react-table";
import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";
import { UserDetailView } from "components/CarespacePage/OrgMemberDetailPage";
import DeactivateMemberButton from "components/EditMemberButtons/EditMemberButton";
import type { TableDataRowType } from "components/Tables/InfiniteScrollingTable";
import { InfiniteScrollingTable } from "components/Tables/InfiniteScrollingTable";
import { Route } from "features/routing/constants";
import { useAuthUser } from "features/users/auth";
import { useAppNavigate } from "lib/routing";
import { useState } from "react";
import { useCarespaceStore } from "state/carespace/carespace";
import { useOrganizationStore } from "state/organization/organization";
import type { OrganizationRole } from "../../backend/resources/orgRole/orgRole";
import { useActiveOrgRole } from "../../backend/resources/orgRole/orgRole";
import type { UserRole } from "../../backend/resources/userRole/userRole";

export enum RoleTableFields {
  MemberName = "Member Name",
  WorkSpaceName = "Name",
  Role = "Role",
  Id = "Id",
  UserId = "UserId",
  ActionButton = "ActionButton",
  roleObject = "roleObject",
}

type BaseRoleTableType = {
  [RoleTableFields.MemberName]?: string;
  [RoleTableFields.WorkSpaceName]: string;
  [RoleTableFields.Role]: string;
  [RoleTableFields.Id]: string;
  [RoleTableFields.UserId]: string;
  // [RoleTableFields.ActionButton]: string;
  [RoleTableFields.roleObject]: UserRole | OrganizationRole;
};

type RoleTableType = BaseRoleTableType & TableDataRowType<BaseRoleTableType>;

export enum ListType {
  Users = "Users",
  Orgs = "Orgs",
  Carespaces = "Carespaces",
}

const tableStyles = cva([], {
  variants: {
    cellWidth: {
      small: ["w-[10rem]"],
      large: ["w-[20rem]"],
    },
  },
  defaultVariants: {
    cellWidth: "large",
  },
});

export function UserRoleTable({
  data,
  listType,
  hiddenColumns = [RoleTableFields.MemberName],
  ...variantProps
}: {
  data: RoleTableType[];
  listType: ListType;
  hiddenColumns?: RoleTableFields[];
} & VariantProps<typeof tableStyles>) {
  const tableStyle = tableStyles({ ...variantProps });
  const { authUser } = useAuthUser();
  const { data: orgRole } = useActiveOrgRole();

  const navigate = useAppNavigate();
  const setActiveOrganizationId = useOrganizationStore(
    (state) => state.setActiveOrganizationId,
  );
  const setActiveCarespaceId = useCarespaceStore(
    (state) => state.setActiveCarespaceId,
  );
  const activeCarespaceId = useCarespaceStore(
    (state) => state.activeCarespaceId,
  );
  const [focusedUserId, setFocusedUserId] = useState<string | null>(null);
  return (
    <>
      {focusedUserId ? (
        <UserDetailView
          userId={focusedUserId}
          setUserId={setFocusedUserId}
          DMOptions={{
            selectedCarespaceId:
              listType === ListType.Carespaces ? activeCarespaceId : undefined,
          }}
        />
      ) : null}

      <InfiniteScrollingTable
        data={data}
        emptyText="No rows to display"
        columnFields={Object.values(RoleTableFields)}
        handleClick={(cell, row) => {
          if (cell.column.id === RoleTableFields.ActionButton) {
            return; // already handled by DeactivateMemberButton
          }
          switch (listType) {
            case ListType.Users: {
              setFocusedUserId(row.original[RoleTableFields.UserId]);
              break;
            }
            case ListType.Orgs: {
              setActiveOrganizationId(row.original[RoleTableFields.Id]);
              navigate({
                path: Route.ROOT,
              });
              break;
            }
            case ListType.Carespaces: {
              setActiveCarespaceId(row.original[RoleTableFields.Id]);
              navigate({
                path: Route.CARESPACE,
                params: {
                  carespaceId: row.original[RoleTableFields.Id],
                },
              });
              break;
            }
          }
        }}
        hiddenColumnHeaders={[RoleTableFields.ActionButton]}
        hiddenColumns={[
          RoleTableFields.Id,
          RoleTableFields.UserId,
          ...hiddenColumns,
          RoleTableFields.roleObject,
        ]}
        CellContent={({ cell }: { cell: Cell<RoleTableType, unknown> }) => {
          if (cell.column.id === RoleTableFields.ActionButton) {
            const showActionButton =
              orgRole?.is_superuser &&
              authUser?.id !== cell.row.original[RoleTableFields.UserId];
            return (
              <div className="flex w-min">
                {showActionButton && (
                  <DeactivateMemberButton
                    role={cell.row.original[RoleTableFields.roleObject]}
                  />
                )}
              </div>
            );
          }
          return (
            <p className={`truncate text-base ${tableStyle}`}>
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </p>
          );
        }}
      />
    </>
  );
}
