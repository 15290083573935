import * as TabsPrimitive from "@radix-ui/react-tabs";
import * as React from "react";
import { useSearchParams } from "react-router-dom";

import { cn } from "shared/utils/cn";

import useControlledProps, {
  type ControlledProps,
} from "shared/hooks/use-controlled-props";

const TabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.List
    ref={ref}
    className={cn(
      "inline-flex h-10 w-full border-b border-neutral-200 text-black dark:bg-neutral-800 dark:text-neutral-400",
      className,
    )}
    {...props}
  />
));
TabsList.displayName = TabsPrimitive.List.displayName;

const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Trigger
    ref={ref}
    className={cn(
      "inline-flex items-center justify-center whitespace-nowrap px-3 py-1.5 text-sm font-medium mb-[-1px] ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-brand-orange focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:text-brand-orange data-[state=active]:border-b-2 data-[state=active]:border-brand-orange dark:ring-offset-neutral-950 dark:focus-visible:ring-neutral-300 dark:data-[state=active]:bg-neutral-950 dark:data-[state=active]:text-neutral-50",
      className,
    )}
    {...props}
  />
));
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName;

const TabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    ref={ref}
    className={cn(
      "px-2 py-6 ring-offset-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-neutral-950 focus-visible:ring-offset-2 dark:ring-offset-neutral-950 dark:focus-visible:ring-neutral-300",
      className,
    )}
    {...props}
  />
));
TabsContent.displayName = TabsPrimitive.Content.displayName;

export interface TabsProps
  extends Omit<
      React.ComponentPropsWithRef<typeof TabsPrimitive.Root>,
      "onChange"
    >,
    ControlledProps<string> {
  items: {
    value: string;
    label: string;
    content: React.ReactNode;
  }[];
}

const Tabs = ({
  items,
  defaultValue,
  value,
  onChange,
  ...props
}: TabsProps) => {
  const param = "tab";
  const [searchParams, setSearchParams] = useSearchParams();
  const tabParam = searchParams.get(param);

  const { onChange: onValueChange, ...controlledProps } = useControlledProps({
    defaultValue: tabParam || defaultValue || undefined,
    value,
    onChange,
  });

  return (
    <TabsPrimitive.Root
      {...props}
      {...controlledProps}
      onValueChange={(newValue) => {
        setSearchParams(
          (prev) => ({
            ...prev,
            [param]: newValue,
          }),
          {
            replace: true,
          },
        );

        onValueChange(newValue);
      }}
    >
      <TabsList>
        {items.map((item) => (
          <TabsTrigger key={item.value} value={item.value}>
            {item.label}
          </TabsTrigger>
        ))}
      </TabsList>

      {items.map((item) => (
        <TabsContent key={item.value} value={item.value}>
          {item.content}
        </TabsContent>
      ))}
    </TabsPrimitive.Root>
  );
};

export { Tabs, TabsContent, TabsList, TabsTrigger };
