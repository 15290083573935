import { useMutation, useQuery } from "@tanstack/react-query";
import { supabase } from "clients/supabaseClient";
import { useAuthUser } from "features/users/auth";
import { useActiveCarespaceId } from "state/carespace/carespace";
import type { Database } from "../../../../../types/supabase";
import queryClient from "../../../shared/query-client";
import { QUERY_KEYS } from "../../query-keys";
import { CarespaceRoleType } from "./types";

const TABLE = "user_role";
export type UserRole = Database["public"]["Tables"]["user_role"]["Row"];
type UserRoleUpdate = Database["public"]["Tables"]["user_role"]["Update"];

export const useActiveUserRole = () => {
  const { authUser } = useAuthUser();
  const activeCarespaceId = useActiveCarespaceId();
  const {
    isLoading: isUserRoleLoading,
    error: userRoleError,
    data: userRoleData,
  } = useQuery({
    queryKey: [
      QUERY_KEYS.userRole,
      { userId: authUser?.id, activeCarespaceId },
    ],
    queryFn: () => fetchUserRole(authUser?.id, activeCarespaceId),
    refetchOnWindowFocus: false,
  });
  return { userRole: userRoleData, isUserRoleLoading, userRoleError };
};

async function updateUserRole(userRole: UserRoleUpdate) {
  if (!userRole.id) return;

  const { data, error } = await supabase
    .from(TABLE)
    .update({ ...userRole, id: undefined })
    .eq("id", userRole.id)
    .select("*")
    .limit(1)
    .order("id", { ascending: true }) // noop
    .maybeSingle();
  if (error) {
    throw new Error(error.message);
  }

  return data;
}

export const useUpdateUserRole = () =>
  useMutation({
    mutationFn: async ({
      roleId,
      newRole,
      isDeactivated,
    }: {
      roleId: string;
      newRole?: CarespaceRoleType;
      isDeactivated?: boolean;
    }) => {
      const response = await updateUserRole({
        id: roleId,
        role: newRole,
        is_deactivated: isDeactivated,
      });
      // Need to update the user role and the invitations since that's
      // How we keep track of team members in admin

      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.invitation],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.invitation],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.userRole],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.orgAndCarespaceIdentities],
      });
    },
  });

// fetch and queries
export function useCarespaceIds() {
  const { authUser } = useAuthUser();

  const { isLoading, error, data } = useQuery({
    queryKey: [QUERY_KEYS.carespaceIds, { userId: authUser?.id }],
    queryFn: () => fetchCarespaceIds(authUser?.id),
    enabled: !!authUser,
  });
  return {
    carespaceIds: data,
    carespaceIdsError: error,
    carespaceIdsIsLoading: isLoading,
  };
}

async function fetchUserRole(
  user_id: string | undefined,
  carespace_id: string | undefined,
) {
  if (!user_id || !carespace_id) {
    return null;
  }

  const { data, error } = await supabase
    .from(TABLE)
    .select()
    .eq("user_id", user_id)
    .eq("carespace_id", carespace_id)
    .limit(1)
    .maybeSingle();

  if (error) {
    return null;
  }

  return data;
}

async function fetchCarespaceIds(user_id?: string) {
  if (!user_id) {
    return undefined;
  }

  const { data, error } = await supabase.from(TABLE).select("carespace_id");

  if (error) {
    throw new Error(error.message);
  }

  return data.map((role) => role.carespace_id || undefined);
}
