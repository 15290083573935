import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { useNavigateBack } from "lib/routing";

export default function BackButton({ className }: { className?: string }) {
  const navigateBack = useNavigateBack();
  return (
    <ButtonWithIcon
      onClick={() => navigateBack()}
      text="Back"
      className={className}
      icon={IconOption.BACK_ARROW}
      size="small"
    />
  );
}
