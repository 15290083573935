import { zodResolver } from "@hookform/resolvers/zod";
import { US_STATES } from "features/organizations/constants";
import { AlertCircle } from "lucide-react";
import { useEffect } from "react";
import type {
  Control,
  DefaultValues,
  SubmitHandler,
  UseFormReturn,
} from "react-hook-form";
import { useForm } from "react-hook-form";
import { Alert, AlertDescription, AlertTitle } from "shared/ui/alert";
import { Button } from "shared/ui/button";
import { Combobox } from "shared/ui/combobox";
import { Form, FormField } from "shared/ui/form";
import { Input } from "shared/ui/input";
import { PhoneInput } from "shared/ui/phone-input";
import { Separator } from "shared/ui/separator";
import { Title } from "shared/ui/title";
import type { z } from "zod";
import {
  ClinicianAttestationMap,
  DementiaStagingToolMap,
  EthnicityMap,
  HasCaregiverMap,
  MedicareBeneficiaryTypeMap,
  PhoneTypeMap,
  RaceMap,
  ReferralSourceMap,
  RelationshipTypeMap,
  ResidenceTypeMap,
  Sex,
  icd10Codes,
} from "../constants";
import type { AlignmentFormSchema, FormSchema } from "../schema";
import { alignmentFormSchema, formSchema } from "../schema";

function parseDateValue(value: Date | string | undefined): string {
  if (typeof value === "string") return value;
  return value?.toISOString().split("T")[0] ?? "";
}

type CarespaceFormProps<T extends "default" | "alignment"> = T extends "default"
  ? {
      variant: "default";
      onSubmit: SubmitHandler<FormSchema>;
      defaultValues: DefaultValues<FormSchema>;
      title: string;
      submitLabel: string;
    }
  : {
      variant: "alignment";
      onSubmit: SubmitHandler<AlignmentFormSchema>;
      defaultValues: DefaultValues<AlignmentFormSchema>;
      title: string;
      submitLabel: string;
      rationale?: string;
    };

function isAlignmentFormSchema(
  data: FormSchema | AlignmentFormSchema,
): data is AlignmentFormSchema {
  return "patient_phone_type" in data;
}

const isOrNoOptions = [
  {
    value: "yes",
    label: "Yes",
  },
  {
    value: "no",
    label: "No",
  },
];

function yesOrNoValue(value: boolean | undefined): string | undefined {
  if (typeof value === "undefined") {
    return undefined;
  }

  return value ? "yes" : "no";
}

export default function CarespacesForm<T extends "default" | "alignment">({
  variant = "default",
  onSubmit,
  defaultValues,
  title,
  submitLabel,
  ...props
}: CarespaceFormProps<T>) {
  const schema = variant === "default" ? formSchema : alignmentFormSchema;
  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues,
  });

  // ⚠️ Use alignmentForm and alignmentControl with caution as they are only safe to use when variant is "alignment"
  const alignmentForm = form as unknown as UseFormReturn<AlignmentFormSchema>;
  const alignmentControl =
    form.control as unknown as Control<AlignmentFormSchema>;

  const showCaregiverFields =
    form.watch("patient_has_caregiver") &&
    form.watch("patient_has_caregiver") !== "no" &&
    form.watch("patient_has_caregiver") !== "undetermined";
  const caregiverLivesWithPatient =
    variant === "alignment"
      ? alignmentForm.watch("caregiver_lives_with_patient")
      : undefined;
  const caregiverHasUser =
    defaultValues.patient_has_caregiver === "yes_one" ||
    defaultValues.patient_has_caregiver === "yes_multiple";
  const patientAddress = form.watch("patient_address");
  const patientCity = form.watch("patient_city");
  const patientState = form.watch("patient_state");
  const patientZipcode = form.watch("patient_zipcode");

  useEffect(() => {
    if (!caregiverLivesWithPatient) return;

    alignmentForm.setValue("caregiver_address", patientAddress);
    alignmentForm.setValue("caregiver_city", patientCity);
    alignmentForm.setValue("caregiver_state", patientState);
    alignmentForm.setValue("caregiver_zipcode", patientZipcode);
  }, [
    caregiverLivesWithPatient,
    alignmentForm.setValue,
    patientAddress,
    patientCity,
    patientState,
    patientZipcode,
  ]);

  const handleFormSubmit = (data: FormSchema | AlignmentFormSchema) => {
    if (isAlignmentFormSchema(data)) {
      // For alignment, cast the data to AlignmentFormSchema.
      return (onSubmit as SubmitHandler<AlignmentFormSchema>)(data);
    }
    // For default variant, cast the data to FormSchema.
    return (onSubmit as SubmitHandler<FormSchema>)(data);
  };

  return (
    <div className="flex flex-col gap-4 p-6 max-w-2xl">
      <Title className="mb-4">{title}</Title>

      {"rationale" in props && typeof props.rationale === "string" && (
        <Alert variant="destructive">
          <AlertCircle className="h-4 w-4" />
          <AlertTitle>Rejection rationale</AlertTitle>
          <AlertDescription>{props.rationale}</AlertDescription>
        </Alert>
      )}

      <Form
        form={form}
        onSubmit={form.handleSubmit(handleFormSubmit)}
        className="mt-4"
      >
        <Title order={3} color="gray">
          Patient Information
        </Title>
        <Separator />

        <div className="grid grid-cols-2 gap-4">
          <FormField
            control={form.control}
            name="patient_first_name"
            label="First Name"
            render={({ field }) => <Input {...field} />}
          />
          <FormField
            control={form.control}
            name="patient_last_name"
            label="Last Name"
            render={({ field }) => <Input {...field} />}
          />
        </div>
        <div className="grid grid-cols-2 gap-4 mt-4">
          <FormField
            control={form.control}
            name="patient_birthday"
            label="Birthday"
            render={({ field }) => (
              <Input
                {...field}
                type="date"
                value={parseDateValue(field.value)}
              />
            )}
          />
          <FormField
            control={form.control}
            name="patient_legal_sex"
            label="Legal Sex"
            render={({ field }) => (
              <Combobox
                options={Object.values(Sex).map((value) => ({
                  value,
                  label: value,
                }))}
                {...field}
              />
            )}
          />
        </div>
        <div className="grid grid-cols-2 gap-4 mt-4">
          <FormField
            control={form.control}
            name="patient_race"
            label="Race"
            render={({ field }) => (
              <Combobox
                options={Object.entries(RaceMap).map(([value, label]) => ({
                  value,
                  label,
                }))}
                {...field}
              />
            )}
          />
          <FormField
            control={form.control}
            name="patient_ethnicity"
            label="Ethnicity"
            render={({ field }) => (
              <Combobox
                options={Object.entries(EthnicityMap).map(([value, label]) => ({
                  value,
                  label,
                }))}
                {...field}
              />
            )}
          />
        </div>
        <div className="grid grid-cols-2 gap-4 mt-4">
          <FormField
            control={form.control}
            name="patient_preferred_name"
            label="Preferred Name (Optional)"
            render={({ field }) => <Input {...field} />}
          />
          <FormField
            control={form.control}
            name="patient_preferred_language"
            label="Preferred Language (Optional)"
            render={({ field }) => <Input {...field} />}
          />
        </div>

        <div className="grid grid-cols-2 gap-4 mt-4">
          <FormField
            control={form.control}
            name="patient_is_new_patient"
            label="Patient Status with Practice"
            render={({ field }) => (
              <Combobox
                options={[
                  { value: "true", label: "New patient" },
                  { value: "false", label: "Existing patient" },
                ]}
                value={String(field.value)}
                onChange={(value) => field.onChange(value === "true")}
              />
            )}
          />
          <FormField
            control={form.control}
            name="patient_referral_source"
            label="Referral Source"
            render={({ field }) => (
              <Combobox
                options={Object.entries(ReferralSourceMap).map(
                  ([value, label]) => ({
                    value,
                    label,
                  }),
                )}
                {...field}
              />
            )}
          />
        </div>
        <div className="grid grid-cols-2 gap-4 mt-4">
          <FormField
            control={form.control}
            name="patient_mbi"
            label="Medicare Beneficiary Identifier (MBI)"
            render={({ field }) => <Input {...field} />}
          />
          <FormField
            control={form.control}
            name="patient_medicaid_id"
            label="Medicaid ID (Optional)"
            render={({ field }) => <Input {...field} />}
          />
        </div>

        <FormField
          control={form.control}
          name="patient_medical_record_number"
          label="Medical Record Number"
          render={({ field }) => <Input {...field} />}
        />

        <div className="grid grid-cols-2 gap-4 mt-4">
          <FormField
            control={form.control}
            name="patient_email"
            label="Email (Optional)"
            render={({ field }) => <Input {...field} type="email" />}
          />
          <FormField
            control={form.control}
            name="patient_phone_number"
            label="Phone Number"
            render={({ field }) => <PhoneInput {...field} />}
          />
        </div>

        {variant === "alignment" && (
          <div className="grid grid-cols-2 gap-4 mt-4">
            <FormField
              control={alignmentControl}
              name="patient_phone_type"
              label="Patient Phone Type"
              render={({ field }) => (
                <Combobox
                  options={Object.entries(PhoneTypeMap).map(
                    ([value, label]) => ({
                      value,
                      label,
                    }),
                  )}
                  {...field}
                />
              )}
            />

            <FormField
              control={alignmentControl}
              name="patient_timezone"
              label="Timezone"
              render={({ field }) => (
                <Combobox
                  searchable
                  options={Intl.supportedValuesOf("timeZone").map((value) => ({
                    value,
                    label: value,
                  }))}
                  {...field}
                />
              )}
            />
          </div>
        )}

        <Title order={3} className="mt-8" color="gray">
          Patient Address
        </Title>
        <Separator />

        <div className="grid grid-cols-2 gap-4 mt-4">
          <FormField
            control={form.control}
            name="patient_address"
            label="Address"
            render={({ field }) => <Input {...field} />}
          />

          <FormField
            control={form.control}
            name="patient_city"
            label="City"
            render={({ field }) => <Input {...field} />}
          />
        </div>

        <div className="grid grid-cols-2 gap-4 mt-4">
          <FormField
            control={form.control}
            name="patient_state"
            label="State"
            render={({ field }) => (
              <Combobox
                searchable
                options={US_STATES.map((state) => ({
                  value: state,
                  label: state,
                }))}
                {...field}
              />
            )}
          />
          <FormField
            control={form.control}
            name="patient_zipcode"
            label="Zip Code"
            render={({ field }) => <Input {...field} />}
          />
        </div>

        {variant === "alignment" && (
          <>
            <FormField
              control={alignmentControl}
              name="patient_residence_type"
              label="Residence Type"
              render={({ field }) => (
                <Combobox
                  options={Object.entries(ResidenceTypeMap).map(
                    ([value, label]) => ({
                      value,
                      label,
                    }),
                  )}
                  {...field}
                />
              )}
            />

            <FormField
              control={alignmentControl}
              name="patient_is_nursing_home_resident"
              label="Is Nursing Home Resident?"
              render={({ field }) => (
                <Combobox
                  {...field}
                  options={isOrNoOptions}
                  value={yesOrNoValue(field.value)}
                  onChange={(value) => field.onChange(value === "yes")}
                />
              )}
            />
          </>
        )}

        <Title order={3} className="mt-8" color="gray">
          Provider Information
        </Title>
        <Separator />

        <div className="grid grid-cols-2 gap-4">
          <FormField
            control={form.control}
            name="provider_first_name"
            label="Provider First Name"
            render={({ field }) => <Input {...field} />}
          />
          <FormField
            control={form.control}
            name="provider_last_name"
            label="Provider Last Name"
            render={({ field }) => <Input {...field} />}
          />
        </div>

        <FormField
          control={form.control}
          name="provider_npi"
          label="Provider National Provider Identifier"
          render={({ field }) => <Input {...field} />}
        />

        <Title order={3} className="mt-8" color="gray">
          Primary Care Provider Information (Optional)
        </Title>
        <Separator />

        <div className="grid grid-cols-2 gap-4">
          <FormField
            control={form.control}
            name="pcp_first_name"
            label="Primary Care Provider First Name"
            render={({ field }) => <Input {...field} />}
          />
          <FormField
            control={form.control}
            name="pcp_last_name"
            label="Primary Care Provider Last Name"
            render={({ field }) => <Input {...field} />}
          />
        </div>

        <FormField
          control={form.control}
          name="pcp_phone_number"
          label="Primary Care Provider Phone Number"
          render={({ field }) => <PhoneInput {...field} />}
        />

        <Title order={3} className="mt-8" color="gray">
          Caregiver Information
        </Title>
        <Separator />

        <FormField
          control={form.control}
          name="patient_has_caregiver"
          label="Does the patient have a caregiver?"
          disabled={caregiverHasUser}
          render={({ field }) => (
            <Combobox
              options={Object.entries(HasCaregiverMap).map(
                ([value, label]) => ({
                  value,
                  label,
                }),
              )}
              {...field}
              onChange={(value) => {
                field.onChange(value);
                // If "No" or "Undetermined", clear caregiver fields
                if (value === "no" || value === "undetermined") {
                  form.setValue("caregiver_first_name", "");
                  form.setValue("caregiver_last_name", "");
                  form.setValue("caregiver_email", "");
                  form.setValue("caregiver_phone_number", "");
                }
              }}
            />
          )}
        />

        {showCaregiverFields && (
          <>
            <div className="grid grid-cols-2 gap-4">
              <FormField
                control={form.control}
                name="caregiver_first_name"
                label="Caregiver First Name"
                render={({ field }) => <Input {...field} />}
              />
              <FormField
                control={form.control}
                name="caregiver_last_name"
                label="Caregiver Last Name"
                render={({ field }) => <Input {...field} />}
              />
            </div>

            <div className="grid grid-cols-2 gap-4 mt-4">
              <FormField
                control={form.control}
                name="caregiver_email"
                label="Caregiver Email"
                disabled={caregiverHasUser}
                render={({ field }) => <Input {...field} type="email" />}
              />
              <FormField
                control={form.control}
                name="caregiver_phone_number"
                label="Caregiver Phone Number"
                render={({ field }) => <PhoneInput {...field} />}
              />
            </div>
          </>
        )}

        {showCaregiverFields && variant === "alignment" && (
          <>
            <div className="grid grid-cols-2 gap-4 mt-4">
              <FormField
                control={alignmentControl}
                name="caregiver_phone_type"
                label="Caregiver Phone Type"
                render={({ field }) => (
                  <Combobox
                    options={Object.entries(PhoneTypeMap).map(
                      ([value, label]) => ({
                        value,
                        label,
                      }),
                    )}
                    {...field}
                  />
                )}
              />

              <FormField
                control={alignmentControl}
                name="caregiver_legal_sex"
                label="Caregiver Legal Sex"
                render={({ field }) => (
                  <Combobox
                    options={Object.values(Sex).map((value) => ({
                      value,
                      label: value,
                    }))}
                    {...field}
                  />
                )}
              />
            </div>
            <div className="grid grid-cols-2 gap-4 mt-4">
              <FormField
                control={alignmentControl}
                name="caregiver_race"
                label="Caregiver Race"
                render={({ field }) => (
                  <Combobox
                    options={Object.entries(RaceMap).map(([value, label]) => ({
                      value,
                      label,
                    }))}
                    {...field}
                  />
                )}
              />
              <FormField
                control={alignmentControl}
                name="caregiver_ethnicity"
                label="Caregiver Ethnicity"
                render={({ field }) => (
                  <Combobox
                    options={Object.entries(EthnicityMap).map(
                      ([value, label]) => ({
                        value,
                        label,
                      }),
                    )}
                    {...field}
                  />
                )}
              />
            </div>
            <div className="grid grid-cols-2 gap-4 mt-4">
              <FormField
                control={alignmentControl}
                name="caregiver_birthday"
                label="Caregiver Birthday"
                render={({ field }) => (
                  <Input
                    {...field}
                    type="date"
                    value={parseDateValue(field.value)}
                  />
                )}
              />
              <FormField
                control={alignmentControl}
                name="caregiver_relationship_type"
                label="Relationship to Patient"
                render={({ field }) => (
                  <Combobox
                    options={Object.entries(RelationshipTypeMap).map(
                      ([value, label]) => ({
                        value,
                        label,
                      }),
                    )}
                    {...field}
                  />
                )}
              />
            </div>
            <div className="grid grid-cols-2 gap-4 mt-4">
              <FormField
                control={alignmentControl}
                name="caregiver_since_years"
                label="Caregiver Since (Years)"
                render={({ field }) => (
                  <Input type="number" step="1" min="0" {...field} />
                )}
              />

              <FormField
                control={alignmentControl}
                name="caregiver_since_months"
                label="Caregiver Since (Months)"
                render={({ field }) => (
                  <Input type="number" step="1" min="0" {...field} />
                )}
              />
            </div>
            <div className="grid grid-cols-2 gap-4 mt-4">
              <FormField
                control={alignmentControl}
                name="caregiver_is_medicare_beneficiary"
                label="Is Caregiver a Medicare Beneficiary?"
                render={({ field }) => (
                  <Combobox
                    {...field}
                    options={Object.entries(MedicareBeneficiaryTypeMap).map(
                      ([key, value]) => ({
                        value: key,
                        label: value,
                      }),
                    )}
                  />
                )}
              />

              <FormField
                control={alignmentControl}
                name="caregiver_mbi"
                label="Caregiver Medicare Beneficiary ID"
                render={({ field }) => <Input type="text" {...field} />}
              />
            </div>

            <Title order={3} className="mt-8" color="gray">
              Caregiver Address
            </Title>
            <Separator />

            <FormField
              control={alignmentControl}
              name="caregiver_lives_with_patient"
              label="Does Caregiver Live With Patient?"
              render={({ field }) => (
                <Combobox
                  {...field}
                  options={isOrNoOptions}
                  value={yesOrNoValue(field.value)}
                  onChange={(value) => field.onChange(value === "yes")}
                />
              )}
            />

            <div className="grid grid-cols-2 gap-4 mt-4">
              <FormField
                control={alignmentControl}
                name="caregiver_address"
                label="Caregiver Address"
                disabled={caregiverLivesWithPatient}
                render={({ field }) => <Input {...field} />}
              />

              <FormField
                control={alignmentControl}
                name="caregiver_city"
                label="Caregiver City"
                disabled={caregiverLivesWithPatient}
                render={({ field }) => <Input {...field} />}
              />
            </div>

            <div className="grid grid-cols-2 gap-4 mt-4">
              <FormField
                control={alignmentControl}
                name="caregiver_state"
                label="Caregiver State"
                disabled={caregiverLivesWithPatient}
                render={({ field }) => (
                  <Combobox
                    searchable
                    options={US_STATES.map((state) => ({
                      value: state,
                      label: state,
                    }))}
                    {...field}
                  />
                )}
              />
              <FormField
                control={alignmentControl}
                name="caregiver_zipcode"
                label="Caregiver Zip Code"
                disabled={caregiverLivesWithPatient}
                render={({ field }) => <Input {...field} />}
              />
            </div>
          </>
        )}

        <Title order={3} className="mt-8" color="gray">
          Attesting Clinician Information
        </Title>
        <Separator />

        <div className="grid grid-cols-2 gap-4">
          <FormField
            control={form.control}
            name="clinician_first_name"
            label="Clinician First Name"
            render={({ field }) => <Input {...field} />}
          />
          <FormField
            control={form.control}
            name="clinician_last_name"
            label="Clinician Last Name"
            render={({ field }) => <Input {...field} />}
          />
        </div>

        <FormField
          control={form.control}
          name="clinician_npi"
          label="Clinician National Provider Identifier"
          render={({ field }) => <Input {...field} />}
        />

        <FormField
          control={form.control}
          name="clinician_attestation"
          label="Clinician Attestation"
          render={({ field }) => (
            <Combobox
              options={Object.entries(ClinicianAttestationMap).map(
                ([value, label]) => ({
                  value,
                  label,
                }),
              )}
              {...field}
            />
          )}
        />

        <div className="grid grid-cols-2 gap-4">
          <FormField
            control={form.control}
            name="patient_dementia_staging_tool"
            label="Dementia Staging Tool Used"
            disabled={variant === "alignment"}
            render={({ field }) => (
              <Combobox
                options={Object.entries(DementiaStagingToolMap).map(
                  ([value, label]) => ({
                    value,
                    label,
                  }),
                )}
                {...field}
              />
            )}
          />
          <FormField
            control={form.control}
            name="patient_dementia_staging_score"
            label="Dementia Staging Tool Score"
            disabled={variant === "alignment"}
            render={({ field }) => (
              <Input
                {...field}
                type="number"
                step="0.01"
                onChange={(e) =>
                  field.onChange(Number.parseFloat(e.target.value))
                }
              />
            )}
          />
        </div>

        <div className="grid grid-cols-2 gap-4">
          <FormField
            control={form.control}
            name="patient_icd10_code"
            label="ICD-10 Code"
            render={({ field }) => (
              <Combobox
                searchable
                options={icd10Codes.map((value) => ({ value, label: value }))}
                {...field}
              />
            )}
          />
          {variant === "alignment" && (
            <FormField
              control={form.control as unknown as Control<AlignmentFormSchema>}
              name="assessment_date"
              label="Date of Assessment"
              render={({ field }) => (
                <Input
                  {...field}
                  type="date"
                  value={parseDateValue(field.value)}
                />
              )}
            />
          )}
        </div>

        <Button
          type="submit"
          disabled={form.formState.isSubmitting}
          isLoading={form.formState.isSubmitting}
          className="my-8"
        >
          {submitLabel}
        </Button>
      </Form>
    </div>
  );
}
