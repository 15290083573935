import {
  type ColumnFiltersState,
  createColumnHelper,
} from "@tanstack/react-table";
import { useMemo, useState } from "react";

import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { useAppNavigate } from "lib/routing";

import { LoadingSpinner } from "components/LoadingSpinner";

import { useFetchMany as useOrganizations } from "features/organizations/queries/hooks";
import type { Organization } from "features/organizations/types";
import { Route } from "features/routing/constants";
import { EllipsisVertical, Pencil } from "lucide-react";
import useRequireSuperOrg from "shared/hooks/use-require-super-org";
import { Button } from "shared/ui/button";
import {
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuRoot,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "shared/ui/dropdown-menu";
import { DataTable } from "shared/ui/table";
import { Text } from "shared/ui/text";
import { Title } from "shared/ui/title";
import { useOrganizationStore } from "state/organization/organization";

const columnHelper = createColumnHelper<Organization>();

export default function OrganizationList() {
  useRequireSuperOrg();
  const { data: organizations, isLoading } = useOrganizations({
    equals: { is_super_org: false },
  });
  const setActiveOrganizationId = useOrganizationStore(
    (state) => state.setActiveOrganizationId,
  );
  const navigate = useAppNavigate();

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: "Name",
        meta: {
          columnFiltering: {
            filterLabel: "Name",
          },
        },
      }),
      columnHelper.accessor("state", {
        header: "State",
        meta: {
          columnFiltering: {
            filterLabel: "State",
          },
        },
      }),
      columnHelper.accessor("city", {
        header: "City",
        meta: {
          columnFiltering: {
            filterLabel: "City",
          },
        },
      }),
      columnHelper.accessor("address", {
        header: "Address",
        meta: {
          columnFiltering: {
            filterLabel: "Address",
          },
        },
      }),
      columnHelper.accessor("zipcode", {
        header: "Zip Code",
        meta: {
          columnFiltering: {
            filterLabel: "Zip Code",
          },
        },
      }),
      columnHelper.display({
        id: "actions",
        cell: ({ row }) => (
          <DropdownMenuRoot>
            <DropdownMenuTrigger asChild>
              <Button
                variant="outline"
                size="icon"
                aria-label={`Actions for ${row.original.name}`}
              >
                <EllipsisVertical className="w-4 h-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56">
              <DropdownMenuLabel>{row.original.name}</DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuGroup>
                <DropdownMenuItem
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent row click event from firing
                    setActiveOrganizationId(row.original.id);
                    navigate({
                      path: Route.ADMIN_EDIT,
                    });
                  }}
                >
                  <Pencil className="w-4 h-4" />
                  <span>Edit</span>
                </DropdownMenuItem>
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenuRoot>
        ),
      }),
    ],
    [],
  );

  if (isLoading) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <LoadingSpinner className="w-6 h-6" />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4 h-full p-6 mb-8">
      <Title>Organizations</Title>
      <div className="flex items-center mb-8">
        <ButtonWithIcon
          text="Add Organization"
          icon={IconOption.PLUS}
          onClick={() => {
            navigate({
              path: Route.ORGANIZATIONS_NEW,
            });
          }}
        />
      </div>
      {organizations ? (
        <DataTable
          columns={columns}
          data={organizations}
          columnFiltering={{
            columnFilters,
            setColumnFilters,
          }}
          onRowClick={(row) => {
            setActiveOrganizationId(row.id);
            navigate({
              path: Route.ADMIN,
            });
          }}
        />
      ) : (
        <div className="w-full h-full flex items-center justify-center">
          <Text>No organizations found</Text>
        </div>
      )}
    </div>
  );
}
