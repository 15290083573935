import CancelIconSvg from "assets/cancel-icon.svg?react";
import CheckmarkIconSvg from "assets/checkmark-icon.svg?react";
import CirclePlusIcon from "assets/circle-plus.svg?react";
import TrashIcon from "assets/delete-icon.svg?react";
import DownloadIconSvg from "assets/download.svg?react";
import EditIconSvg from "assets/edit-folder.svg?react";
import EmailIconSvg from "assets/email-icon.svg?react";
import FinishLaterIcon from "assets/finish later.svg?react";
import ForwardIconSvg from "assets/forward-icon.svg?react";
import MessageIconSvg from "assets/message.svg?react";
import MoreIcon from "assets/more-actions.svg?react";
import PADLOCK from "assets/padlock.png";
import PhoneIconSvg from "assets/phone-icon.svg?react";
import PrivacyPolicy from "assets/privacy.png";
import RemoveIcon from "assets/remove.svg?react";
import RestoreSvg from "assets/restore.svg?react";
import RightArrowButtonIcon from "assets/right-arrow-button.svg?react";
import SaveDraftIcon from "assets/save_draft_button.svg?react";
import SendInvitationIcon from "assets/send-invitation.svg?react";
import TermsOfService from "assets/tos.png";
import UploadIconSvg from "assets/upload.svg?react";
import WebIconSvg from "assets/website-icon.svg?react";
import { Arrow } from "icons/Arrow";

export enum IconSize {
  extra_small = 16,
  small = 20,
  medium = 24,
  large = 32,
}

export enum IconOption {
  PLUS = 0,
  ARROW = 1,
  BACK_ARROW = 4,
  UP_ARROW = 5,
  EDIT = 6,
  LATER = 9,
  CANCEL = 13,
  TRASH = 14,
  CHECKMARK = 15,
  SAVE_DRAFT = 16,
  MORE = 17,
  PHONE = 18,
  EMAIL = 19,
  WEB = 20,
  PADLOCK = 21,
  SEND_INVITATION = 24,
  TERMS_OF_SERVICE = 25,
  PRIVACY_POLICY = 26,
  MESSAGE = 28,
  UPLOAD = 29,
  RESTORE = 31,
  FORWARD = 32,
  DOWNLOAD = 33,
  REMOVE = 35,
}

export const ButtonIconMap = {
  [IconOption.PLUS]: <CirclePlusIcon />,
  [IconOption.RESTORE]: <RestoreSvg />,
  [IconOption.UP_ARROW]: (
    <Arrow className="bg-brand-orange fill-white rounded-full p-1" size={30} />
  ),
  [IconOption.REMOVE]: (
    <RemoveIcon className="rounded-full overflow-clip w-5 h-5" />
  ),
  [IconOption.ARROW]: <RightArrowButtonIcon />,
  [IconOption.BACK_ARROW]: <RightArrowButtonIcon className="rotate-180" />,
  [IconOption.EDIT]: <EditIconSvg className="w-full" />,
  [IconOption.LATER]: <FinishLaterIcon />,
  [IconOption.CANCEL]: <CancelIconSvg />,
  [IconOption.TRASH]: <TrashIcon />,
  [IconOption.CHECKMARK]: <CheckmarkIconSvg className="w-full" />,
  [IconOption.SEND_INVITATION]: <SendInvitationIcon />,
  [IconOption.SAVE_DRAFT]: <SaveDraftIcon />,
  [IconOption.MORE]: <MoreIcon />,
  [IconOption.PHONE]: <PhoneIconSvg />,
  [IconOption.MESSAGE]: <MessageIconSvg />,
  [IconOption.EMAIL]: <EmailIconSvg />,
  [IconOption.WEB]: <WebIconSvg />,
  [IconOption.UPLOAD]: <UploadIconSvg />,
  [IconOption.DOWNLOAD]: <DownloadIconSvg />,
  [IconOption.PADLOCK]: <img src={PADLOCK} alt="" />,
  [IconOption.TERMS_OF_SERVICE]: <img src={TermsOfService} alt="" />,
  [IconOption.PRIVACY_POLICY]: <img src={PrivacyPolicy} alt="" />,
  [IconOption.FORWARD]: <ForwardIconSvg />,
};
