import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { z } from "zod";

import { supabase } from "clients/supabaseClient";

import { Button } from "shared/ui/button";
import { Card } from "shared/ui/card";
import { Form, FormField } from "shared/ui/form";
import { Input } from "shared/ui/input";

import { Route } from "features/routing/constants";
import { useToast } from "shared/hooks/use-toast";

const REDIRECT_URL = import.meta.env.VITE_REDIRECT_URL;

const formSchema = z.object({
  email: z.string().email({ message: "Please enter a valid email address" }),
});

type FormSchema = z.infer<typeof formSchema>;

export default function ResetPassword() {
  const form = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
    },
  });
  const { toast } = useToast();

  const onSubmit = async ({ email }: FormSchema) => {
    const { error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: `${REDIRECT_URL}${Route.UPDATE_PASSWORD}`,
    });

    if (error) {
      toast({
        description: error.message,
        variant: "destructive",
      });
    } else {
      form.reset();

      toast({
        title: "Password successfully reset!",
        description: "Please check your email.",
        variant: "success",
      });
    }
  };

  return (
    <div className="flex items-center justify-center w-full h-full">
      <Card title="Reset your password" className="w-[450px]">
        <Form form={form} onSubmit={form.handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-1">
            <FormField
              control={form.control}
              name="email"
              label="Email"
              render={({ field }) => <Input {...field} />}
            />

            <Link
              className="text-brand-orange hover:brightness-110 transition-colors text-sm ml-auto"
              to={Route.LOGIN}
            >
              Return to login
            </Link>
          </div>

          <Button
            type="submit"
            className="w-full"
            isLoading={form.formState.isSubmitting}
          >
            Send reset email
          </Button>
        </Form>
      </Card>
    </div>
  );
}
