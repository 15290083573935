import * as Sentry from "@sentry/react";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "clients/supabaseClient";
import { QUERY_KEYS } from "../../backend/query-keys";

export function useOrgAndCarespaceIdentitiesForUser(userId?: string) {
  // carespace and org identities
  const { isLoading, error, data, isFetching, refetch } = useQuery({
    queryKey: [QUERY_KEYS.orgAndCarespaceIdentities, { userId }],
    queryFn: () => fetchCarespaceAndOrgIdentitiesByUserId(userId),
    refetchOnWindowFocus: false,
  });

  return {
    // query obj
    isLoading,
    error,
    data,
    isFetching,
    refetch,
    // derived
    carespaceNames: data?.user_role?.map((role) => role?.carespace?.name),
    orgNames: data?.organization_role?.map((role) => role?.organization?.name),
  };
}

async function fetchCarespaceAndOrgIdentitiesByUserId(userId?: string) {
  if (!userId) {
    return null;
  }

  const { data, error } = await supabase
    .from("user")
    .select(
      "*, organization_role(*, organization(*)), user_role(*, carespace(*))",
    )
    .eq("id", userId);

  if (error) {
    Sentry.captureException(error);
    throw new Error(error.message);
  }

  return data?.[0];
}
