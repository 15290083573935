import { calculateAge } from "features/date-utils";
import { Text } from "shared/ui/text";
import { Title } from "shared/ui/title";
import { PatientTierMap, type Sex } from "../constants";
import type { GuideStatus, PatientTier } from "../types";

type PatientProps = {
  name: string;
  birthday: Date | null;
  tier?: PatientTier;
  sex?: Sex;
  mbi?: string;
  medicaid_id?: string;
  preferred_name?: string;
  preferred_language?: string;
  guide_status: GuideStatus;
};

export default function PatientInfo(props: PatientProps) {
  let birthday = "Unknown birthday";

  if (props.birthday) {
    const formattedDate = new Date(props.birthday).toLocaleDateString("en-US");
    const age = calculateAge(props.birthday);
    birthday = `${age} years old (${formattedDate})`;
  }

  return (
    <div className="p-4">
      <Title order={2}>{props.name}</Title>
      <Text size="sm" className="mb-2 text-gray-500">
        Patient
      </Text>
      {props.preferred_name && (
        <Text size="sm" className="text-gray-500">
          Preferred name: {props.preferred_name}
        </Text>
      )}
      {props.preferred_language && (
        <Text size="sm" className="text-gray-500">
          Preferred language: {props.preferred_language}
        </Text>
      )}
      <Text size="sm" className="text-gray-500">
        {birthday}
      </Text>
      <Text size="sm" className="text-gray-500">
        {props.sex}
      </Text>
      <Text size="sm" className="text-gray-500">
        Tier: {PatientTierMap[props.tier ?? "na"]}
      </Text>
      <Text size="sm" className="mt-2 text-gray-500">
        Guide Status: {props.guide_status}
      </Text>
      {props.mbi && (
        <Text size="sm" className="text-gray-500">
          MBI: {props.mbi}
        </Text>
      )}
      {props.medicaid_id && (
        <Text size="sm" className="text-gray-500">
          Medicaid ID: {props.medicaid_id}
        </Text>
      )}
    </div>
  );
}
