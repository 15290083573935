import type { QueryData } from "@supabase/supabase-js";

import type { Database, Tables } from "types/supabase";

import type { CarespaceRole } from "features/users/types";

import type { US_STATES } from "features/organizations/constants";
import type { User } from "features/users/types";
import type {
  ASSESSMENT_TABLE_NAME,
  TABLE_NAME,
  USER_ASSESSMENT_TABLE_NAME,
} from "./constants";
import { PatientTierMap, Sex } from "./constants";
import type { select } from "./queries";

export type GuideStatus = Database["public"]["Enums"]["guide_status_type"];
export type DementiaStagingTool =
  Database["public"]["Enums"]["dementia_staging_tool_enum"];
export type HasCaregiver = Database["public"]["Enums"]["has_caregiver_enum"];
export type Race = Database["public"]["Enums"]["race_type"];
export type Ethnicity = Database["public"]["Enums"]["ethnicity_type"];
export type PatientTier = Database["public"]["Enums"]["patient_tier"];
export type ResidenceType = Database["public"]["Enums"]["residence_type"];
export type PhoneType = Database["public"]["Enums"]["phone_type_enum"];
export type RelationshipType = Database["public"]["Enums"]["relationship_type"];
export type MedicareBeneficiaryType =
  Database["public"]["Enums"]["medicare_beneficiary_type"];
export type ClinicianAttestation =
  Database["public"]["Enums"]["clinician_attestation_enum"];
export type ReferralSource =
  Database["public"]["Enums"]["referral_source_type"];

export type UserAssessmentInsert =
  Database["public"]["Tables"]["user_assessment"]["Insert"];
export type UserAssessmentAnswerInsert =
  Database["public"]["Tables"]["user_assessment_answer"]["Insert"];
export type GuideStatusInsert =
  Database["public"]["Tables"]["guide_status"]["Insert"];

type Patient = Omit<
  Tables<"patient">,
  | "sex"
  | "tier"
  | "birthday"
  | "race"
  | "ethnicity"
  | "dementia_staging_tool"
  | "has_caregiver"
  | "state"
  | "phone_type"
> & {
  sex: Sex;
  tier: PatientTier;
  birthday: Date | null;
  name: string;
  race: Race;
  ethnicity: Ethnicity;
  has_caregiver: HasCaregiver;
  state: (typeof US_STATES)[number];
  phone_type: PhoneType | null;
};

type CareTeam = Partial<
  Record<
    CarespaceRole,
    {
      role: CarespaceRole;
      name: string;
    }
  >
>;

export type TableName = typeof TABLE_NAME;
export type AssessmentTableName = typeof ASSESSMENT_TABLE_NAME;
export type UserAssessmentTableName = typeof USER_ASSESSMENT_TABLE_NAME;

type UserWithName = User & {
  name: string;
};

export type Carespace = Omit<
  QueryData<ReturnType<typeof select>>[0],
  "patient" | "name" | "guide_status" | "user_role"
> & {
  patient: Patient | null;
  name: string;
  care_team: CareTeam;
  guide_status: GuideStatus;
  guide_status_rationale: string | null;
  pcp_name: string | null;
  provider_name: string | null;
  clinician_name: string | null;
  user_role: {
    role: CarespaceRole;
    user: UserWithName;
  }[];
  current_paf_submission: {
    status: string;
    clinician_first_name?: string;
    clinician_last_name?: string;
    clinician_npi?: string;
    dementia_staging_tool: DementiaStagingTool;
    dementia_staging_score: number;
    icd10_code?: string;
    assessment_date?: string;
    clinician_attestation?: ClinicianAttestation;
  } | null;
};

// deadline and recurrence are intervals in the database
// and Supabase returns them as unknown types
// but we know they are strings
export type Activity = Tables<"activity"> & {
  deadline: string | null;
  recurrence: string | null;
  creatorName?: string;
  custom_fields?: Record<string, string> | null;
};

// Get all keys of T where the type includes null
type NullableKeys<T> = {
  [K in keyof T]-?: null extends T[K] ? K : never;
}[keyof T];

// Create a new type where those keys are optional
type NullableToOptional<T, K extends keyof T = never> = Omit<
  T,
  NullableKeys<T> | K
> &
  Partial<Pick<T, NullableKeys<T> | K>>;

export type NewActivity = NullableToOptional<Activity, "id">;

export type NewActivityPayload = Omit<
  NewActivity,
  "carespace_id" | "created_at" | "created_by_id" | "created_by_type"
>;

export function isSex(value?: string | null): value is Sex {
  return typeof value === "string" && Object.values(Sex).includes(value as Sex);
}

export function isPatientTier(value?: string | null): value is PatientTier {
  return typeof value === "string" && value in PatientTierMap;
}

export type AssessmentTypes = "ZARIT" | "PROMIS";
