export enum OrgRoleType {
  ADMIN = "admin",
  PROVIDER = "provider",
  CARE_NAVIGATOR = "carenavigator",
}

export const OrgRoleTypeToLabel: Record<OrgRoleType, string> = {
  [OrgRoleType.ADMIN]: "Administrator",
  [OrgRoleType.PROVIDER]:
    "Physician / Nurse Practitioner / Physician Associate",
  [OrgRoleType.CARE_NAVIGATOR]: "Care Navigator",
};

export enum CarespaceRoleType {
  PRIMARY_CAREGIVER = "primary_caregiver",
  SECONDARY_CAREGIVER = "secondary_caregiver",
}

export const CarespaceRoleTypeToLabel: Record<CarespaceRoleType, string> = {
  [CarespaceRoleType.PRIMARY_CAREGIVER]: "Primary Caregiver",
  [CarespaceRoleType.SECONDARY_CAREGIVER]: "Secondary Caregiver",
};

export const ORG_ROLE_OPTIONS: { value: OrgRoleType; label: string }[] = [
  {
    value: OrgRoleType.PROVIDER,
    label: OrgRoleTypeToLabel[OrgRoleType.PROVIDER],
  },
  {
    value: OrgRoleType.CARE_NAVIGATOR,
    label: OrgRoleTypeToLabel[OrgRoleType.CARE_NAVIGATOR],
  },
  { value: OrgRoleType.ADMIN, label: OrgRoleTypeToLabel[OrgRoleType.ADMIN] },
];
