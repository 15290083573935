import { supabase } from "./supabaseClient";

type Success<T> = {
  data: T;
  error: null;
};

type Failure<E> = {
  data: null;
  error: E;
};

type Result<T, E = Error> = Success<T> | Failure<E>;

async function callBackend<T>(
  path: string,
  options: RequestInit,
): Promise<Result<T>> {
  const { data } = await supabase.auth.getSession();
  const token = data.session?.access_token;

  if (!token) {
    throw new Error("No token found");
  }

  const response = await fetch(`${import.meta.env.VITE_BACKEND_URL}${path}`, {
    ...options,
    headers: {
      ...options.headers,
      Authorization: `Bearer ${token}`,
    },
  });

  let body: unknown;

  if (!response.ok) {
    return {
      data: null,
      error: new Error(`Request failed with status ${response.status}`),
    };
  }

  try {
    body = await response.json();
  } catch (error) {
    return {
      data: null,
      error: new Error(`Failed to parse response: ${error}`),
    };
  }

  return {
    data: body as T,
    error: null,
  };
}

export const backend = {
  fetch: <T>(path: string, options: RequestInit) =>
    callBackend<T>(path, options),
};
