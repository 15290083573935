export const LocalStorageKeys = {
  activeAssessment: "active-assessment-store",
  appLayout: "app-layout",
  productOnboarding: "product-onboarding",
  assessment: "assessment",
  myPlan: "myPlan",
  invitation: "invitation",
  organization: "organization-storage",
  carespace: "carespace-storage",
};
