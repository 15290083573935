import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { supabase } from "clients/supabaseClient";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useToast } from "shared/hooks/use-toast";
import { Button } from "shared/ui/button";
import { Form, FormField } from "shared/ui/form";
import { PhoneInput } from "shared/ui/phone-input";
import { v4 as uuidv4 } from "uuid";
import { z } from "zod";
import { PhoneMfa } from "./phone_mfa";

const formSchema = z.object({
  phoneNumber: z.string({ required_error: "Phone is required" }),
});

type FormSchema = z.infer<typeof formSchema>;

interface EnrollPhoneMfaProps {
  onSuccess: () => void;
}

export const EnrollPhoneMfa = ({ onSuccess }: EnrollPhoneMfaProps) => {
  const { toast } = useToast();
  const [factorId, setFactorId] = useState<string>();
  const { mutate } = useEnroll();

  const form = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: { phoneNumber: "" },
  });

  const onPhoneSubmit = ({ phoneNumber }: FormSchema) => {
    mutate(phoneNumber, {
      onSuccess: (data) => {
        setFactorId(data.id);
      },
      onError: (error) => {
        toast({ description: error.message, variant: "destructive" });
      },
    });
  };

  return (
    <div className="flex flex-col gap-4 py-4">
      <Form form={form} onSubmit={form.handleSubmit(onPhoneSubmit)}>
        <FormField
          control={form.control}
          name="phoneNumber"
          label="Enter phone number"
          disabled={form.formState.isSubmitting}
          render={({ field }) => <PhoneInput {...field} />}
        />

        <Button type="submit" isLoading={form.formState.isSubmitting}>
          Submit
        </Button>
      </Form>

      {factorId && (
        <PhoneMfa factorId={factorId} onSuccess={onSuccess} sendOtpCode />
      )}
    </div>
  );
};

const useEnroll = () =>
  useMutation({
    mutationFn: (phoneNumber: string) =>
      supabase.auth.mfa
        .enroll({
          phone: phoneNumber,
          factorType: "phone",
          friendlyName: `phone-${uuidv4()}`,
        })
        .then(({ data, error }) => {
          if (error) throw error;
          return data;
        }),
  });
