import { Send, ThumbsDown, ThumbsUp } from "lucide-react";
import React, { useEffect, useRef, useState } from "react";
import { Button } from "shared/ui/button";
import { Input } from "shared/ui/input";
import { useAlfredChat } from "./hooks";

// Generic Thumbs component
type ThumbsIconProps = {
  direction: "up" | "down";
  filled?: boolean;
  className?: string;
};

const ThumbsIcon = ({
  direction,
  filled = false,
  className = "",
}: ThumbsIconProps) => {
  const Icon = direction === "up" ? ThumbsUp : ThumbsDown;
  return <Icon className={className} fill={filled ? "currentColor" : "none"} />;
};

type Props = {
  carespaceId: string;
};

// Typing indicator component
const TypingIndicator = () => (
  <div className="flex gap-2 justify-start">
    <div className="max-w-[90%] px-4 py-2 rounded-lg bg-gray-100 flex items-center min-h-[36px]">
      <div className="flex space-x-1">
        <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" />
        <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce delay-75" />
        <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce delay-150" />
      </div>
    </div>
  </div>
);

export function AlfredChat({ carespaceId }: Props) {
  // State
  const [messageInput, setMessageInput] = useState("");
  const { messages, streaming, isLoading, sendMessage, handleFeedback } =
    useAlfredChat(carespaceId);
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  // Scroll to bottom when messages change
  useEffect(() => {
    scrollToBottom();
  }, [messages, streaming]);

  const scrollToBottom = () => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  };

  const handleSendMessage = async (e?: React.FormEvent) => {
    e?.preventDefault();

    if (!messageInput.trim() || isLoading) return;

    const userMessage = messageInput;
    setMessageInput("");

    // Send the message via our hook
    sendMessage(userMessage);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  // Render message content
  const renderContent = (content: string) => {
    return content.split("\n").map((line, i, array) => (
      <React.Fragment key={line}>
        {line}
        {i < array.length - 1 && <br />}
      </React.Fragment>
    ));
  };

  return (
    <div className="flex flex-col h-full border border-neutral-200 rounded-md overflow-hidden bg-white">
      {/* Messages area */}
      <div ref={containerRef} className="flex-grow overflow-y-auto p-4">
        {messages.length > 0 ? (
          <div className="flex flex-col gap-4">
            {messages.map((msg) => (
              <div
                key={msg.id}
                className={`flex gap-2 ${
                  msg.role === "user" ? "justify-end" : "justify-start"
                }`}
              >
                <div
                  className={`max-w-[90%] px-4 py-2 rounded-lg text-sm ${
                    msg.role === "user"
                      ? "bg-blue-100 text-blue-900"
                      : "bg-gray-100 text-gray-900"
                  }`}
                >
                  {renderContent(msg.content)}

                  {/* Feedback buttons for assistant messages */}
                  {msg.role === "assistant" && (
                    <div className="flex justify-end gap-2 mt-2">
                      <button
                        type="button"
                        onClick={() => handleFeedback(msg.id, "thumbs_up")}
                        className="p-1 rounded hover:bg-gray-200"
                        aria-label="Thumbs up"
                      >
                        <ThumbsIcon
                          direction="up"
                          filled={msg.feedback === "thumbs_up"}
                          className="w-4 h-4"
                        />
                      </button>
                      <button
                        type="button"
                        onClick={() => handleFeedback(msg.id, "thumbs_down")}
                        className="p-1 rounded hover:bg-gray-200"
                        aria-label="Thumbs down"
                      >
                        <ThumbsIcon
                          direction="down"
                          filled={msg.feedback === "thumbs_down"}
                          className="w-4 h-4"
                        />
                      </button>
                    </div>
                  )}
                </div>
              </div>
            ))}

            {/* Streaming message */}
            {streaming && (
              <div className="flex gap-2 justify-start">
                <div className="max-w-[90%] px-4 py-2 rounded-lg text-sm bg-gray-100 text-gray-900">
                  {renderContent(streaming)}
                </div>
              </div>
            )}

            {/* Typing indicator */}
            {isLoading && !streaming && <TypingIndicator />}
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center h-full text-center text-gray-500">
            <p className="text-lg font-medium">How can I help you?</p>
            <p className="text-sm">Ask me anything about this patient</p>
          </div>
        )}
      </div>

      {/* Input area */}
      <form
        onSubmit={handleSendMessage}
        className="p-3 border-t border-gray-200"
      >
        <div className="flex gap-2">
          <Input
            ref={inputRef}
            type="text"
            value={messageInput}
            onChange={(e) => setMessageInput(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Ask Alfred about this patient..."
            className="flex-grow"
            disabled={isLoading}
          />
          <Button type="submit" disabled={isLoading || !messageInput.trim()}>
            <Send className="w-4 h-4" />
          </Button>
        </div>
      </form>
    </div>
  );
}
