import { zodResolver } from "@hookform/resolvers/zod";
import * as Sentry from "@sentry/react";
import { LayoutList, Plus, Sparkles, Trash, X } from "lucide-react";
import { useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { z } from "zod";

import dayjs from "dayjs";
import { ownerOptions } from "features/shared/types";
import { useToast } from "shared/hooks/use-toast";
import { Button } from "shared/ui/button";
import { Combobox } from "shared/ui/combobox";
import { DatePicker } from "shared/ui/date-picker";
import { Dialog, DialogClose, DialogFooter } from "shared/ui/dialog";
import { Form, FormField } from "shared/ui/form";
import { Input } from "shared/ui/input";
import { SplitButton } from "shared/ui/split-button";
import { Text } from "shared/ui/text";
import { Textarea } from "shared/ui/textarea";
import type { NewActivityPayload } from "../types";

const customFieldSchema = z.object({
  key: z.string().min(1, "Key is required"),
  value: z.string().min(1, "Value is required"),
});

const formSchema = z.object({
  title: z.string().min(1),
  description: z.string().optional(),
  owner: z.enum(["carenavigator", "caregiver"]),
  scheduled_at: z.nullable(z.date()),
  custom_fields: z
    .array(customFieldSchema)
    .optional()
    .superRefine((fields, ctx) => {
      if (!fields || fields.length === 0) return;

      // Find duplicate keys
      const keyToIndexMap = new Map<string, number[]>();
      for (const [index, field] of fields.entries()) {
        const key = field.key;
        if (!key) continue; // Skip empty keys (will be caught by individual validation)

        const indices = keyToIndexMap.get(key) || [];
        indices.push(index);
        keyToIndexMap.set(key, indices);
      }

      // Add error for each duplicate key field
      for (const [key, indices] of keyToIndexMap.entries()) {
        if (indices.length > 1) {
          // Add error for each duplicate field (except the first one)
          for (const [i, index] of indices.entries()) {
            if (i > 0) {
              // Skip the first occurrence
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: `Duplicate key "${key}"`,
                path: [index, "key"],
              });
            }
          }
        }
      }
    }),
});

type NewActivitySchema = z.infer<typeof formSchema>;
type NewActivityProps = {
  onSave: (activity: NewActivityPayload) => Promise<void>;
  onGenerate: () => Promise<void>;
};

export default function NewActivity({ onSave, onGenerate }: NewActivityProps) {
  const [open, setOpen] = useState(false);
  const [isGeneratingActivities, setIsGeneratingActivities] = useState(false);
  const { toast } = useToast();

  const form = useForm<NewActivitySchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      title: "",
      description: "",
      owner: "carenavigator",
      scheduled_at: null,
      custom_fields: [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "custom_fields",
  });

  async function onSubmit(data: NewActivitySchema) {
    try {
      // Convert custom fields array to object
      const customFields: Record<string, string> = {};
      if (data.custom_fields && data.custom_fields.length > 0) {
        for (const field of data.custom_fields) {
          customFields[field.key] = field.value;
        }
      }

      const formattedActivity: NewActivityPayload = {
        title: data.title,
        description: data.description,
        owner: data.owner,
        scheduled_at: data.scheduled_at?.toISOString(),
        type: "user_created",
        custom_fields:
          Object.keys(customFields).length > 0 ? customFields : undefined,
      };

      await onSave(formattedActivity);
      form.reset();
      setOpen(false);
    } catch (error) {
      Sentry.captureException(error);
      toast({
        variant: "destructive",
        title: "Failed to save activity",
        description: "Please try again",
      });
    }
  }

  async function onGenerateActivities() {
    setIsGeneratingActivities(true);
    try {
      await onGenerate();
    } catch {
      toast({
        title: "Error generating activities",
        variant: "destructive",
      });
    } finally {
      setIsGeneratingActivities(false);
    }
  }

  function addCustomField() {
    append({ key: "", value: "" });
  }

  const content = (
    <Form form={form} onSubmit={form.handleSubmit(onSubmit)}>
      <>
        <FormField
          control={form.control}
          name="title"
          label="Title"
          render={({ field }) => (
            <Input placeholder="Activity Title" className="w-full" {...field} />
          )}
        />

        <FormField
          control={form.control}
          name="description"
          label="Description"
          render={({ field }) => (
            <Textarea
              placeholder="Activity Description"
              className="w-full"
              {...field}
            />
          )}
        />

        <FormField
          control={form.control}
          name="owner"
          label="Owner"
          render={({ field }) => <Combobox options={ownerOptions} {...field} />}
        />

        <FormField
          control={form.control}
          name="scheduled_at"
          label="Schedule For (Optional)"
          render={({ field }) => (
            <>
              <div className="flex items-center gap-2 w-full">
                <DatePicker
                  value={field.value ?? undefined}
                  onChange={field.onChange}
                  min={dayjs().add(1, "day").toDate()}
                  className="rounded-md border flex-1 w-full"
                />
                {field.value && (
                  <Button
                    type="button"
                    variant="ghost"
                    size="sm"
                    onClick={() => field.onChange(null)}
                    className="p-1 h-8 w-8"
                  >
                    <X className="h-4 w-4" />
                  </Button>
                )}
              </div>
              <Text size="sm" className="text-gray-500 mt-1">
                Scheduled activities will only appear on or after the selected
                date.
              </Text>
            </>
          )}
        />

        <div className="space-y-3 mt-4">
          <div className="flex items-center justify-between">
            <Text className="font-medium">Custom Fields</Text>
            <Button
              type="button"
              variant="outline"
              size="sm"
              onClick={addCustomField}
              className="h-8"
            >
              <Plus className="h-4 w-4 mr-1" /> Add Field
            </Button>
          </div>

          {fields.map((field, index) => (
            <div key={field.id} className="flex gap-2 items-start">
              <div className="flex-1">
                <FormField
                  control={form.control}
                  name={`custom_fields.${index}.key`}
                  label=""
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder="Field name"
                      className="w-full"
                    />
                  )}
                />
              </div>
              <div className="flex-1">
                <FormField
                  control={form.control}
                  name={`custom_fields.${index}.value`}
                  label=""
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder="Field value"
                      className="w-full"
                    />
                  )}
                />
              </div>
              <Button
                type="button"
                variant="ghost"
                size="sm"
                onClick={() => remove(index)}
                className="p-1 h-8 w-8 mt-1.5"
              >
                <Trash className="h-4 w-4" />
              </Button>
            </div>
          ))}

          {fields.length === 0 && (
            <Text size="sm" className="text-gray-500">
              No custom fields added yet. Click the button above to add a field.
            </Text>
          )}
        </div>
      </>
      <DialogFooter className="sm:justify-start mt-4">
        <DialogClose asChild>
          <Button type="button" variant="secondary">
            Cancel
          </Button>
        </DialogClose>
        <Button type="submit">Save</Button>
      </DialogFooter>
    </Form>
  );

  return (
    <Dialog
      open={open}
      onOpenChange={setOpen}
      content={content}
      title="Add Activity"
    >
      <SplitButton
        variant="outline"
        title="Add Activity"
        icon={<LayoutList className="w-4 h-4" />}
        isLoading={isGeneratingActivities}
        disabled={isGeneratingActivities}
        options={[
          {
            label: "Generate activities",
            icon: <Sparkles className="w-4 h-4" />,
            onClick: onGenerateActivities,
          },
        ]}
      />
    </Dialog>
  );
}
