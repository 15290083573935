import { useFetchMany as useOrganizations } from "features/organizations/queries/hooks";
import { ALL_ORGANIZATIONS } from "features/routing/layouts/components/organization-switcher";
import { useEffect, useMemo } from "react";
import { LocalStorageKeys } from "state/localStorageKeys";
import { create } from "state/zustand-create";
import { persist } from "zustand/middleware";
/**
 * @fileoverview Sets up the Zustand library organization store.
 * This store holds information about organization state.
 */
import { useOrgs } from "../../backend/resources/orgRole/orgRole";

/**
 * Hook to access the organization store
 */

export type OrganizationSelection = string | typeof ALL_ORGANIZATIONS;

type State = {
  activeOrganizationId?: OrganizationSelection;
};

type Actions = {
  setActiveOrganizationId: (
    activeOrganizationId: string | typeof ALL_ORGANIZATIONS,
  ) => void;
  reset: () => void;
};

const initialState: State = {
  activeOrganizationId: undefined,
};

// TODO: remove this as a persistent store, it's a potential HIPAA violation. Removal might cause bugs.
// See https://app.shortcut.com/craniometrix/story/3097/frontend-remove-persist-from-organization-store
export const useOrganizationStore = create<State & Actions>()(
  persist(
    (set) => ({
      ...initialState,
      setActiveOrganizationId: (activeOrganizationId) =>
        set({ activeOrganizationId }),
      reset: () => set({ ...initialState }),
    }),
    {
      name: LocalStorageKeys.organization, // unique name
      getStorage: () => localStorage, // (optional) by default the 'localStorage' is used
    },
  ),
);

export function useActiveOrganizationId() {
  const { activeOrganizationId, setActiveOrganizationId } =
    useOrganizationStore();
  const { data: organizations, isLoading: isLoadingOrganizations } =
    useOrganizations({
      equals: { is_super_org: false },
    });
  const { isSuperSuperUser, isLoading: isLoadingRole } = useOrgs();
  const organizationIds = useMemo(
    () => organizations?.map((org) => org.id),
    [organizations],
  );

  useEffect(() => {
    if (isLoadingRole || isLoadingOrganizations) {
      return;
    }

    if (activeOrganizationId) {
      return;
    }

    if (isSuperSuperUser) {
      setActiveOrganizationId(ALL_ORGANIZATIONS);
      return;
    }

    if (!organizationIds || organizationIds.length === 0) {
      return;
    }

    // If the user is not a super super user, set the active organization to the first organization
    setActiveOrganizationId(organizationIds[0]);
  }, [
    organizationIds,
    activeOrganizationId,
    setActiveOrganizationId,
    isSuperSuperUser,
    isLoadingRole,
    isLoadingOrganizations,
  ]);

  return activeOrganizationId;
}
