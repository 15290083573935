import { TextArea } from "components/TextArea";

interface FreeFormTextProps {
  className?: string;
  placeholder?: string;
  answer?: string;
  onChange: (value: string) => void;
  disabled?: boolean;
  length?: "short" | "long";
  hidden?: boolean;
}

export function FreeFormText({
  className,
  placeholder,
  answer,
  onChange,
  disabled,
  length = "long",
  hidden = false,
}: FreeFormTextProps) {
  if (hidden) {
    return null;
  }

  return (
    <TextArea
      className={className}
      placeholder={placeholder}
      value={answer}
      onChange={(e) => onChange(e.target.value)}
      disabled={disabled}
      rows={length === "short" ? 2 : 4}
    />
  );
}
