import * as Sentry from "@sentry/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import queryClient from "../../../shared/query-client";
import { QUERY_KEYS } from "../../query-keys";
import { useActiveCarespace } from "../carespace/carespace";

import { useAuthUser } from "features/users/auth";
import type { User } from "features/users/types";

import { supabase } from "clients/supabaseClient";
import type { OrgRoleType } from "../userRole/types";

import { ALL_ORGANIZATIONS } from "features/routing/layouts/components/organization-switcher";
import { checkIsSuperSuperUser } from "lib";
import { useActiveOrganizationId } from "state/organization/organization";
import type { Database } from "../../../../../types/supabase";

const TABLE = "organization_role";

type Organization = Database["public"]["Tables"]["organization"]["Row"];
export type OrganizationRole =
  Database["public"]["Tables"]["organization_role"]["Row"];
type OrganizationRoleUpdate =
  Database["public"]["Tables"]["organization_role"]["Update"];

export type OrgAndIdentityReturnType = Omit<OrganizationRole, "role"> & {
  role: OrgRoleType;
  organization: Organization | null; // asserts 1-to-1 relationship in join type to avoid [] || {} || null union type, known supabase bug
  user: User;
};

/**
 * Query functions
 */
async function fetchAllOrgAndIdentities() {
  const { data, error } = await supabase
    .from(TABLE)
    .select("*, organization(*), user(*)");

  if (error) {
    Sentry.captureException(error);
  }

  return data as OrgAndIdentityReturnType[];
}

async function fetchOrgById(organizationId?: string | null) {
  if (!organizationId) return null;

  const { data, error } = await supabase
    .from("organization")
    .select("*")
    .eq("id", organizationId)
    .limit(1)
    .maybeSingle();

  // .eq("user_id", userId)
  // without below returns method, there's a bug in return type
  // regardless of unique constraints, returns `[] | {} | null` type
  // this has been fixed in postgrest but not supabase.
  // https://github.com/orgs/supabase/discussions/7610#discussioncomment-5515519
  // .returns<OrgAndIdentityReturnType[]>(); // asserts one-to-one relationship

  if (error) {
    Sentry.captureException(error);
    throw new Error(error.message);
  }

  return data;
}

async function fetchOrgRole(organization_id?: string, user_id?: string) {
  if (!organization_id || !user_id) return null;

  const { data, error } = await supabase
    .from(TABLE)
    .select("*")
    .eq("user_id", user_id)
    .limit(1)
    .maybeSingle();

  if (error) {
    Sentry.captureException(error);
    throw new Error(error.message);
  }
  return data;
}

/**
 * Query hooks
 */

export function useOrgs() {
  const { authUser } = useAuthUser();

  const { data: allOrgIdentities, isLoading: isAllOrgIdentitesLoading } =
    useQuery({
      queryKey: [
        QUERY_KEYS.orgAndIdentities,
        { authUser: authUser?.id, productAccessQuery: true },
      ],
      queryFn: () => fetchAllOrgAndIdentities(),
      refetchOnWindowFocus: false,
      enabled: !!authUser,
    });

  // Users have access to their peers' roles
  // Also, super org users have access to all roles
  // Therefore, we need to filter just the roles that belong to the user
  const ownOrgIdentities = allOrgIdentities
    ? allOrgIdentities.filter(
        (orgIdentity) => orgIdentity.user_id === authUser?.id,
      )
    : [];

  const isSuperSuperUser = checkIsSuperSuperUser(ownOrgIdentities);

  // Check if user has admin access in any org
  const hasAdminAccess =
    isSuperSuperUser ||
    ownOrgIdentities?.some((orgIdentity) => orgIdentity.is_superuser);

  const hasOneOrMoreOrgIdentities =
    ownOrgIdentities && ownOrgIdentities.length > 0;

  const { data: carespace } = useActiveCarespace();
  const firstOrganizationId =
    ownOrgIdentities?.[0]?.organization?.id ?? carespace?.organization_id;

  // Get deduped ownOrgs
  const ownOrganizationsMap: Record<string, Organization> = {};
  if (ownOrgIdentities)
    for (const orgIdentity of ownOrgIdentities) {
      if (orgIdentity.organization) {
        ownOrganizationsMap[orgIdentity.organization_id] =
          orgIdentity.organization;
      }
    }
  const ownOrganizations = Object.values(ownOrganizationsMap);
  const hasCareCentralAccess = ownOrganizations.length > 0;

  return {
    isLoading: isAllOrgIdentitesLoading,
    allOrgIdentities,
    ownOrgIdentities,
    ownOrganizations,
    hasOneOrMoreOrgIdentities,
    firstOrganizationId,
    isSuperSuperUser,
    hasCareCentralAccess,
    hasAdminAccess,
  };
}

export function useOrg(organizationId?: string | null) {
  return useQuery({
    queryKey: [QUERY_KEYS.orgs, { organizationId }],
    queryFn: () => fetchOrgById(organizationId),
    refetchOnWindowFocus: false,
  });
}

function useActiveOrg() {
  const activeOrgId = useActiveOrganizationId();

  return useQuery({
    queryKey: [QUERY_KEYS.activeOrg, { activeOrgId }],
    queryFn: () => {
      if (!activeOrgId) return null;

      if (activeOrgId === ALL_ORGANIZATIONS) {
        throw new Error("Multiple active orgs not supported");
      }

      return fetchOrgById(activeOrgId);
    },
    refetchOnWindowFocus: false,
  });
}

export function useActiveOrgRole() {
  const { authUser } = useAuthUser();
  const activeOrgId = useActiveOrganizationId();

  return useQuery({
    queryKey: [
      QUERY_KEYS.orgAndIdentities,
      { authUser: authUser?.id, activeOrgId },
    ],
    queryFn: () => {
      if (!activeOrgId || !authUser) return null;

      if (activeOrgId === ALL_ORGANIZATIONS) {
        throw new Error("Multiple active orgs not supported");
      }

      return fetchOrgRole(activeOrgId, authUser?.id);
    },
    refetchOnWindowFocus: false,
  });
}

/**
 * Mutation functions
 */

async function updateOrgRole(orgRole: OrganizationRoleUpdate) {
  if (!orgRole.id) return null;

  const { data, error } = await supabase
    .from(TABLE)
    .update(orgRole)
    .eq("id", orgRole.id)
    .select("*")
    .limit(1)
    .order("id", { ascending: true }) // noop
    .maybeSingle();

  if (error) {
    throw new Error(error.message);
  }

  return data;
}

/**
 * Mutation hooks
 */

export function useUpdateOrgRole() {
  return useMutation({
    mutationFn: async ({
      roleId,
      newRole,
      isSuperUser,
      isDeactivated,
    }: {
      roleId: string;
      newRole?: OrgRoleType;
      isSuperUser?: boolean;
      isDeactivated?: boolean;
    }) => {
      const response = await updateOrgRole({
        id: roleId,
        role: newRole,
        is_deactivated: isDeactivated,
        is_superuser: isSuperUser,
      });

      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.organizationInvitation],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.organizationInvitation],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.orgAndIdentities],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.carespaceMemberByInvitationId],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.carespaceMembers],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.orgAndCarespaceIdentities],
      });
    },
  });
}
