import * as Sentry from "@sentry/react";
import { useAuthUser } from "features/users/auth";
import { useUserOnboarding } from "hooks/useUserOnboarding";
import { useEffect, useState } from "react";
import OneSignal from "react-onesignal";
import { oneSignal } from "../../backend/functions";

const DOUBLE_INIT_ERROR_MESSAGE = "OneSignal is already initialized";

export default function OneSignalComponent() {
  const { authUser } = useAuthUser();
  const [isOneSignalInitialized, setIsOneSignalInitialized] = useState(false);
  const {
    isLoading: isLoadingOnboarding,
    isUserRoleSelectionShowing,
    isCareCentralOnboardingShowing,
    isCarePilotOnboardingShowing,
  } = useUserOnboarding();

  const isOnboarding =
    isUserRoleSelectionShowing ||
    isCareCentralOnboardingShowing ||
    isCarePilotOnboardingShowing;
  useEffect(() => {
    async function setupOneSignal() {
      if (
        authUser &&
        !isOneSignalInitialized &&
        !isOnboarding &&
        !isLoadingOnboarding
      ) {
        // initialize OneSignal
        setIsOneSignalInitialized(true);
        if (OneSignal.Notifications.isPushSupported()) {
          try {
            await OneSignal.init({
              appId: import.meta.env.VITE_ONE_SIGNAL_APP_ID,
              allowLocalhostAsSecureOrigin: true,
            });
          } catch (error) {
            Sentry.captureException(error);
          }
          try {
            // configure OneSignal
            await OneSignal.login(authUser.id);
            await OneSignal.Slidedown.promptPush();
            await oneSignal(authUser.id);
            // biome-ignore lint/suspicious/noExplicitAny: Any in catch blocks are fine
          } catch (error: any) {
            if (error.message !== DOUBLE_INIT_ERROR_MESSAGE) {
              Sentry.captureException(error);
            }
          }
        }
      }
    }
    setupOneSignal();
  }, [authUser, isOneSignalInitialized, isOnboarding, isLoadingOnboarding]);
  return null;
}
