import type { NodeProps } from "@xyflow/react";
import { Handle, Position } from "@xyflow/react";
import { Badge } from "shared/ui/badge";
import { Text } from "shared/ui/text";
import { cn } from "shared/utils/cn";
import type { ActivityNode } from "../types";

export default function ActivityNodeComponent({
  data,
}: NodeProps<ActivityNode>) {
  const styles =
    data.owner === "carenavigator" ? "border-blue-200" : "border-green-200";

  return (
    <div
      className={cn(
        "flex flex-col w-full h-full bg-white py-2 px-3 gap-2 border rounded-md overflow-hidden",
        styles,
      )}
    >
      <Handle type="target" position={Position.Top} className="bg-gray-300" />
      <Text className="text-gray-700 text-base w-full h-full line-clamp-3">
        {data.title}
      </Text>
      <div className="flex flex-row gap-2">
        <Badge
          className={cn(
            "text-gray-700 text-xxs",
            data.owner === "carenavigator" ? "bg-blue-200" : "bg-green-200",
          )}
        >
          {data.owner}
        </Badge>
        <Badge className="text-xxs">{data.type}</Badge>
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        className="bg-gray-300"
      />
    </div>
  );
}
