import { zodResolver } from "@hookform/resolvers/zod";
import type { MFAVerifyParams } from "@supabase/supabase-js";
import { useMutation } from "@tanstack/react-query";
import { supabase } from "clients/supabaseClient";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useToast } from "shared/hooks/use-toast";
import { Button } from "shared/ui/button";
import { Form, FormField } from "shared/ui/form";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSeparator,
  InputOTPSlot,
} from "shared/ui/input-otp";
import { Text } from "shared/ui/text";
import { z } from "zod";

const formSchema = z.object({
  otp: z
    .string({ required_error: "OTP is required" })
    .length(6, { message: "OTP must be 6 digits" }),
});

type FormSchema = z.infer<typeof formSchema>;

interface PhoneMfaProps {
  onSuccess: () => void;
  factorId: string;
  sendOtpCode?: boolean;
}

// Local storage key for tracking when the next OTP code can be requested
const PHONE_OTP_REQUEST_TIMESTAMP_KEY = "phoneOtpRequestAvailableAt";

export function PhoneMfa({ onSuccess, factorId, sendOtpCode }: PhoneMfaProps) {
  const { toast } = useToast();
  const [challengeId, setChallengeId] = useState<string>("");
  const { timeLeft, initTimer, setTimerWithDuration } = useOtpCodeTimer(
    PHONE_OTP_REQUEST_TIMESTAMP_KEY,
  );

  const form = useForm<FormSchema>({
    resolver: zodResolver(formSchema, {}),

    defaultValues: {
      otp: "",
    },
  });
  const { mutate: verify, isPending: isVerifying } = useVerify();
  const { mutate: requestOtpCode, isPending: isRequesting } =
    useOtpCodeRequest();

  const onSubmit = ({ otp }: FormSchema) => {
    verify(
      { factorId, challengeId, code: otp },
      {
        onSuccess,
        onError: (error) => {
          toast({ description: error.message, variant: "destructive" });
        },
      },
    );
  };

  const onOtpCodeRequest = () => {
    requestOtpCode(factorId, {
      onSuccess: (data) => {
        setChallengeId(data.id);
        setTimerWithDuration(60 * 1000);
      },
      onError: (error) => {
        toast({ description: error.message, variant: "destructive" });
      },
    });
  };

  useEffect(() => {
    if (sendOtpCode) onOtpCodeRequest();
    initTimer();
  }, []);

  return (
    <div className="flex flex-col gap-4 py-4">
      <Form form={form} onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          control={form.control}
          name="otp"
          label="Enter Code"
          render={({ field }) => (
            <InputOTP maxLength={6} {...field}>
              <InputOTPGroup>
                <InputOTPSlot index={0} />
                <InputOTPSlot index={1} />
                <InputOTPSlot index={2} />
              </InputOTPGroup>
              <InputOTPSeparator />
              <InputOTPGroup>
                <InputOTPSlot index={3} />
                <InputOTPSlot index={4} />
                <InputOTPSlot index={5} />
              </InputOTPGroup>
            </InputOTP>
          )}
        />

        <Button
          isLoading={isRequesting}
          disabled={!!timeLeft || isRequesting}
          onClick={onOtpCodeRequest}
          type="button"
        >
          {timeLeft ? "Code has been sent" : "Request code"}
        </Button>

        {timeLeft && (
          <Text className="text-sm self-center">
            You can request new code in {Math.ceil(timeLeft / 1000)} seconds
          </Text>
        )}

        <Button
          type="submit"
          className="w-full mt-4"
          isLoading={isVerifying}
          disabled={!challengeId || isVerifying}
        >
          Submit
        </Button>
      </Form>
    </div>
  );
}

const useVerify = () =>
  useMutation({
    mutationFn: (params: MFAVerifyParams) =>
      supabase.auth.mfa.verify(params).then(({ data, error }) => {
        if (error) throw error;
        return data;
      }),
  });

const useOtpCodeRequest = () =>
  useMutation({
    mutationFn: (factorId: string) =>
      supabase.auth.mfa.challenge({ factorId }).then(({ data, error }) => {
        if (error) throw error;
        return data;
      }),
  });

function useOtpCodeTimer(storageKey: string) {
  const [timeLeft, setTimeLeft] = useState<number>();
  const [timerId, setTimerId] = useState<ReturnType<typeof setInterval>>();

  const initTimer = useCallback(
    (initTimestamp?: number) => {
      if (timerId) clearInterval(timerId);

      const timestamp =
        initTimestamp || Number(localStorage.getItem(storageKey));

      const currentTimeLeft = timestamp - Date.now();
      if (currentTimeLeft <= 0) return;

      setTimeLeft(currentTimeLeft);

      const newTimerId = setInterval(() => {
        const updatedTimeLeft = timestamp - Date.now();
        if (updatedTimeLeft <= 0) {
          clearInterval(newTimerId);
          setTimeLeft(undefined);
          return;
        }
        setTimeLeft(updatedTimeLeft);
      }, 1000);

      setTimerId(newTimerId);
    },
    [timerId, storageKey],
  );

  const setTimerWithDuration = useCallback(
    (durationMs: number) => {
      const expiryTimestamp = Date.now() + durationMs;
      localStorage.setItem(storageKey, expiryTimestamp.toString());
      initTimer(expiryTimestamp);
    },
    [initTimer, storageKey],
  );

  useEffect(() => {
    return () => {
      if (timerId) clearInterval(timerId);
    };
  }, [timerId]);

  return { timeLeft, initTimer, setTimerWithDuration };
}
