import type { Factor } from "@supabase/supabase-js";
import { supabase } from "clients/supabaseClient";
import { useEffect, useMemo, useState } from "react";
import { Button } from "shared/ui/button";
import { Tabs } from "shared/ui/tabs";
import type { TabsProps } from "shared/ui/tabs";
import { TotpMfa } from "./totp_mfa";

import { useToast } from "shared/hooks/use-toast";
import { Text } from "shared/ui/text";
import { useAuthUser } from "..";
import { PhoneMfa } from "./phone_mfa";

interface MFAProps {
  onSuccess: () => void;
}

export default function MFA({ onSuccess }: MFAProps) {
  const { logout } = useAuthUser();
  const { toast } = useToast();
  const [totpFactor, setTotpFactor] = useState<Factor>();
  const [phoneFactor, setPhoneFactor] = useState<Factor>();

  const mfaOptions = useMemo(() => {
    const result = [] as TabsProps["items"];
    if (totpFactor)
      result.push({
        value: "totp",
        label: "Authenticator App",
        content: <TotpMfa factorId={totpFactor.id} onSuccess={onSuccess} />,
      });
    if (phoneFactor)
      result.push({
        value: "phone",
        label: "SMS Verification",
        content: <PhoneMfa factorId={phoneFactor.id} onSuccess={onSuccess} />,
      });
    return result;
  }, [totpFactor, phoneFactor, onSuccess]);

  useEffect(() => {
    supabase.auth.mfa.listFactors().then(({ data, error }) => {
      if (error) {
        toast({ description: error.message, variant: "destructive" });
        return;
      }

      const { phone, totp } = data;

      if (phone.length > 0) setPhoneFactor(phone[0]);
      if (totp.length > 0) setTotpFactor(totp[0]);
    });
  }, []);

  if (mfaOptions.length === 0) return <></>;

  return (
    <>
      <div className="flex flex-col gap-5">
        <Text className="text-sm">
          Your account has Multi-Factor Authentication (MFA) enabled. Please
          complete the verification.
        </Text>
        <Tabs defaultValue={mfaOptions[0].value} items={mfaOptions} />
      </div>

      <Button variant="outline" className="w-full" onClick={logout}>
        Back to login
      </Button>
    </>
  );
}
