import { Separator } from "shared/ui/separator";
import { Title } from "shared/ui/title";

interface SectionHeaderProps {
  title: string;
}

export default function SectionHeader({ title }: SectionHeaderProps) {
  return (
    <>
      <Title order={3} className="mt-8" color="gray">
        {title}
      </Title>
      <Separator />
    </>
  );
}
