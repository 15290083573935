import type { PostgrestFilterBuilder } from "@supabase/postgrest-js";

import type { Database, Tables } from "types/supabase";
import type { RawCreateParams } from "zod";
import { z } from "zod";

type PublicSchema = Database[Extract<keyof Database, "public">];
type TableNames = keyof (PublicSchema["Tables"] & PublicSchema["Views"]);

export interface Filter<TableName extends TableNames> {
  equals?: Partial<Tables<TableName>>;
  in?: Partial<{
    [Key in keyof Tables<TableName>]: Tables<TableName>[Key][];
  }>;
}

export interface QueryOptions {
  enabled?: boolean;
}

export function buildFilters<
  TableName extends TableNames,
  Row extends Record<string, unknown>,
  Result,
  RelationName = unknown,
  Relationships = unknown,
>(
  request: PostgrestFilterBuilder<
    PublicSchema,
    Row,
    Result,
    RelationName,
    Relationships
  >,
  filter?: Filter<TableName>,
) {
  let requestWithFilters = request;

  if (filter?.equals) {
    requestWithFilters = requestWithFilters.match(filter.equals);
  }

  if (filter?.in) {
    for (const [column, values] of Object.entries(filter.in)) {
      // @ts-expect-error The type system struggles with this complex generic case
      requestWithFilters = requestWithFilters.in(column, values as unknown[]);
    }
  }

  return requestWithFilters;
}

export function enumFromKeys<
  T extends Database["public"]["Enums"][keyof Database["public"]["Enums"]],
>(
  object: Record<T, unknown>,
  params?: RawCreateParams,
): z.ZodEnum<[T, ...T[]]> {
  return z.enum(Object.keys(object) as [T, ...T[]], params);
}
