export enum Layout {
  PUBLIC = 0,
  RESTRICTED = 1,
  PRIVATE = 2,
}

export enum Route {
  ROOT = "/",
  LOGIN = "/log-in",
  HOME = "/home",
  TERMS_OF_SERVICE = "/terms-of-service",
  COOKIE_USE = "/cookie-use",
  PRIVACY_POLICY = "/privacy-policy",
  RESET_PASSWORD = "/reset-password",
  UPDATE_PASSWORD = "/update-password",
  SERVICE_PAGE = "/service/:id",
  SERVICE_PROVIDER_PAGE = "/service-provider/:id",
  ADMIN = "/admin",
  ADMIN_EDIT = "/admin/edit",
  MY_ACCOUNT = "/my-account",
  MFA_ENROLL = "/enroll_mfa",
  MY_CARE = "/carepilot/my-care",
  MY_CARE_ID = "/carepilot/my-care/:id",
  FAMILY_HUB = "/carepilot/family-hub",
  SETTINGS = "/carepilot/settings",
  GENERAL_DISCUSSION = "/carepilot/general-discussion",
  CONVERSATION = "/carepilot/conversation/:conversation_id",
  CARESPACES = "/carespaces",
  CARESPACES_NEW = "/carespaces/new",
  CARESPACE = "/carespaces/:carespaceId",
  CARESPACE_ASSESSMENT = "/carespaces/:carespaceId/assessment",
  SAMPLE_CARESPACES_CSV = "/sample_carespaces_import.csv",
  SERVICE_HUB = "/service-hub",
  DIRECTORY = "/carecentral/directory",
  BILLING_PAGE = "/carecentral/billing",
  PATIENT_SEARCH = "/super-super-user/patient-search",
  ORGANIZATIONS = "/organizations",
  ORGANIZATIONS_NEW = "/organizations/new",
  MEMBERS = "/members",
  MEMBERS_NEW = "/members/new",
  MEMBERS_EDIT = "/members/:memberId/edit",
  SUPERORG_MEMBERS = "/superorg_members",
  SUPERORG_MEMBERS_NEW = "/superorg_members/new",
  SUPERORG_MEMBERS_EDIT = "/superorg_members/:memberId/edit",
  CAREFLOW = "/careflow",
  ACCEPT_INVITATION = "/accept-invitation",
}
